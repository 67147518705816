import * as i0 from '@angular/core';
import { EventEmitter, Component, Input, Output, NgModule } from '@angular/core';
import * as i1 from '@theevolvedgroup/component-library/search-filter';
import { SearchFilterModule } from '@theevolvedgroup/component-library/search-filter';
import * as i2 from '@angular/material/menu';
import { MatMenuModule } from '@angular/material/menu';
import * as i3 from '@theevolvedgroup/component-library/icon-button';
import { IconButtonModule } from '@theevolvedgroup/component-library/icon-button';
import { CommonModule } from '@angular/common';
class SearchMenuComponent {
  constructor() {
    /** When value is changed the search field will be focused */
    this.focusSearch = false;
    /** The color of the search icon. */
    this.iconColor = "var(--theme-color-grey-0)";
    /** Event emitted container the user's search filter input */
    this.filterTermChange = new EventEmitter();
    /** Event emitted when clear icon button is clicked */
    this.clearSearchTerm = new EventEmitter();
    /** Event emitter when menu opened. */
    this.menuOpened = new EventEmitter();
  }
  ngOnInit() {}
  onFilterTermChange(event) {
    this.filterTermChange.emit(event);
  }
  onClearSearchTerm(event) {
    this.clearSearchTerm.emit(event);
  }
  onMenuOpened() {
    this.focusSearch = true;
    this.menuOpened.emit();
  }
  onMenuClosed() {
    this.focusSearch = false;
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: SearchMenuComponent,
      deps: [],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: SearchMenuComponent,
      selector: "teg-search-menu",
      inputs: {
        focusSearch: "focusSearch",
        resetSearch: "resetSearch",
        filterTerm: "filterTerm",
        iconColor: "iconColor"
      },
      outputs: {
        filterTermChange: "filterTermChange",
        clearSearchTerm: "clearSearchTerm",
        menuOpened: "menuOpened"
      },
      ngImport: i0,
      template: "<div class=\"teg-search-menu-btn\"\n     [matMenuTriggerFor]=\"searchMenu\"\n     #quickAccessMenuTrigger=\"matMenuTrigger\"\n     matTooltip=\"Search\"\n     (menuOpened)=\"onMenuOpened()\"\n     (menuClosed)=\"onMenuClosed()\">\n    <teg-icon-button [color]=\"iconColor\">search</teg-icon-button>\n</div>\n<mat-menu #searchMenu=\"matMenu\"\n          [overlapTrigger]=\"false\"\n          [hasBackdrop]=\"true\"\n          backdropClass=\"cdk-overlay-dark-backdrop\"\n          class=\"search-menu\">\n    <teg-search-filter [(filterTerm)]=\"filterTerm\"\n                       [focusSearch]=\"focusSearch\"\n                       [resetSearch]=\"resetSearch\"\n                       (filterTermChange)=\"onFilterTermChange($event)\"\n                       (clearSearchTerm)=\"onClearSearchTerm($event)\">\n    </teg-search-filter>\n</mat-menu>",
      styles: [""],
      dependencies: [{
        kind: "component",
        type: i1.SearchFilterComponent,
        selector: "teg-search-filter",
        inputs: ["focusSearch", "resetSearch", "filterTerm", "debounceTime"],
        outputs: ["filterTermChange", "clearSearchTerm"]
      }, {
        kind: "component",
        type: i2.MatMenu,
        selector: "mat-menu",
        inputs: ["backdropClass", "aria-label", "aria-labelledby", "aria-describedby", "xPosition", "yPosition", "overlapTrigger", "hasBackdrop", "class", "classList"],
        outputs: ["closed", "close"],
        exportAs: ["matMenu"]
      }, {
        kind: "directive",
        type: i2.MatMenuTrigger,
        selector: "[mat-menu-trigger-for], [matMenuTriggerFor]",
        inputs: ["mat-menu-trigger-for", "matMenuTriggerFor", "matMenuTriggerData", "matMenuTriggerRestoreFocus"],
        outputs: ["menuOpened", "onMenuOpen", "menuClosed", "onMenuClose"],
        exportAs: ["matMenuTrigger"]
      }, {
        kind: "component",
        type: i3.IconButtonComponent,
        selector: "teg-icon-button",
        inputs: ["customClass", "icon", "outline", "color", "size", "variant", "library", "opticalSize", "iconWeight", "action", "tooltip", "tooltipPosition", "disabled", "loading", "iconSrc", "svgIcon"],
        outputs: ["iconClicked"]
      }]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: SearchMenuComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: "teg-search-menu",
      template: "<div class=\"teg-search-menu-btn\"\n     [matMenuTriggerFor]=\"searchMenu\"\n     #quickAccessMenuTrigger=\"matMenuTrigger\"\n     matTooltip=\"Search\"\n     (menuOpened)=\"onMenuOpened()\"\n     (menuClosed)=\"onMenuClosed()\">\n    <teg-icon-button [color]=\"iconColor\">search</teg-icon-button>\n</div>\n<mat-menu #searchMenu=\"matMenu\"\n          [overlapTrigger]=\"false\"\n          [hasBackdrop]=\"true\"\n          backdropClass=\"cdk-overlay-dark-backdrop\"\n          class=\"search-menu\">\n    <teg-search-filter [(filterTerm)]=\"filterTerm\"\n                       [focusSearch]=\"focusSearch\"\n                       [resetSearch]=\"resetSearch\"\n                       (filterTermChange)=\"onFilterTermChange($event)\"\n                       (clearSearchTerm)=\"onClearSearchTerm($event)\">\n    </teg-search-filter>\n</mat-menu>"
    }]
  }],
  ctorParameters: () => [],
  propDecorators: {
    focusSearch: [{
      type: Input
    }],
    resetSearch: [{
      type: Input
    }],
    filterTerm: [{
      type: Input
    }],
    iconColor: [{
      type: Input
    }],
    filterTermChange: [{
      type: Output
    }],
    clearSearchTerm: [{
      type: Output
    }],
    menuOpened: [{
      type: Output
    }]
  }
});
class SearchMenuModule {
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: SearchMenuModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: SearchMenuModule,
      declarations: [SearchMenuComponent],
      imports: [CommonModule, SearchFilterModule, MatMenuModule, IconButtonModule],
      exports: [SearchMenuComponent]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: SearchMenuModule,
      imports: [CommonModule, SearchFilterModule, MatMenuModule, IconButtonModule]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: SearchMenuModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [CommonModule, SearchFilterModule, MatMenuModule, IconButtonModule],
      exports: [SearchMenuComponent],
      declarations: [SearchMenuComponent],
      providers: []
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { SearchMenuComponent, SearchMenuModule };
