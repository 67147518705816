import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthCallbackComponent } from "./components/auth-callback/auth-callback.component";
import { ForbiddenComponent } from "./components/forbidden/forbidden.component";
import { UnauthorizedComponent } from "./components/unauthorized/unauthorized.component";
import { AuthGuardService } from "./services/auth-guard.service";
import { HomeComponent } from "./components/home/home.component";
import { LicenseAdminComponent } from "./components/license-admin/license-admin.component";
import { UserAdminComponent } from './components/user-admin/user-admin.component';
import { UserRoleComponent } from './components/user-role/user-role.component';
import { MetricsOverviewComponent } from "./components/metrics/overview/metrics-overview.component";
import { AccountComponent } from "./components/account/account.component";
import { AccountResolver } from "./account-resolver";
import { SubAccountComponent } from "./components/sub-account/sub-account.component";
import { OverviewComponent } from "./components/overview/overview.component";
import { HomeResolver } from "./home-resolver";
import { MetricsResolver } from "./metrics-resolver";
import { MetricsReportComponent } from "./components/metrics/report/metrics-report.component";

const routes: Routes = [
  {
    path: "auth-callback",
    component: AuthCallbackComponent
  },
  {
    path: "Forbidden",
    component: ForbiddenComponent
  },
  {
    path: "Unauthorized",
    component: UnauthorizedComponent
  },
  // {
  //   path: "licenseAdmin",
  //   canActivate: [AuthGuardService],
  //   component: LicenseAdminComponent
  // },
  // {
  //   path: "userAdmin",
  //   canActivate: [AuthGuardService],
  //   component: UserAdminComponent
  // },
  // {
  //   path: "userRole",
  //   canActivate: [AuthGuardService],
  //   component: UserRoleComponent
  // },
  // {
  //   path: "metrics",
  //   canActivate: [AuthGuardService],
  //   component: MetricsComponent
  // },
  {
    path: "",
    canActivate: [AuthGuardService],
    component: HomeComponent,
    resolve: {
      shareRoles: HomeResolver
    },
    children: [
      {
        path: "",
        canActivate: [AuthGuardService],
        component: AccountComponent,
        resolve: {
          accounts: AccountResolver
        }
      },
      {
        path: "licenses",
        canActivate: [AuthGuardService],
        component: LicenseAdminComponent
      },
      {
        path: "users",
        canActivate: [AuthGuardService],
        component: UserAdminComponent
      },
      {
        path: "roles",
        canActivate: [AuthGuardService],
        component: UserRoleComponent
      },
      {
        path: 'metrics',
        canActivate: [AuthGuardService],
        component: MetricsOverviewComponent
      },
      {
        path: 'metrics/:mode',
        canActivate: [AuthGuardService],
        component: MetricsOverviewComponent
      },
      {
        path: 'metrics/report/:report',
        canActivate: [AuthGuardService],
        component: MetricsReportComponent,
        resolve: {
          accounts: MetricsResolver
        }
      },
      {
        path: ":account",
        canActivate: [AuthGuardService],
        component: SubAccountComponent,
        resolve: {
          subAccounts: AccountResolver
        }
      },
      {
        path: ":account/:subAccount",
        canActivate: [AuthGuardService],
        component: OverviewComponent,
        resolve: {
          content: AccountResolver
        }
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
