import { AppStateService } from './../../services/app-state.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
    selector: 'app-auth-callback',
    templateUrl: './auth-callback.component.html',
    styleUrls: ['./auth-callback.component.css']
})
export class AuthCallbackComponent {

    constructor(
        private oidcSecurityService: OidcSecurityService,
        private appState: AppStateService,
        private router: Router
    ) {
        this.oidcSecurityService.isAuthenticated().subscribe((isAuthenticated: boolean) => {
            this.appState.isAuthorized = isAuthenticated;
            if (!this.appState.isAuthorized) {
                if (window.location.hash) {
                    this.oidcSecurityService.authorize();
                }
            } else {
                this.router.navigateByUrl('/');
            }
        });
    }
}
