import * as i0 from '@angular/core';
import { EventEmitter, Component, Input, Output, NgModule } from '@angular/core';
import * as i1 from '@theevolvedgroup/component-library/action-bar';
import { ActionBarModule } from '@theevolvedgroup/component-library/action-bar';
import { CommonModule } from '@angular/common';
class ContentSectionHeaderComponent {
  constructor() {
    this.iconClicked = new EventEmitter();
    this.menuClicked = new EventEmitter();
  }
  ngOnInit() {}
  onIconClicked(action) {
    this.iconClicked.emit(action);
  }
  onMenuClicked(menuAction) {
    this.menuClicked.emit(menuAction);
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: ContentSectionHeaderComponent,
      deps: [],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: ContentSectionHeaderComponent,
      selector: "teg-content-section-header",
      inputs: {
        title: "title",
        actions: "actions"
      },
      outputs: {
        iconClicked: "iconClicked",
        menuClicked: "menuClicked"
      },
      ngImport: i0,
      template: "<div class=\"content-section-header\">\n    <span class=\"content-section-header-title\">{{ title }}</span>\n    <div class=\"content-section-header-actions\">\n        <teg-action-bar [actions]=\"actions\"\n                        (iconClicked)=\"onIconClicked($event)\"\n                        (menuOptionClicked)=\"onMenuClicked($event)\"></teg-action-bar>\n    </div>\n</div>",
      styles: [".content-section-header{display:flex;flex-direction:row;gap:var(--theme-spacing-md);padding-top:var(--theme-spacing-xs);padding-bottom:var(--theme-spacing-xs);align-items:center}.content-section-header .content-section-header-title{text-transform:uppercase;font-weight:500;color:var(--theme-section-header-color);font-size:var(--theme-h3-font-size);-webkit-user-select:none;user-select:none;line-height:100%}\n"],
      dependencies: [{
        kind: "component",
        type: i1.ActionBarComponent,
        selector: "teg-action-bar",
        inputs: ["actions", "gap"],
        outputs: ["iconClicked", "buttonClicked", "menuOptionClicked"]
      }]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: ContentSectionHeaderComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: "teg-content-section-header",
      template: "<div class=\"content-section-header\">\n    <span class=\"content-section-header-title\">{{ title }}</span>\n    <div class=\"content-section-header-actions\">\n        <teg-action-bar [actions]=\"actions\"\n                        (iconClicked)=\"onIconClicked($event)\"\n                        (menuOptionClicked)=\"onMenuClicked($event)\"></teg-action-bar>\n    </div>\n</div>",
      styles: [".content-section-header{display:flex;flex-direction:row;gap:var(--theme-spacing-md);padding-top:var(--theme-spacing-xs);padding-bottom:var(--theme-spacing-xs);align-items:center}.content-section-header .content-section-header-title{text-transform:uppercase;font-weight:500;color:var(--theme-section-header-color);font-size:var(--theme-h3-font-size);-webkit-user-select:none;user-select:none;line-height:100%}\n"]
    }]
  }],
  ctorParameters: () => [],
  propDecorators: {
    title: [{
      type: Input
    }],
    actions: [{
      type: Input
    }],
    iconClicked: [{
      type: Output
    }],
    menuClicked: [{
      type: Output
    }]
  }
});
class ContentSectionHeaderModule {
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: ContentSectionHeaderModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: ContentSectionHeaderModule,
      declarations: [ContentSectionHeaderComponent],
      imports: [CommonModule, ActionBarModule],
      exports: [ContentSectionHeaderComponent]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: ContentSectionHeaderModule,
      imports: [CommonModule, ActionBarModule]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: ContentSectionHeaderModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [CommonModule, ActionBarModule],
      exports: [ContentSectionHeaderComponent],
      declarations: [ContentSectionHeaderComponent],
      providers: []
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { ContentSectionHeaderComponent, ContentSectionHeaderModule };
