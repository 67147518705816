;
var ChipContent;
(function (ChipContent) {
  ChipContent[ChipContent["Basic"] = 0] = "Basic";
})(ChipContent || (ChipContent = {}));

/**
 * Generated bundle index. Do not edit.
 */

export { ChipContent };
