<div
  *ngIf="!appState.isAuthorized"
  style="display:flex;flex-direction:column;height:100%;align-items: center;justify-content: center;"
>
  <img
    src="/assets/humanlistening.spinner.gif"
    style="width:101px;height:86px"
  />
  Starting Application
</div>

<router-outlet></router-outlet>
