export const ACCOUNT_METRICS_CONFIG: any = [
    {
        title: "User Activity",
        subtitle: "Activity levels and days since last login",
        action: "metrics_account_user_activity",
        bannerColor: "success",
        showEmailFilter: true,
        columns: [
            {
                field: "clientAccountGuid",
                headerText: "Account Guid",
                width: "320px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "clientAccountName",
                headerText: "Account Name",
                width: "100%",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "loginCount",
                headerText: "No. of Visits - Last 30 Days",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "dailyCount",
                headerText: "Active Users - Last 24 Hours",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "monthlyCount",
                headerText: "Active Users - Last 30 Days",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "daysSinceLastLogin",
                headerText: "Days Since Last Activity",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
        ]
    },
    {
        title: "Total Sessions",
        subtitle: "Total session durations (last 30 days)",
        action: "metrics_account_sessions",
        bannerColor: "success",
        showEmailFilter: true,
        columns: [
            {
                field: "clientAccountGuid",
                headerText: "Account Guid",
                width: "320px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "clientAccountName",
                headerText: "Account Name",
                width: "40%",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "totalSessionMinutes",
                headerText: "Total Sessions (Minutes)",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
        ]
    },
    {
        title: "Average Time Per User",
        subtitle: "Average no. of minutes per active user (last 30 days)",
        action: "metrics_account_avg_time_per_user",
        bannerColor: "success",
        showEmailFilter: true,
        columns: [
            {
                field: "clientAccountGuid",
                headerText: "Account Guid",
                width: "320px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "clientAccountName",
                headerText: "Account Name",
                width: "40%",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "shortestSessonMinutes",
                headerText: "Shortest Session (Minutes)",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "LongestSessionMinutes",
                headerText: "Longest Session (Minutes)",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "averageSessionMinutes",
                headerText: "Average Session (Minutes)",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "SessionCount",
                headerText: "Number of Sessions",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
        ]
    },
    {
        title: "Active Sub Accounts",
        subtitle: "Sub Accounts that have had activity in the last 30 days",
        action: "metrics_account_active_sub_accounts",
        bannerColor: "success",
        showEmailFilter: true,
        columns: [
            {
                field: "clientAccountGuid",
                headerText: "Account Guid",
                width: "320px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "clientAccountName",
                headerText: "Account Name",
                width: "40%",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "clientSubAccountGuid",
                headerText: "Sub Account Guid",
                width: "320px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "clientSubAccountName",
                headerText: "Sub Account Name",
                width: "40%",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "lastActiveDateTime",
                headerText: "Last Active Date",
                width: "180px",
                type: "date",
                format: "dd-MMM-yyyy HH:mm",
                allowFiltering: true,
                allowSorting: true
            }
        ]
    },
    {
        title: "Content Shares",
        subtitle: "Number of shares across all content",
        action: "metrics_account_content_shares",
        bannerColor: "success",
        showEmailFilter: true,
        columns: [
            {
                field: "clientAccountGuid",
                headerText: "Account Guid",
                width: "320px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "clientAccountName",
                headerText: "Account Name",
                width: "40%",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "contentTypeName",
                headerText: "Content Type",
                width: "240px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "emailShareCount",
                headerText: "Email Shares",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "groupShareCount",
                headerText: "Group Shares",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "domainShareCount",
                headerText: "Domain Shares",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "totalShareCount",
                headerText: "Total Shares",
                width: "240px",
                type: "number",
                allowFiltering: true,
                allowSorting: true
            }

        ]
    },
    {
        title: "Survey Counts",
        subtitle: "Number of surveys completed",
        action: "metrics_account_surveys",
        showEmailFilter: true,
    },
    {
        title: "Conversation Counts",
        subtitle: "Number of conversations completed",
        action: "metrics_account_conversations",
        showEmailFilter: true,
    },
    {
        title: "SMS Sent",
        subtitle: "SMS sent from the Account",
        action: "metrics_account_sms_sent",
        showEmailFilter: true,
    },
    {
        title: "Project Counts",
        subtitle: "Number of Survey and Conversational AI Projects",
        action: "metrics_account_project_counts",
        showEmailFilter: true,
    },
    {
        title: "Completion Counts",
        subtitle: "Survey and Conversational AI completion counts",
        action: "metrics_account_completion_counts",
        showEmailFilter: true,
    }
];

export const USER_METRICS_CONFIG: any = [
    {
        title: "User Activity Levels",
        subtitle: "Activity levels and days since last login",
        action: "metrics_user_active_users",
        bannerColor: "success",
        showEmailFilter: true,
        showInactiveFilter: true,
        columns: [
            {
                field: "firstName",
                headerText: "First Name",
                width: "320px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "lastName",
                headerText: "Last Name",
                width: "40%",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "email",
                headerText: "Email",
                width: "320px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "registeredDateTime",
                headerText: "Registered Date",
                width: "180px",
                type: "date",
                format: "dd-MMM-yyyy HH:mm",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "lastLoginDateTime",
                headerText: "Last Active Date",
                width: "180px",
                type: "date",
                format: "dd-MMM-yyyy HH:mm",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "companyName",
                headerText: "Company Name",
                width: "240px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "languageCode",
                headerText: "Language",
                width: "160px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "countryCode",
                headerText: "Country",
                width: "160px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            }
        ]
    },
    {
        title: "Creator Actions",
        subtitle: "Number of Survey and Conversation created, updated and published",
        action: "metrics_user_creator_actions",
        showEmailFilter: true,
    },
    {
        title: "Total Session Minutes",
        subtitle: "Total minutes of login time",
        action: "metrics_user_session_minutes",
        showEmailFilter: true,
    },
    {
        title: "New Users",
        subtitle: "Number of new users registered (last 30 days)",
        action: "metrics_user_new_users",
        bannerColor: "success",
        showEmailFilter: true,
        columns: [
            {
                field: "firstName",
                headerText: "First Name",
                width: "320px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "lastName",
                headerText: "Last Name",
                width: "40%",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "email",
                headerText: "Email",
                width: "320px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "registeredDateTime",
                headerText: "Registered Date",
                width: "180px",
                type: "date",
                format: "dd-MMM-yyyy HH:mm",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "lastLoginDateTime",
                headerText: "Last Active Date",
                width: "180px",
                type: "date",
                format: "dd-MMM-yyyy HH:mm",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "companyName",
                headerText: "Company",
                width: "240px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "languageCode",
                headerText: "Language",
                width: "160px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            },
            {
                field: "countryCode",
                headerText: "Country",
                width: "160px",
                type: "string",
                allowFiltering: true,
                allowSorting: true
            }
        ]
    }
]

export const APPLICATION_METRICS_CONFIG: any = [
    {
        title: "User Activity Level",
        subtitle: "Content created, updated and published",
        action: "metrics_application_user_activity",
        showEmailFilter: true,
    },
    {
        title: "Days Since Last Action",
        subtitle: "Days since the last action per application",
        action: "metrics_application_days_since_last_action",
        showEmailFilter: true,
    },
    {
        title: "New Activity",
        subtitle: "New content created and days since creation",
        action: "metrics_application_new_activity",
        showEmailFilter: true,
    },
    {
        title: "Active Projects",
        subtitle: "Surveys, Conversations and Dashboards with their activity levels",
        action: "metrics_application_active_projects",
        showEmailFilter: true,
    },
    {
        title: "Project Size",
        subtitle: "Survey completions, conversation completions and dashboard logins",
        action: "metrics_application_project_size",
        showEmailFilter: true,
    },
    {
        title: "Text Explorer API Usage",
        subtitle: "COMING SOON",
        action: "metrics_application_te-api-usage",
        showEmailFilter: true,
    }
]