import { DataService } from '../../services/data.service';
import { Component, HostListener, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AppStateService } from '../../services/app-state.service';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { AccountDialogComponent } from '../dialogs/account-dialog/account-dialog.component';
import { SubAccountDialogComponent } from '../dialogs/sub-account-dialog/sub-account-dialog.component';
import { TileClickEvent, TileField } from '@theevolvedgroup/component-library/tile';
import { Router } from '@angular/router';
import { DialogCloseResult } from '@theevolvedgroup/component-library/dialog-base/types';
import { DialogFactoryService, DialogService } from '@theevolvedgroup/component-library/dialog-base';
import { ViewType } from '@theevolvedgroup/component-library/content-section';
import { ShareDialogComponent } from '../dialogs/share-dialog/share-dialog.component';
import { ClientAccount, ContentTypeGuids, ContentStatusFields, ContentStatuses, ContentStatusEnum } from 'src/app/services/app-state.model';
import { ActionType, MenuOptionsType } from '@theevolvedgroup/component-library/action-bar/types';
import { FieldSettingsModel } from '@syncfusion/ej2-angular-dropdowns';


@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class AccountComponent implements OnInit {
  accountTiles: any = [];
  ViewType: ViewType = ViewType.Tile;

  headerHeight: number = 220;
  viewportHeight: number = window.innerHeight - this.headerHeight;

  contentStatusFields = ContentStatusFields;
  contentStatuses = [...ContentStatuses];
  selectedContentStatusId: number = ContentStatusEnum.Active;

  accountActions: any = [
    {
      icon: "list",
      outline: false,
      color: "primary",
      action: {
        name: "viewType",
        type: ActionType.Toggle,
        data: {
          selected: false,
          selectedIcon: {
            icon: "view_module",
            outline: false,
            color: "primary",
            tooltip: "Tile View",
          },
          unselectedIcon: {
            icon: "list",
            outline: false,
            color: "primary",
            tooltip: "List View",
          },
        },
      },
    },
  ];

  listViewFields: TileField[] = [
    {
      field: "clientAccountGuid",
      label: "Account Guid",
      textAlign: "left",
      width: "320px",
      showOnTile: false,
      showOnList: true,
    },
    {
      field: "clientAccountId",
      label: "Account ID",
      textAlign: "left",
      width: "240px",
      showOnTile: false,
      showOnList: true,
    },
    {
      field: "clientAccountName",
      label: "Account Name",
      textAlign: "left",
      width: "40%",
      showOnTile: false,
      showOnList: true,
    },
    {
      field: "clientAccountVanityName",
      label: "Account Vanity Name",
      textAlign: "left",
      width: "40%",
      showOnTile: false,
      showOnList: true,
    }
  ];

  constructor(
    public appState: AppStateService,
    public dataService: DataService,
    public dialog: MatDialog,
    private router: Router,
    private dialogFactoryService: DialogFactoryService) {

  }

  ngOnInit(): void {
    this.loadAccountTiles();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.viewportHeight = window.innerHeight - this.headerHeight;
  }

  loadAccountTiles() {
    this.accountTiles = [];

    const currentDate = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);

    _.forEach(this.appState.clientAccounts, (clientAccount: ClientAccount) => {

      if ((!clientAccount.isDeleted && this.selectedContentStatusId == ContentStatusEnum.Deleted) ||
        (!clientAccount.isArchived && this.selectedContentStatusId == ContentStatusEnum.Archived) ||
        ((clientAccount.isDeleted || clientAccount.isArchived) && this.selectedContentStatusId == ContentStatusEnum.Active))
        return;

      // Exclude anything deleted more than 30 days ago
      if (clientAccount.isDeleted && clientAccount.deletedDateTime && new Date(clientAccount.deletedDateTime) < thirtyDaysAgo)
        return;

      this.accountTiles.push({
        avatarUrl: clientAccount.clientAccountIconUrl,
        title: clientAccount.clientAccountName,
        data: {
          clientAccountGuid: clientAccount.clientAccountGuid,
          clientAccountId: clientAccount.clientAccountId,
          clientAccountName: clientAccount.clientAccountName,
          clientAccountVanityName: clientAccount.clientAccountVanityName,

        },
        fields: [...this.listViewFields],
        selectable: false,
        loading: false,
        actions: [
          {
            icon: "share",
            tooltip: "Share",
            outline: true,
            color: "var(--theme-color-grey-0)",
            action: {
              name: "share",
              data: { selected: false },
            },
          },
          {
            icon: "more_vert",
            action: { name: "more" },
            menuOptions: {
              groups: [
                {
                  type: MenuOptionsType.Default,
                  options: [
                    {
                      label: "Edit",
                      action: {
                        name: "edit",
                      },
                    },
                    {
                      label: clientAccount.isDeleted ? "Activate" : "Delete",
                      action: {
                        name: "delete",
                      },
                    },
                    {
                      label: clientAccount.isArchived ? "Unarchive" : "Archive",
                      action: {
                        name: "archive",
                      },
                    },
                  ],
                }
              ],
            },
          },
          {
            icon: "arrow_forward",
            tooltip: "Manage",
            outline: true,
            color: "var(--theme-color-grey-0)",
            action: {
              name: "manage",
              data: { selected: false },
            },
          },
        ],
        menuOptions: null,
        url: "#"
      });
    });

    this.accountTiles = [...this.accountTiles];
  }

  onAccountTileClicked(account) {
    this.appState.currentAccount = _.find(this.appState.clientAccounts, { clientAccountGuid: account.item.data.clientAccountGuid });
    this.router.navigateByUrl(this.appState.currentAccount.clientAccountVanityName);
  }

  onAddAccountTileClicked() {
    const dialogRef = this.dialog.open(SubAccountDialogComponent, {
      width: '600px',
      hasBackdrop: true,
      disableClose: true, // Whether the user can use escape or clicking outside to close a modal.
      data: {
        isAccount: true,
        mode: 'Add',
      }
    });

    dialogRef.afterClosed().subscribe((account: any) => {
      if (account) {
        this.appState.clientAccounts = [];
        this.appState.dataLoaded = false;
        this.dataService.getClientAccounts().subscribe((response: any) => {
          this.appState.clientAccounts = response.data.clientAccounts;

          this.loadAccountTiles();

          this.appState.dataLoaded = true;
        });
      }
    });
  }

  onItemOptionClicked(event: TileClickEvent) {
    if (!event.item.data)
      return;

    let account = _.find(this.appState.clientAccounts, { clientAccountGuid: event.item.data.clientAccountGuid });

    if (event.action.action) {
      if (event.action.action.name == "edit") {
        this.editAccount(account);
      }
      else if (event.action.action.name == "delete") {
        this.toggleDeleteAccount(account);
      }
      else if (event.action.action.name == "archive") {
        this.toggleArchiveAccount(account);
      }
    } else if (event.action) {

      if (event.action.name == "manage") {
        this.onAccountTileClicked(event);
      }
      else if (event.action.name == "share") {
        this.shareAccount(account);
      }
    }
  }

  onContentStatusChanged(event: any) {
    this.selectedContentStatusId = event.value;
    this.loadAccountTiles();
  }

  editAccount(account: any) {
    const dialogRef = this.dialog.open(AccountDialogComponent, {
      width: '600px',
      hasBackdrop: true,
      disableClose: true, // Whether the user can use escape or clicking outside to close a modal.
      data: {
        isAccount: true,
        mode: 'Edit',
        clientAccount: account
      }
    });

    dialogRef.afterClosed().subscribe((response: any) => {
      if (account) {
        account.clientAccountName = response.clientAccountName;
        account.clientAccountVanityName = response.clientAccountVanityName;
        account.clientAccountIconUrl = response.clientAccountIconUrl;

        this.loadAccountTiles();
      }
    });
  }

  shareAccount(account: ClientAccount) {
    this.dataService.getclientAccountShares(account.clientAccountGuid)
      .subscribe(response => {
        account.shareByEmails = response.data.shareByEmails;

        let contentComponentProperties = {
          shareByEmails: account.shareByEmails,
          contentTypeGuid: ContentTypeGuids.Account,
          contentItemGuid: account.clientAccountGuid
        };

        let shareDialog: DialogService = this.dialogFactoryService.openDynamicDialog({
          headerText: `Share Account - ${account.clientAccountName}`,
          contentComponent: ShareDialogComponent,
          buttons: [{ id: "close", caption: "DONE", colorType: "primary" }],
          contentComponentProperties, 
        }, { widthValue: 640 });
      });
  }

  toggleArchiveAccount(account) {
    let statusName = account.isArchived ? "unarchive" : "archive";
    let headerText = _.capitalize(statusName) + " Account";

    let confirmDialog = this.dialogFactoryService.openMessageDialog({
      headerText: headerText,
      contentComponentProperties: {
        message: `Are you sure you want to ${statusName} this account?`,
      },
      buttons: [{ id: "no", colorType: "secondary", caption: "NO" }, { id: "yes", colorType: "warn", caption: "YES" }],
      headerColor: "warn",
    });

    confirmDialog.closed$.subscribe((result: DialogCloseResult) => {
      if (result.button.id == 'yes') {
        account.isArchived = !account.isArchived;
        this.appState.dataLoaded = false;
        this.dataService
          .clientAccountArchive(account)
          .subscribe(response => {
            account.isArchived = response.data.clientAccountArchive.isArchived;
            account.archivedByEmailAddress = response.data.clientAccountArchive.archivedByEmailAddress;
            account.archivedDateTime = response.data.clientAccountArchive.archivedDateTime;

            this.loadAccountTiles();

            this.appState.dataLoaded = true;
          });
      }
    });
  }

  toggleDeleteAccount(account) {
    let statusName = account.isDeleted ? "activate" : "delete";
    let headerText = _.capitalize(statusName) + " Account";

    let confirmDialog = this.dialogFactoryService.openMessageDialog({
      headerText: headerText,
      contentComponentProperties: {
        message: `Are you sure you want to ${statusName} this account?`,
      },
      buttons: [{ id: "no", colorType: "secondary", caption: "NO" }, { id: "yes", colorType: "warn", caption: "YES" }],
      headerColor: "warn",
    });

    confirmDialog.closed$.subscribe((result: DialogCloseResult) => {
      if (result.button.id == 'yes') {
        account.isDeleted = !account.isDeleted;
        this.appState.dataLoaded = false;
        this.dataService
          .clientAccountDelete(account)
          .subscribe(response => {
            account.isDeleted = response.data.clientAccountDelete.isDeleted;
            account.deletedByEmailAddress = response.data.clientAccountDelete.deletedByEmailAddress;
            account.deletedDateTime = response.data.clientAccountDelete.deletedDateTime;

            this.loadAccountTiles();

            this.appState.dataLoaded = true;
          });
      }
    });
  }
}


