<div
  *ngIf="!appState.dataLoaded"
  style="display:flex;flex-direction:column;height:100%;align-items: center;justify-content: center;"
>
  <img
    src="/assets/humanlistening.spinner.gif"
    style="width:101px;height:86px"
  />
  Loading Content
</div>

<div class="content-section-wrapper">
    <div class="filter-container">
      <teg-dropdown class="filter"
                    label="Prod. Date" 
                    [data]="prodDates"
                    [fields]="prodDateFields" 
                    [(ngModel)]="selectedProdDateId"
                    (selectionChanged)="onProdDateChanged($event)" >
      </teg-dropdown>
    </div>
    <div *ngIf="surveyTiles.length < 1; else surveys">
      <div class="no-content-message">
        <span>No surveys available</span>
      </div>
    </div>
    <ng-template #surveys>
      <teg-content-section 
          title="Surveys"
          [actions]="surveyActions"
          [tiles]="surveyTiles"
          [viewType]="ViewType"
          [enableVirtualScroll]="true"
          [virtualScrollHeight]="viewportHeight"
          [visible]="true"
          [loading]="!appState.dataLoaded"
          [enableContentLimit]="false"
          (itemOptionClicked)="onItemOptionClicked($event, 'survey')">
      </teg-content-section>
    </ng-template>
</div>