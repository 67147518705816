<div *ngIf="loading" style="display:flex;align-items:center;justify-content:center;height:100%;">
    Loading...
</div>

<ng-container *ngIf="!loading">
    <div mat-dialog-draggable-title class="dialog-header" *ngIf="data.mode" innerHtml="{{ data.mode }}">
    </div>
    <form #subAccountForm="ngForm">
        <mat-dialog-content>
            <div style="display: flex;flex-direction: column;" *ngIf="isAccount">
                <mat-form-field>
                    <input matInput placeholder="Account Name" [(ngModel)]="account.clientAccountName"
                        name="clientAccountName" required #clientAccountName="ngModel" cdkFocusInitial
                        pattern="(?=^.{1,100}$)(?!^[ \-,.]+)(?!^.*[ \-,]$)^[A-Za-z0-9 \-,.]+$"
                        (input)="onAccountNameChanged($event)" />
                    <mat-error *ngIf="clientAccountName.hasError('required')">
                        Account Name is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="clientAccountName.hasError('pattern')">
                        Account Name is <strong>invalid</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input matInput placeholder="Account Vanity Name" [(ngModel)]="account.clientAccountVanityName"
                        name="clientAccountVanityName" required #clientAccountVanityName="ngModel"
                        pattern="(?=^.{1,100}$)(?!^[\-]+)(?!^.*[\-]$)^[A-Za-z0-9\-]+$" />
                    <mat-error *ngIf="clientAccountVanityName.hasError('required')">
                        Account Vanity Name is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="clientAccountVanityName.hasError('pattern')">
                        Account Vanity Name is <strong>invalid</strong>
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <span *ngIf="
                            !iconUrlEditing &&
                            account.clientAccountIconUrl &&
                            account.clientAccountIconUrl.length > 0
                        " matPrefix mat-card-avatar class="mat-card-avatar"><img [src]="account.clientAccountIconUrl"
                            width="70" /></span>
                    <input matInput placeholder="Icon Image (70px x 70px)" [hidden]="
                            !iconUrlEditing &&
                            account.clientAccountIconUrl &&
                            account.clientAccountIconUrl.length > 0
                        " [(ngModel)]="account.clientAccountIconUrl" name="clientAccountIconUrl"
                        (blur)="iconUrlEditing = false" #clientAccountIconUrl="ngModel" required />
                    <button mat-icon-button matSuffix (click)="iconUrlEditing = true">
                        <mat-icon matSuffix matTooltip="Manually edit filename">edit</mat-icon>
                    </button>
                    <button mat-icon-button matSuffix (click)="account.clientAccountIconUrl = ''">
                        <mat-icon matSuffix matTooltip="Clear filename">clear</mat-icon>
                    </button>
                    <label matSuffix for="iconUrlUploadAccount">
                        <button mat-icon-button matSuffix (click)="iconUrlInputAccount.click()">
                            <mat-icon matSuffix matTooltip="Upload a file">file_upload</mat-icon>
                        </button>
                    </label>
                    <mat-error *ngIf="clientAccountIconUrl.hasError('required')">
                        Icon URL is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <input type="file" id="iconUrlUploadAccount" #iconUrlInputAccount ng2FileSelect [style.display]="'none'"
                    [uploader]="uploader" (change)="iconUrlInputAccount.value = ''" />
                <mat-progress-bar *ngIf="response && !response.status" class="upload-spinner" mode="determinate"
                    [value]="response.progress"></mat-progress-bar>
                <mat-checkbox [(ngModel)]="account.config.hideTitle" [labelPosition]="'before'" name="hideTitle">Hide
                    Account Tile
                    Label</mat-checkbox>
                <mat-form-field><input matInput placeholder="No of years Account Valid" [(ngModel)]="validForYears"
                        name="validForYears" pattern="^[0-9]*$" #clientSubAccountYears="ngModel" />
                    <mat-error *ngIf="clientSubAccountYears.hasError('pattern')">
                        ValidForYears <strong>invalid</strong></mat-error>
                </mat-form-field>
                <mat-form-field> <input matInput placeholder="No of licenses" [(ngModel)]="licenseCount"
                        name="licenseCount" pattern="^[0-9]*$" #clientSubAccountLicenseCount="ngModel" />
                    <mat-error *ngIf="clientSubAccountLicenseCount.hasError('pattern')">
                        LicenseCount <strong>invalid</strong></mat-error>
                </mat-form-field>
            </div>
            <div style="display: flex;flex-direction: column;">
                <mat-form-field>
                    <input matInput placeholder="Sub Account Name (no spaces or special characters allowed)"
                        [(ngModel)]="subAccount.clientSubAccountName" name="clientSubAccountName" required
                        #clientSubAccountName="ngModel" autofocus
                        pattern="(?=^.{1,100}$)(?!^[\-]+)(?!^.*[\-]$)^[A-Za-z0-9\-]+$" />
                    <mat-error *ngIf="clientSubAccountName.hasError('required')">
                        Sub Account Name is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="clientSubAccountName.hasError('pattern')">
                        Sub Account Name is <strong>invalid</strong>
                    </mat-error>
                </mat-form-field>
                <div
                    class="mat-form-field mat-primary mat-form-field-type-mat-input mat-form-field-appearance-legacy mat-form-field-can-float mat-form-field-has-label mat-form-field-hide-placeholder mat-form-field-should-float">
                    <div class="mat-form-field-wrapper">
                        <div class="mat-form-field-flex">
                            <div class="mat-form-field-infix">
                                <ejs-dropdownlist [enabled]="data.mode == 'Add'" id='ddlLocation'
                                    [fields]='{ text: "locationDescription", value: "locationCode" }'
                                    [(value)]="selectedLocation" (change)="onLocationChange($event)"
                                    [dataSource]='dataDomiciledLocations' placeholder='Select Data Domicile Location'>
                                </ejs-dropdownlist>
                                <span class="mat-form-field-label-wrapper">
                                    <label class="mat-form-field-label" id="mat-form-field-label-5" for="ddlLocation">
                                        <span>Data Domicile Location</span>
                                    </label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <mat-form-field>
                    <span *ngIf="
                      !iconUrlEditing &&
                      subAccount.clientSubAccountIconUrl &&
                      subAccount.clientSubAccountIconUrl.length>
                  0
                  " matPrefix mat-card-avatar class="mat-card-avatar"><img [src]="subAccount.clientSubAccountIconUrl"
                            width="70" />
                    </span>
                    <input matInput placeholder="Icon Image (70px x 70px)" [hidden]="
                       !iconUrlEditing &&
                       subAccount.clientSubAccountIconUrl &&
                       subAccount.clientSubAccountIconUrl.length> 0
                " [(ngModel)]="subAccount.clientSubAccountIconUrl" name="clientSubAccountIconUrl"
                        (blur)="iconUrlEditing = false" #clientSubAccountIconUrl="ngModel" required />
                    <button mat-icon-button matSuffix (click)="iconUrlEditing = true">
                        <mat-icon matSuffix matTooltip="Manually edit filename">edit</mat-icon>
                    </button>
                    <button mat-icon-button matSuffix (click)="subAccount.clientSubAccountIconUrl = ''">
                        <mat-icon matSuffix matTooltip="Clear filename">clear</mat-icon>
                    </button>
                    <label matSuffix for="iconUrlUpload">
                        <button mat-icon-button matSuffix (click)="iconUrlInput.click()">
                            <mat-icon matSuffix matTooltip="Upload a file">file_upload</mat-icon>
                        </button>
                    </label>
                    <mat-error *ngIf="clientSubAccountIconUrl.hasError('required')">
                        Icon URL is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <input type="file" id="iconUrlUpload" #iconUrlInput ng2FileSelect [style.display]="'none'"
                    [uploader]="uploader" (change)="iconUrlInput.value = ''" />
                <mat-progress-bar *ngIf="response && !response.status" class="upload-spinner" mode="determinate"
                    [value]="response.progress"></mat-progress-bar>
                <mat-form-field>
                    <input matInput placeholder="Survey BaseUrl" [(ngModel)]="subAccount.config.surveyBaseUrl"
                        name="surveyBaseUrl" />
                </mat-form-field>
                <mat-form-field>
                    <input matInput placeholder="Help Url" [(ngModel)]="subAccount.config.helpUrl" name="helpUrl" />
                </mat-form-field>
                <mat-checkbox [checked]="subAccount.config.hideTitle"
                    (change)="subAccount.config.hideTitle = !subAccount.config.hideTitle" [labelPosition]="'before'"
                    name="hideTitle">
                    Hide Sub Acc Title label
                </mat-checkbox>
                <!-- <mat-checkbox [(ngModel)]="subAccount.config.isPeopleListening" [labelPosition]="'before'"
      name="isPeopleListening">
      Is People Listening</mat-checkbox> -->
                <mat-checkbox [checked]="!subAccount.config.useNewShaper"
                    (change)="subAccount.config.useNewShaper = !subAccount.config.useNewShaper"
                    [labelPosition]="'before'" name="useNewShaper">
                    Use Legacy Survey Shaper
                </mat-checkbox>
                <mat-checkbox [checked]="subAccount.config.enableSMS"
                    (change)="subAccount.config.enableSMS = !subAccount.config.enableSMS" [labelPosition]="'before'"
                    name="enableSMS">
                    Enable SMS
                </mat-checkbox>
                <mat-checkbox [checked]="!subAccount.config.enableEmail"
                    (change)="subAccount.config.enableEmail = !subAccount.config.enableEmail" [labelPosition]="'before'"
                    name="enableEmail">
                    Disable Email
                </mat-checkbox>
                <mat-checkbox [checked]="!subAccount.config.useNewControlPanel"
                    (change)="subAccount.config.useNewControlPanel = !subAccount.config.useNewControlPanel"
                    [labelPosition]="'before'" name="useNewControlPanel">
                    Use Legacy Control Panel
                </mat-checkbox>
                <mat-checkbox [checked]="subAccount.config.enableTranslations"
                    (change)="subAccount.config.enableTranslations = !subAccount.config.enableTranslations"
                    [labelPosition]="'before'" name="enableTranslations">
                    Enable Translations
                </mat-checkbox>
                <mat-checkbox [checked]="subAccount.config.useLearningLoops"
                    (change)="subAccount.config.useLearningLoops = !subAccount.config.useLearningLoops"
                    [labelPosition]="'before'" name="useLearningLoops">
                    Use Learning Loops
                </mat-checkbox>
                <mat-checkbox [checked]="subAccount.config.enableFileBankUpload"
                    (change)="subAccount.config.enableFileBankUpload = !subAccount.config.enableFileBankUpload"
                    [labelPosition]="'before'" name="enableFileBankUpload">
                    Enable Survey File Bank Upload
                </mat-checkbox>
                <mat-checkbox [checked]="subAccount.config.enableCaseManagerPIINotifications"
                    (change)="subAccount.config.enableCaseManagerPIINotifications = !subAccount.config.enableCaseManagerPIINotifications"
                    [labelPosition]="'before'" name="enableCaseManagerPIINotifications">
                    Enable case manager notifications PII info
                </mat-checkbox>
                <mat-checkbox [checked]="subAccount.config.useIntuify"
                    (change)="subAccount.config.useIntuify = !subAccount.config.useIntuify"
                    [labelPosition]="'before'" name="useIntuify">
                    Allow Intuify Questions in Shaper
                </mat-checkbox>
                <mat-checkbox [checked]="subAccount.config.enableLLM"
                    (change)="subAccount.config.enableLLM = !subAccount.config.enableLLM"
                    [labelPosition]="'before'" name="enableLLM">
                    Enable LLM
                </mat-checkbox>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button color="secondary" (click)="cancel()">Cancel</button>
            <!-- Can optionally provide a result for the closing dialog. -->
            <button mat-button color="primary" (click)="save()" [disabled]="subAccountForm.invalid || saving">
                Save
            </button>
        </mat-dialog-actions>
    </form>
</ng-container>