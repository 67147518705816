
<div
  *ngIf="!appState.dataLoaded; else content"
  style="display:flex;flex-direction:column;height:100%;align-items: center;justify-content: center;"
>
  <img
    src="/assets/humanlistening.spinner.gif"
    style="width:101px;height:86px"
  />
  Loading Accounts
</div>

<ng-template #content>
  <div *ngIf="!appState.currentAccount && !appState.currentSubAccount"  class="content-section-wrapper">
      <div class="filter-container">
        <teg-dropdown class="filter"
                      label="Status" 
                      [data]="contentStatuses"
                      [fields]="contentStatusFields" 
                      [(ngModel)]="selectedContentStatusId"
                      (selectionChanged)="onContentStatusChanged($event)" >
        </teg-dropdown>
      </div>
      <teg-content-section 
          title="Accounts"
          [tiles]="accountTiles"
          [actions]="accountActions"
          [loading]= "false"
          [showAddTile] = "true"
          [viewType]="ViewType"
          [enableVirtualScroll]="true"
          [virtualScrollHeight]="viewportHeight"
          (tileClicked)="onAccountTileClicked($event)"
          (addItem)="onAddAccountTileClicked()"
          (itemOptionClicked)="onItemOptionClicked($event)">
      </teg-content-section>
  </div>
</ng-template>