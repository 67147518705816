<teg-header [loggedIn]="true"
    [homeIcon]=""
    [avatarUrl]="appState.userProfile?.avatarUrl"
    [emailAddress]="appState.userProfile?.email"
    [fullName]="appState.userProfile?.fullName"
    [actions]="defaultArgs.actions"
    [menuItems]="defaultArgs.menuItems"
    [avatarMenuOptions]="defaultArgs.avatarMenuOptions"
    [subHeaderActions]="defaultArgs.subHeaderActions"
    [breadcrumbs]="appState.breadcrumbs"
    [accountList]="defaultArgs.accountList"
    [subAccountList]="defaultArgs.currentAccount.subAccounts"
    [currentAccount]="defaultArgs.currentAccount"
    [currentSubAccount]="defaultArgs.currentSubAccount"
    [helpHidden]="true"
    [showSearch]="false"
    [showQuickAccessMenu]="false"
    (avatarMenuClicked)="onAvatarMenuClicked($event)"
    (navBarItemSelected)="onNavBarItemSelected($event)"
    (breadcrumbClicked)="onBreadcrumbClicked($event)"
    >
    <ng-container headerActions>
    </ng-container>
</teg-header> 


<div class="content-container">
    <router-outlet></router-outlet>
</div>
