<h1 mat-dialog-title>{{data.role}}</h1>
<div mat-dialog-content>
    <div style="display: flex;flex-direction: row; align-items: center;padding-bottom: 5px;">
        <span style="padding-right: 5px;">Email:</span>
        <mat-form-field>
            <input matInput placeholder="Email" [(ngModel)]="emailAddress" name="emailAddress" required
                #emailAddressField="ngModel" style="width: 300px;"
                pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" />
            <mat-error *ngIf="emailAddressField.hasError('required')">
                Email is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="emailAddressField.hasError('pattern')">
                Email is <strong>invalid</strong>
            </mat-error>
        </mat-form-field>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button color="secondary" (click)="onNoClick()">Cancel</button>
    <!-- Can optionally provide a result for the closing dialog. -->
    <button mat-button color="primary" [mat-dialog-close]="emailAddress">
        Save
    </button>
</mat-dialog-actions>