import * as i0 from '@angular/core';
import { EventEmitter, Component, Input, Output, NgModule } from '@angular/core';
import { ActionType } from '@theevolvedgroup/component-library/action-bar/types';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@angular/material/core';
import { MatRippleModule } from '@angular/material/core';
import * as i3 from '@theevolvedgroup/component-library/icon';
import { IconModule } from '@theevolvedgroup/component-library/icon';
import * as i4 from '@angular/material/tooltip';
import { MatTooltipModule } from '@angular/material/tooltip';
import * as i5 from '@angular/material/progress-spinner';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
class IconButtonComponent {
  constructor() {
    /** Material library of icons to use */
    this.library = "symbols";
    /* Tooltip position for Icon Button */
    this.tooltipPosition = "below";
    this.iconClicked = new EventEmitter();
    this.sizeValue = 24;
  }
  ngOnInit() {
    if (!this.library) {
      this.library = "symbols";
    }
    if (!this.tooltipPosition) {
      this.tooltipPosition = "below";
    }
  }
  ngOnChanges(changes) {
    this.initToggleState();
    if (changes.size) {
      this.setSize();
    }
  }
  onIconClicked(event) {
    event.preventDefault();
    if (this.disabled == undefined || !this.disabled) {
      this.handleToggle();
      if (this.action) {
        this.action.event = event;
      }
      this.iconClicked.emit(this.action);
    }
  }
  setSize() {
    if (this.size && this.size == "xs") {
      this.sizeValue = 16;
    } else if (this.size && this.size == "small") {
      this.sizeValue = 24;
    } else if (this.size && this.size == "medium") {
      this.sizeValue = 28;
    } else if (this.size && this.size == "large") {
      this.sizeValue = 32;
    }
    if (!this.opticalSize) {
      this.opticalSize = this.sizeValue;
    }
  }
  handleToggle() {
    if (this.action?.type == ActionType.Toggle || this.action?.type == ActionType.ToggleOutline) {
      this.action.data.selected = !this.action.data?.selected;
      if (this.action?.type == ActionType.ToggleOutline) {
        this.outline = !this.outline;
      } else if (this.action?.type == ActionType.Toggle) {
        if (this.action.data.selected) {
          this.setIcon(this.action.data.selectedIcon);
        } else {
          this.setIcon(this.action.data.unselectedIcon);
        }
      }
    }
  }
  initToggleState() {
    if (this.action?.type == ActionType.ToggleOutline) {
      this.outline = !this.action.data?.selected;
    } else if (this.action?.type == ActionType.Toggle) {
      if (this.action.data.selected) {
        this.setIcon(this.action.data.selectedIcon);
      } else {
        this.setIcon(this.action.data.unselectedIcon);
      }
    }
  }
  setIcon(selectedIcon) {
    this.icon = selectedIcon.icon;
    this.iconSrc = selectedIcon.iconSrc;
    this.svgIcon = selectedIcon.svgIcon;
    this.color = selectedIcon.color;
    this.size = selectedIcon.size;
    this.outline = selectedIcon.outline;
    this.variant = selectedIcon.variant;
    this.tooltip = selectedIcon.tooltip;
    this.tooltipPosition = selectedIcon.tooltipPosition ?? "below";
    this.iconWeight = selectedIcon.iconWeight;
    this.opticalSize = selectedIcon.opticalSize;
    this.customClass = selectedIcon.customClass;
    this.setSize();
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: IconButtonComponent,
      deps: [],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: IconButtonComponent,
      selector: "teg-icon-button",
      inputs: {
        customClass: "customClass",
        icon: "icon",
        outline: "outline",
        color: "color",
        size: "size",
        variant: "variant",
        library: "library",
        opticalSize: "opticalSize",
        iconWeight: "iconWeight",
        action: "action",
        tooltip: "tooltip",
        tooltipPosition: "tooltipPosition",
        disabled: "disabled",
        loading: "loading",
        iconSrc: "iconSrc",
        svgIcon: "svgIcon"
      },
      outputs: {
        iconClicked: "iconClicked"
      },
      host: {
        properties: {
          "style.--icon-size": "sizeValue + 'px'",
          "style.pointer-events": "disabled ? 'none' : 'auto'"
        }
      },
      usesOnChanges: true,
      ngImport: i0,
      template: "<ng-container *ngIf=\"!loading; else iconLoadingTemplate\">\n    <div #iconButton\n         class=\"icon-button\"\n         [ngClass]=\"{ 'disabled': disabled }\"\n         matRipple\n         matRippleColor=\"var(--theme-color-default-ripple)\"\n         [matRippleUnbounded]=\"true\"\n         [matRippleCentered]=\"true\"\n         [matRippleDisabled]=\"disabled\"\n         [matTooltip]=\"tooltip\"\n         [matTooltipPosition]=\"tooltipPosition\"\n         (click)=\"onIconClicked($event)\">\n        <teg-icon [icon]=\"icon\"\n                  [outline]=\"outline\"\n                  [color]=\"(disabled) ?  'var(--theme-color-disabled)' : color\"\n                  [size]=\"size\"\n                  [variant]=\"variant\"\n                  [library]=\"library\"\n                  [customClass]=\"customClass\"\n                  [opticalSize]=\"opticalSize\"\n                  [iconWeight]=\"iconWeight\"\n                  [iconSrc]=\"iconSrc\"\n                  [svgIcon]=\"svgIcon\">\n            <ng-container #content>\n                <ng-content></ng-content>\n            </ng-container>\n            <ng-container *ngIf=\"!content.children?.length\">\n                {{ icon }}\n            </ng-container>\n        </teg-icon>\n    </div>\n</ng-container>\n<ng-template #iconLoadingTemplate>\n    <mat-spinner class=\"icon-loading\"></mat-spinner>\n</ng-template>",
      styles: [".icon-button{cursor:pointer;display:flex;align-items:center;justify-content:center;-webkit-user-select:none;user-select:none;height:var(--icon-height, var(--icon-size));width:var(--icon-width, var(--icon-size))}.icon-button.disabled teg-icon{cursor:not-allowed!important}.icon-loading{cursor:wait}\n"],
      dependencies: [{
        kind: "directive",
        type: i1.NgClass,
        selector: "[ngClass]",
        inputs: ["class", "ngClass"]
      }, {
        kind: "directive",
        type: i1.NgIf,
        selector: "[ngIf]",
        inputs: ["ngIf", "ngIfThen", "ngIfElse"]
      }, {
        kind: "directive",
        type: i2.MatRipple,
        selector: "[mat-ripple], [matRipple]",
        inputs: ["matRippleColor", "matRippleUnbounded", "matRippleCentered", "matRippleRadius", "matRippleAnimation", "matRippleDisabled", "matRippleTrigger"],
        exportAs: ["matRipple"]
      }, {
        kind: "component",
        type: i3.IconComponent,
        selector: "teg-icon",
        inputs: ["customClass", "icon", "outline", "color", "size", "variant", "library", "opticalSize", "iconWeight", "iconSrc", "svgIcon"]
      }, {
        kind: "directive",
        type: i4.MatTooltip,
        selector: "[matTooltip]",
        inputs: ["matTooltipPosition", "matTooltipPositionAtOrigin", "matTooltipDisabled", "matTooltipShowDelay", "matTooltipHideDelay", "matTooltipTouchGestures", "matTooltip", "matTooltipClass"],
        exportAs: ["matTooltip"]
      }, {
        kind: "component",
        type: i5.MatProgressSpinner,
        selector: "mat-progress-spinner, mat-spinner",
        inputs: ["color", "mode", "value", "diameter", "strokeWidth"],
        exportAs: ["matProgressSpinner"]
      }]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: IconButtonComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: "teg-icon-button",
      host: {
        "[style.--icon-size]": "sizeValue + 'px'",
        "[style.pointer-events]": "disabled ? 'none' : 'auto'"
      },
      template: "<ng-container *ngIf=\"!loading; else iconLoadingTemplate\">\n    <div #iconButton\n         class=\"icon-button\"\n         [ngClass]=\"{ 'disabled': disabled }\"\n         matRipple\n         matRippleColor=\"var(--theme-color-default-ripple)\"\n         [matRippleUnbounded]=\"true\"\n         [matRippleCentered]=\"true\"\n         [matRippleDisabled]=\"disabled\"\n         [matTooltip]=\"tooltip\"\n         [matTooltipPosition]=\"tooltipPosition\"\n         (click)=\"onIconClicked($event)\">\n        <teg-icon [icon]=\"icon\"\n                  [outline]=\"outline\"\n                  [color]=\"(disabled) ?  'var(--theme-color-disabled)' : color\"\n                  [size]=\"size\"\n                  [variant]=\"variant\"\n                  [library]=\"library\"\n                  [customClass]=\"customClass\"\n                  [opticalSize]=\"opticalSize\"\n                  [iconWeight]=\"iconWeight\"\n                  [iconSrc]=\"iconSrc\"\n                  [svgIcon]=\"svgIcon\">\n            <ng-container #content>\n                <ng-content></ng-content>\n            </ng-container>\n            <ng-container *ngIf=\"!content.children?.length\">\n                {{ icon }}\n            </ng-container>\n        </teg-icon>\n    </div>\n</ng-container>\n<ng-template #iconLoadingTemplate>\n    <mat-spinner class=\"icon-loading\"></mat-spinner>\n</ng-template>",
      styles: [".icon-button{cursor:pointer;display:flex;align-items:center;justify-content:center;-webkit-user-select:none;user-select:none;height:var(--icon-height, var(--icon-size));width:var(--icon-width, var(--icon-size))}.icon-button.disabled teg-icon{cursor:not-allowed!important}.icon-loading{cursor:wait}\n"]
    }]
  }],
  ctorParameters: () => [],
  propDecorators: {
    customClass: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    outline: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    variant: [{
      type: Input
    }],
    library: [{
      type: Input
    }],
    opticalSize: [{
      type: Input
    }],
    iconWeight: [{
      type: Input
    }],
    action: [{
      type: Input
    }],
    tooltip: [{
      type: Input
    }],
    tooltipPosition: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    loading: [{
      type: Input
    }],
    iconSrc: [{
      type: Input
    }],
    svgIcon: [{
      type: Input
    }],
    iconClicked: [{
      type: Output
    }]
  }
});
class IconButtonModule {
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: IconButtonModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: IconButtonModule,
      declarations: [IconButtonComponent],
      imports: [CommonModule, MatRippleModule, IconModule, MatTooltipModule, MatProgressSpinnerModule],
      exports: [IconButtonComponent]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: IconButtonModule,
      imports: [CommonModule, MatRippleModule, IconModule, MatTooltipModule, MatProgressSpinnerModule]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: IconButtonModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [CommonModule, MatRippleModule, IconModule, MatTooltipModule, MatProgressSpinnerModule],
      exports: [IconButtonComponent],
      declarations: [IconButtonComponent],
      providers: []
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { IconButtonComponent, IconButtonModule };
