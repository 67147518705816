<div
  *ngIf="!appState.dataLoaded; else content"
  style="display:flex;flex-direction:column;height:100%;align-items: center;justify-content: center;"
>
  <img
    src="/assets/humanlistening.spinner.gif"
    style="width:101px;height:86px"
  />
  Loading Metrics
</div>

<ng-template #content>
    <div class="report-container">
        <div class="report-header-container">
            <div class="title-container">
                <div class="title">{{reportTitle}}</div>
                <div class="subtitle">{{reportSubtitle}}</div>
            </div>
            <div class="actions-container">
              <teg-slide-toggle *ngIf="showInactiveFilter" 
                                  [ariaLabel]="'Include Inactive Users'" 
                                  [(checked)]="showInactiveUsers"
                                  (checkedChange)="onInactiveUsersFilerChanged($event)">
                                  <span title="Display users who have not had any activity in the last 30 days.">Include Inactive Users</span>
                </teg-slide-toggle>
                <teg-slide-toggle *ngIf="showEmailFilter" 
                                  [ariaLabel]="'External Users Only'" 
                                  [(checked)]="externalUsersOnly"
                                  (checkedChange)="onEmailFilterChanged($event)">
                    <span title="Display results for users external to The Evolved Group. @theevolvedgroup.com and @humanlistening.com Email addresses are considered internal.">External Users only</span>
                </teg-slide-toggle>
              <teg-button (click)="onDownloadClicked()">Download</teg-button>
            </div>
        </div>
        <div class="report-table-container">
            
            <teg-table  [dataSource]="reportDataSource" 
                        [columns]="reportColumns"
                        [allowFiltering]="true"
                        [allowSorting]="true"
                        [height]="viewportHeight">
            </teg-table>
        </div>
        <div class="report-footer-container">
        </div>
    </div>
</ng-template>
