import * as i0 from '@angular/core';
import { EventEmitter, Component, Attribute, Optional, Self, Inject, ViewChild, Input, Output, NgModule } from '@angular/core';
import * as i2 from '@angular/forms';
import { NgControl } from '@angular/forms';
import * as i1 from '@angular/material/slide-toggle';
import { MatSlideToggle, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CustomControlValueAccessor } from '@theevolvedgroup/component-library/utils';
import { CommonModule } from '@angular/common';
class SlideToggleComponent extends CustomControlValueAccessor {
  constructor(tabIndex, _changeDetectorRef, ngControl) {
    super(ngControl);
    this.tabIndex = tabIndex;
    this._changeDetectorRef = _changeDetectorRef;
    this.ngControl = ngControl;
    /** Used to set the aria-label attribute on the underlying input element. */
    this.ariaLabel = null;
    /** Used to set the aria-labelledby attribute on the underlying input element. */
    this.ariaLabelledby = null;
    /** Whether the slide-toggle element is checked or not */
    this.checked = false;
    /** Whether the component is disabled or not */
    this.disabled = false;
    /** Whether the label should appear after or before the slide-toggle. Defaults to 'after'. */
    this.labelPosition = 'after';
    /** Name value will be applied to the input element if present. */
    this.name = null;
    /** Whether the slide-toggle is required. */
    this.required = false;
    /** An event will be dispatched each time the slide-toggle changes its value. */
    this.checkedChange = new EventEmitter();
    /** An event will be dispatched each time the slide-toggle input is toggled.
     * This event is always emitted when the user toggles the slide toggle, but
     * this does not mean the slide toggle's value has changed.
     */
    this.toggleChange = new EventEmitter();
  }
  ngOnInit() {}
  onChange(changeEvent) {
    this.checked = changeEvent.checked;
    this._onChange(changeEvent.checked);
    this.checkedChange.emit(changeEvent.checked);
  }
  onToggleChange() {
    this.toggleChange.emit();
  }
  /** Override CustomControlValueAccessor writeValue */
  writeValue(value) {
    this.checked = value;
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: SlideToggleComponent,
      deps: [{
        token: 'tabindex',
        attribute: true
      }, {
        token: i0.ChangeDetectorRef
      }, {
        token: NgControl,
        optional: true,
        self: true
      }],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: SlideToggleComponent,
      selector: "teg-slide-toggle",
      inputs: {
        ariaLabel: ["aria-label", "ariaLabel"],
        ariaLabelledby: ["aria-labelledby", "ariaLabelledby"],
        ariaDescribedby: ["aria-describedby", "ariaDescribedby"],
        checked: "checked",
        disabled: "disabled",
        labelPosition: "labelPosition",
        name: "name",
        required: "required"
      },
      outputs: {
        checkedChange: "checkedChange",
        toggleChange: "toggleChange"
      },
      viewQueries: [{
        propertyName: "matSlideToggle",
        first: true,
        predicate: MatSlideToggle,
        descendants: true
      }],
      usesInheritance: true,
      ngImport: i0,
      template: "<mat-slide-toggle\n    class=\"teg-slide-toggle\"\n    [aria-label]=\"ariaLabel\"\n    [aria-labelledby]=\"ariaLabelledby\"\n    [checked]=\"checked\"\n    [disabled]=\"disabled\"\n    [labelPosition]=\"labelPosition\"\n    [name]=\"name\"\n    [required]=\"required\"\n    (change)=\"onChange($event)\"\n    (toggleChange)=\"onToggleChange()\"\n>\n    <ng-content></ng-content>\n</mat-slide-toggle>\n",
      styles: [":host{--justify-content: start}.teg-slide-toggle ::ng-deep .mdc-label{font-size:var(--theme-base-font-size);letter-spacing:normal;white-space:nowrap}.teg-slide-toggle.mat-mdc-slide-toggle{box-sizing:border-box;width:100%;padding-right:20px}.teg-slide-toggle.mat-mdc-slide-toggle ::ng-deep .mdc-form-field{width:100%;justify-content:var(--justify-content)}.teg-slide-toggle.mat-mdc-slide-toggle ::ng-deep .mdc-form-field .mdc-label{margin-left:unset;margin-right:unset}\n"],
      dependencies: [{
        kind: "component",
        type: i1.MatSlideToggle,
        selector: "mat-slide-toggle",
        inputs: ["name", "id", "labelPosition", "aria-label", "aria-labelledby", "aria-describedby", "required", "color", "disabled", "disableRipple", "tabIndex", "checked", "hideIcon", "disabledInteractive"],
        outputs: ["change", "toggleChange"],
        exportAs: ["matSlideToggle"]
      }]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: SlideToggleComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'teg-slide-toggle',
      template: "<mat-slide-toggle\n    class=\"teg-slide-toggle\"\n    [aria-label]=\"ariaLabel\"\n    [aria-labelledby]=\"ariaLabelledby\"\n    [checked]=\"checked\"\n    [disabled]=\"disabled\"\n    [labelPosition]=\"labelPosition\"\n    [name]=\"name\"\n    [required]=\"required\"\n    (change)=\"onChange($event)\"\n    (toggleChange)=\"onToggleChange()\"\n>\n    <ng-content></ng-content>\n</mat-slide-toggle>\n",
      styles: [":host{--justify-content: start}.teg-slide-toggle ::ng-deep .mdc-label{font-size:var(--theme-base-font-size);letter-spacing:normal;white-space:nowrap}.teg-slide-toggle.mat-mdc-slide-toggle{box-sizing:border-box;width:100%;padding-right:20px}.teg-slide-toggle.mat-mdc-slide-toggle ::ng-deep .mdc-form-field{width:100%;justify-content:var(--justify-content)}.teg-slide-toggle.mat-mdc-slide-toggle ::ng-deep .mdc-form-field .mdc-label{margin-left:unset;margin-right:unset}\n"]
    }]
  }],
  ctorParameters: () => [{
    type: undefined,
    decorators: [{
      type: Attribute,
      args: ['tabindex']
    }]
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i2.NgControl,
    decorators: [{
      type: Optional
    }, {
      type: Self
    }, {
      type: Inject,
      args: [NgControl]
    }]
  }],
  propDecorators: {
    matSlideToggle: [{
      type: ViewChild,
      args: [MatSlideToggle]
    }],
    ariaLabel: [{
      type: Input,
      args: ['aria-label']
    }],
    ariaLabelledby: [{
      type: Input,
      args: ['aria-labelledby']
    }],
    ariaDescribedby: [{
      type: Input,
      args: ['aria-describedby']
    }],
    checked: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    labelPosition: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    required: [{
      type: Input
    }],
    checkedChange: [{
      type: Output
    }],
    toggleChange: [{
      type: Output
    }]
  }
});
class SlideToggleModule {
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: SlideToggleModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: SlideToggleModule,
      declarations: [SlideToggleComponent],
      imports: [CommonModule, MatSlideToggleModule],
      exports: [SlideToggleComponent]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: SlideToggleModule,
      imports: [CommonModule, MatSlideToggleModule]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: SlideToggleModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [CommonModule, MatSlideToggleModule],
      exports: [SlideToggleComponent],
      declarations: [SlideToggleComponent]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { SlideToggleComponent, SlideToggleModule };
