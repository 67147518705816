import { DataService } from "../../services/data.service";
import { Component, OnInit } from "@angular/core";
import { forkJoin, Subject } from "rxjs";
import * as _ from "lodash";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "app-license-admin",
  templateUrl: "./license-admin.component.html",
  styleUrls: ["./license-admin.component.css"]
})
export class LicenseAdminComponent implements OnInit {
  users: any[];
  applications: any[];
  licenses: any[];
  loading = true;
  usersWithLicenses = true;
  userInfo = {
    count: 0
  };
  patternMatch = "%@theevolvedgroup.com";
  modelChanged: Subject<string> = new Subject<string>();
  constructor(public dataService: DataService) {
    this.loadData();
    this.modelChanged
      .pipe(
        debounceTime(1000), // wait 300ms after the last event before emitting last event
        distinctUntilChanged()
      ) // only emit if value is different from previous value
      .subscribe(() => this.loadData());
  }
  loadData() {
    this.loading = true;
    forkJoin([
      this.dataService.getApplicationList(),
      this.dataService.getUserList(this.patternMatch),
      this.dataService.getApplicationLicenseUserList()
    ]).subscribe((data: any[]) => {
      this.applications = data[0];
      this.users = data[1];
      this.licenses = data[2];
      this.users.forEach(user => {
        user.licenses = {};
        this.applications.forEach(application => {
          user.licenses[application.applicationId] = { updating: false };
          user.licenses[application.applicationId].checked = _.some(
            this.licenses,
            license => {
              return (
                license.emailAddress.toLowerCase() ===
                  user.email.toLowerCase() &&
                license.applicationId === application.applicationId
              );
            }
          );
        });
      });
      this.loading = false;
    });
  }

  ngOnInit() {}
  onChange(event, user, application) {
    user.licenses[application.applicationId].updating = true;
    if (event.target.checked) {
      this.dataService.userLicenseAdd(user, application).subscribe(data => {
        user.licenses[application.applicationId].checked = true;
        user.licenses[application.applicationId].updating = false;
      });
    } else {
      this.dataService.userLicenseRemove(user, application).subscribe(data => {
        user.licenses[application.applicationId].checked = false;
        user.licenses[application.applicationId].updating = false;
      });
    }
  }
  changed(event) {
    this.modelChanged.next(event);
  }
}
