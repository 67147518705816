import { DataService } from '../../../services/data.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { AppStateService } from '../../../services/app-state.service';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import XLSX from 'xlsx';
import { ActivatedRoute, Router } from '@angular/router';
import { ACCOUNT_METRICS_CONFIG, APPLICATION_METRICS_CONFIG, USER_METRICS_CONFIG } from '../models/metrics-models';
import { Dictionary } from 'src/app/services/app-state.model';
import { DialogFactoryService } from '@theevolvedgroup/component-library/dialog-base';
import { DialogCloseResult } from '@theevolvedgroup/component-library/dialog-base/types';


@Component({
  selector: 'app-metrics-report',
  templateUrl: './metrics-report.component.html',
  styleUrls: ['./metrics-report.component.less']
})
export class MetricsReportComponent implements OnInit {
  reportName: string;
  reportTitle: string;
  reportSubtitle: string;
  reportDataSource: any[] = [];
  reportColumns: any[] = [];

  showInactiveFilter: boolean = false;
  showInactiveUsers: boolean = false;

  showEmailFilter: boolean = true;
  externalUsersOnly: boolean = true;

  headerHeight: number = 220;
  viewportHeight: number = window.innerHeight - this.headerHeight;

  private loadReportDataDebounced: () => void;

  constructor(
    public appState: AppStateService,
    public dataService: DataService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private dialogFactoryService: DialogFactoryService) {
    this.loadReportDataDebounced = _.debounce(this.loadReportData, 1000);

  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.viewportHeight = window.innerHeight - this.headerHeight;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.reportName = params['report'];

      let allReports = [...ACCOUNT_METRICS_CONFIG, ...USER_METRICS_CONFIG, ...APPLICATION_METRICS_CONFIG];
      let validReport = _.find(allReports, { action: this.reportName });

      if (!validReport) {
        this.router.navigate(['/metrics']);
        return;
      }

      this.reportTitle = validReport.title;
      this.reportSubtitle = validReport.subtitle;

      if (!validReport.columns || validReport.columns.length < 1) {
        let headerText = "Report Unavailable";
        let message = "This report is unavailable. Only the report tiles with a green action bar are available at the moment.";
        let buttons = [{ id: "ok", colorType: "error", caption: "OK" }];
        let dialog = this.dialogFactoryService.openMessageDialog({ headerText, contentComponentProperties: { message }, buttons, headerColor: "error" });

        dialog.closed$.subscribe((result: DialogCloseResult) => {
          this.router.navigate(['/metrics']);
        });

        return;
      }

      this.loadReportData();

    });
  }

  loadReportData() {
    let allReports = [...ACCOUNT_METRICS_CONFIG, ...USER_METRICS_CONFIG, ...APPLICATION_METRICS_CONFIG];
    let currentReport = allReports.find(x => x.action == this.reportName);

    this.showEmailFilter = currentReport.showEmailFilter;
    this.showInactiveFilter = currentReport.showInactiveFilter;

    let reportParameters: Dictionary = {
      "clientAccountGuid": this.appState.currentAccount?.clientAccountGuid
    };

    if (this.showInactiveFilter) {
      reportParameters["showInactiveUsers"] = this.showInactiveUsers;
    }

    if (this.showEmailFilter) {
      reportParameters["externalUsersOnly"] = this.externalUsersOnly;
    }

    this.appState.dataLoaded = false;
    this.dataService.getMetrics(this.reportName, reportParameters).subscribe((response: any) => {
      this.reportColumns = [...currentReport.columns];
      this.reportDataSource = [...response];
      this.appState.dataLoaded = true;
    });
  }

  onInactiveUsersFilerChanged(value) {
    this.showInactiveUsers = value;
    this.loadReportDataDebounced();
  }

  onEmailFilterChanged(value) {
    this.externalUsersOnly = value;
    this.loadReportDataDebounced();
  }

  onDownloadClicked() {
    if (!this.reportDataSource || this.reportDataSource.length < 1 || !this.reportColumns || this.reportColumns.length < 1)
      return;

    var wb = XLSX.utils.book_new();
    let worksheetName = _.truncate(this.reportTitle.replace(/[\\\/:*?[\]]/g, ''));

    let worksheet = {
      name: worksheetName,
      label: worksheetName,
      header: [],
      records: [],
    };

    _.forEach(this.reportColumns, (c) => {
      worksheet.header.push(c.headerText);
    });

    _.forEach(this.reportDataSource, (r) => {
      let record = [];
      _.forEach(this.reportColumns, (c) => {
        record.push(r[c.field]);
      });
      worksheet.records.push(record);
    });

    var ws = XLSX.utils.aoa_to_sheet(_.concat([worksheet.header], worksheet.records));
    XLSX.utils.book_append_sheet(wb, ws, worksheet.label);

    var filename = `${this.reportTitle} Report [PROTECTED].xlsx`;
    XLSX.writeFile(wb, filename);
  }
}
