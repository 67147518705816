import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from 'rxjs';
import { AppStateService } from "./services/app-state.service";
import { DataService } from "./services/data.service";
import _ from "lodash";
import { ACCOUNT_METRICS_CONFIG, APPLICATION_METRICS_CONFIG, USER_METRICS_CONFIG } from "./components/metrics/models/metrics-models";

@Injectable()
export class MetricsResolver {
    constructor(
        private router: Router,
        public appState: AppStateService,
        public dataService: DataService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> {
        return new Observable((observer) => {
            var report = decodeURI(route.params.report);

            if (report == "undefined") {
                observer.next();
                observer.complete();
                return;
            }

            let allReports = [...ACCOUNT_METRICS_CONFIG, ...USER_METRICS_CONFIG, ...APPLICATION_METRICS_CONFIG];
            let validReport = _.find(allReports, { action: report });

            if (!validReport) {
                observer.next();
                observer.complete();
                return;
            }

            if (report.indexOf('metrics_account') > -1 && (this.appState.clientAccounts == null || this.appState.clientAccounts.length == 0)) {
                this.appState.currentAccount = null;
                this.appState.currentSubAccount = null;

                this.appState.dataLoaded = false;
                this.dataService.getClientAccounts().subscribe((response: any) => {
                    this.appState.clientAccounts = _.take(response.data.clientAccounts, 300);
                    this.appState.dataLoaded = true;

                    this.appState.breadcrumbs = [{ label: 'Home', url: '/' }, { label: 'Metrics', url: 'metrics' }, { label: validReport.title, url: `metrics/${report}` }];

                    observer.next();
                    observer.complete();
                });
            } else {
                this.appState.breadcrumbs = [{ label: 'Home', url: '/' }, { label: 'Metrics', url: 'metrics' }, { label: validReport.title, url: `metrics/${report}` }];

                observer.next();
                observer.complete();
            }
        });
    }
}
