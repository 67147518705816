
<div id="dialogContainer" *ngIf="appState && appState.forbiddenMessage">
    <div id="forbiddenDialog">
        <div class="header">Oops...</div>
        <div class="content" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
            <div class="message" [innerHtml]="appState.forbiddenMessage"></div>
            <div class="content-image" *ngIf="appState.forbiddenImageUrl"><img [src]="appState.forbiddenImageUrl" style="height: 270px" /></div>
            <button mat-button type="button" color="primary" (click)="goHome()">HOME</button>
        </div>
        <div class="footer">
        </div>
    </div>
</div>

