import { DataService } from '../../../services/data.service';
import { Component } from '@angular/core';
import { AppStateService } from '../../../services/app-state.service';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { SubAccountDialogComponent } from '../../dialogs/sub-account-dialog/sub-account-dialog.component';
import { AccountDialogComponent } from '../../dialogs/account-dialog/account-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ACCOUNT_METRICS_CONFIG, APPLICATION_METRICS_CONFIG, USER_METRICS_CONFIG } from '../models/metrics-models';


@Component({
  selector: 'app-metrics-overview',
  templateUrl: './metrics-overview.component.html',
  styleUrls: ['./metrics-overview.component.less']
})
export class MetricsOverviewComponent {

  accountMetricsTiles: any = [];
  userMetricsTiles: any = [];
  applicationMetricsTiles: any = [];

  showAccountMetrics: boolean = true;
  showUserMetrics: boolean = true;
  showApplicationMetrics: boolean = true;

  private viewReportTooltip: string = "View Report";
  private viewReportIcon: string = "arrow_forward";
  private iconColor: string = "var(--theme-color-grey-0)";



  constructor(
    public appState: AppStateService,
    public dataService: DataService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {

    activatedRoute.params.subscribe(params => {
      this.setupComponent(params['mode']);
    })

    this.loadReportTiles();
  }

  setupComponent(mode: string) {
    switch (mode) {
      case "account":
        this.showAccountMetrics = true;
        this.showUserMetrics = false;
        this.showApplicationMetrics = false;
        break;
      case "user":
        this.showAccountMetrics = false;
        this.showUserMetrics = true;
        this.showApplicationMetrics = false;
        break;
      case "application":
        this.showAccountMetrics = false;
        this.showUserMetrics = false;
        this.showApplicationMetrics = true;
        break;
      default:
        this.showAccountMetrics = true;
        this.showUserMetrics = true;
        this.showApplicationMetrics = true;
        break;
    }

    this.appState.breadcrumbs = [{ label: 'Home', url: '/' }, { label: 'Metrics', url: 'metrics' }];
  }

  getTileTemplate() {
    return {
      avatarUrl: "",
      title: "",
      subtitle: "",
      bannerColor: "default",
      data: {},
      actions: [
        {
          icon: this.viewReportIcon,
          tooltip: this.viewReportTooltip,
          color: this.iconColor,
          action: {
            name: "",
            data: { selected: false }
          },
        },
      ],
    };
  }

  loadReportTiles() {
    _.forEach(ACCOUNT_METRICS_CONFIG, (info: any) => {
      let tile = this.getTileTemplate();
      tile.title = info.title;
      tile.subtitle = info.subtitle;
      tile.bannerColor = info.bannerColor;
      tile.data = {
        reportName: info.action
      },
        this.accountMetricsTiles.push(tile);
    });

    _.forEach(USER_METRICS_CONFIG, (info: any) => {
      let tile = this.getTileTemplate();
      tile.title = info.title;
      tile.subtitle = info.subtitle;
      tile.bannerColor = info.bannerColor;
      tile.data = {
        reportName: info.action
      },
        this.userMetricsTiles.push(tile);
    });

    _.forEach(APPLICATION_METRICS_CONFIG, (info: any) => {
      let tile = this.getTileTemplate();
      tile.title = info.title;
      tile.subtitle = info.subtitle;
      tile.bannerColor = info.bannerColor;
      tile.data = {
        reportName: info.action
      },
        this.applicationMetricsTiles.push(tile);
    });

    this.accountMetricsTiles = [...this.accountMetricsTiles];
    this.userMetricsTiles = [...this.userMetricsTiles];
    this.applicationMetricsTiles = [...this.applicationMetricsTiles];
  }

  onTileClicked(event: any) {
    this.router.navigateByUrl(`/metrics/report/${event.item.data.reportName}`);
  }
}
