import * as i0 from '@angular/core';
import { EventEmitter, Component, Input, Output, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
import * as i3 from '@angular/material/menu';
import { MatMenuModule } from '@angular/material/menu';
import * as i4 from '@theevolvedgroup/component-library/icon-button';
import { IconButtonModule } from '@theevolvedgroup/component-library/icon-button';
import { PortalModule } from '@angular/cdk/portal';
import { MatIconModule } from '@angular/material/icon';
import { ActionBarModule } from '@theevolvedgroup/component-library/action-bar';
class NavBarComponent {
  constructor() {
    this.menuItems = [];
    this.selected = new EventEmitter();
  }
  ngOnInit() {}
  onMenuItemClicked(menuItem) {
    this.menuItems.forEach(item => {
      item.active = false;
    });
    menuItem.active = true;
    this.selected.emit(menuItem);
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: NavBarComponent,
      deps: [],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: NavBarComponent,
      selector: "teg-nav-bar",
      inputs: {
        menuItems: "menuItems"
      },
      outputs: {
        selected: "selected"
      },
      ngImport: i0,
      template: "<div class=\"nav-bar\">\n    <div class=\"menu-icon-container\">\n        <teg-icon-button [outline]=\"false\" [color]=\"'var(--theme-color-grey-0)'\" [matMenuTriggerFor]=\"navBarMenu\">\n            menu\n        </teg-icon-button>\n    </div>\n    <div class=\"menu-items-container\">\n        <ng-container *ngFor=\"let menuItem of menuItems\">\n            <button mat-flat-button class=\"menu-item\" [ngClass]=\"{ 'selected': menuItem.active }\" (click)=\"onMenuItemClicked(menuItem)\">{{menuItem.label}}</button>\n        </ng-container>\n    </div>\n\n    <mat-menu #navBarMenu=\"matMenu\">\n        <button mat-menu-item  *ngFor=\"let menuItem of menuItems\" [ngClass]=\"{ 'selected': menuItem.active }\" (click)=\"onMenuItemClicked(menuItem)\">\n          <span>{{menuItem.label}}</span>\n        </button>\n      </mat-menu>\n</div>",
      styles: [".nav-bar{height:48px;background-color:var(--theme-nav-banner-color)}@media screen and (max-width: 960px){.nav-bar .menu-items-container{display:none}.nav-bar .menu-icon-container{display:flex;align-items:center;margin-left:2rem;height:100%}}@media screen and (min-width: 959px){.nav-bar .menu-items-container{display:flex}.nav-bar .menu-icon-container{display:none}}.nav-bar .menu-items-container{flex-direction:row;margin-left:.5rem;gap:.5rem;align-items:center;height:100%}.nav-bar .menu-items-container .menu-item{height:100%;display:flex;align-items:center;color:var(--theme-nav-text-color);font-size:var(--theme-base-font-size);background-color:var(--theme-nav-banner-color);justify-content:center;cursor:pointer}.nav-bar .menu-items-container .menu-item.selected{border-bottom:2px solid #FFF;border-radius:0!important}.nav-bar .menu-extras-container .menu-extra{display:flex}\n"],
      dependencies: [{
        kind: "directive",
        type: i1.NgClass,
        selector: "[ngClass]",
        inputs: ["class", "ngClass"]
      }, {
        kind: "directive",
        type: i1.NgForOf,
        selector: "[ngFor][ngForOf]",
        inputs: ["ngForOf", "ngForTrackBy", "ngForTemplate"]
      }, {
        kind: "component",
        type: i2.MatButton,
        selector: "    button[mat-button], button[mat-raised-button], button[mat-flat-button],    button[mat-stroked-button]  ",
        exportAs: ["matButton"]
      }, {
        kind: "component",
        type: i3.MatMenu,
        selector: "mat-menu",
        inputs: ["backdropClass", "aria-label", "aria-labelledby", "aria-describedby", "xPosition", "yPosition", "overlapTrigger", "hasBackdrop", "class", "classList"],
        outputs: ["closed", "close"],
        exportAs: ["matMenu"]
      }, {
        kind: "component",
        type: i3.MatMenuItem,
        selector: "[mat-menu-item]",
        inputs: ["role", "disabled", "disableRipple"],
        exportAs: ["matMenuItem"]
      }, {
        kind: "directive",
        type: i3.MatMenuTrigger,
        selector: "[mat-menu-trigger-for], [matMenuTriggerFor]",
        inputs: ["mat-menu-trigger-for", "matMenuTriggerFor", "matMenuTriggerData", "matMenuTriggerRestoreFocus"],
        outputs: ["menuOpened", "onMenuOpen", "menuClosed", "onMenuClose"],
        exportAs: ["matMenuTrigger"]
      }, {
        kind: "component",
        type: i4.IconButtonComponent,
        selector: "teg-icon-button",
        inputs: ["customClass", "icon", "outline", "color", "size", "variant", "library", "opticalSize", "iconWeight", "action", "tooltip", "tooltipPosition", "disabled", "loading", "iconSrc", "svgIcon"],
        outputs: ["iconClicked"]
      }]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: NavBarComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'teg-nav-bar',
      template: "<div class=\"nav-bar\">\n    <div class=\"menu-icon-container\">\n        <teg-icon-button [outline]=\"false\" [color]=\"'var(--theme-color-grey-0)'\" [matMenuTriggerFor]=\"navBarMenu\">\n            menu\n        </teg-icon-button>\n    </div>\n    <div class=\"menu-items-container\">\n        <ng-container *ngFor=\"let menuItem of menuItems\">\n            <button mat-flat-button class=\"menu-item\" [ngClass]=\"{ 'selected': menuItem.active }\" (click)=\"onMenuItemClicked(menuItem)\">{{menuItem.label}}</button>\n        </ng-container>\n    </div>\n\n    <mat-menu #navBarMenu=\"matMenu\">\n        <button mat-menu-item  *ngFor=\"let menuItem of menuItems\" [ngClass]=\"{ 'selected': menuItem.active }\" (click)=\"onMenuItemClicked(menuItem)\">\n          <span>{{menuItem.label}}</span>\n        </button>\n      </mat-menu>\n</div>",
      styles: [".nav-bar{height:48px;background-color:var(--theme-nav-banner-color)}@media screen and (max-width: 960px){.nav-bar .menu-items-container{display:none}.nav-bar .menu-icon-container{display:flex;align-items:center;margin-left:2rem;height:100%}}@media screen and (min-width: 959px){.nav-bar .menu-items-container{display:flex}.nav-bar .menu-icon-container{display:none}}.nav-bar .menu-items-container{flex-direction:row;margin-left:.5rem;gap:.5rem;align-items:center;height:100%}.nav-bar .menu-items-container .menu-item{height:100%;display:flex;align-items:center;color:var(--theme-nav-text-color);font-size:var(--theme-base-font-size);background-color:var(--theme-nav-banner-color);justify-content:center;cursor:pointer}.nav-bar .menu-items-container .menu-item.selected{border-bottom:2px solid #FFF;border-radius:0!important}.nav-bar .menu-extras-container .menu-extra{display:flex}\n"]
    }]
  }],
  ctorParameters: () => [],
  propDecorators: {
    menuItems: [{
      type: Input
    }],
    selected: [{
      type: Output
    }]
  }
});
class NavBarModule {
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: NavBarModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: NavBarModule,
      declarations: [NavBarComponent],
      imports: [CommonModule, ActionBarModule, MatButtonModule, MatIconModule, MatMenuModule, IconButtonModule, PortalModule],
      exports: [NavBarComponent]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: NavBarModule,
      imports: [CommonModule, ActionBarModule, MatButtonModule, MatIconModule, MatMenuModule, IconButtonModule, PortalModule]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: NavBarModule,
  decorators: [{
    type: NgModule,
    args: [{
      declarations: [NavBarComponent],
      exports: [NavBarComponent],
      imports: [CommonModule, ActionBarModule, MatButtonModule, MatIconModule, MatMenuModule, IconButtonModule, PortalModule]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { NavBarComponent, NavBarModule };
