import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userWithLicense'
})
export class UserWithLicensePipe implements PipeTransform {

  transform(users: any[], licensedUsers: boolean, applications: any[], userInfo: any): any {
    if (!licensedUsers) {
      userInfo.count = users.length;
      return users;
    }
    const filteredUsers = users.filter(user => {
      let include = false;
      applications.forEach(application => {
        if (user.licenses[application.applicationId].checked) {
          include = true;
        }
      });
      return include;
    });
    userInfo.count = filteredUsers.length;
    return filteredUsers;
  }

}
