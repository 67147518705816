import { HttpHeaders } from "@angular/common/http";
import {
    Component, Inject, NgZone, OnInit, Input, ViewChild
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Cloudinary } from "@theevolvedgroup/component-library/cloudinary";
import { OidcSecurityService } from "angular-auth-oidc-client";
import * as _ from "lodash";
import {
    FileUploader,
    FileUploaderOptions,
    ParsedResponseHeaders
} from "ng2-file-upload";
import { DataService } from '../../../services/data.service';
import { AppStateService } from '../../../services/app-state.service';
import { UntypedFormControl } from "@angular/forms";


class ClientAccount {
    clientAccountGuid?: string;
    clientAccountName: string;
    clientAccountVanityName: string;
    clientAccountIconUrl: string;
    config: any;
    configJson: string; // NOTE: The C# is stupid and relies on the json to deserialize rather than using the dynamic
    clientAccountLicense: any;
}

//const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
class ClientSubAccount {
    clientAccountGuid: string;
    clientSubAccountGuid?: string;
    clientSubAccountName: string;
    clientSubAccountIconUrl: string;
    config: any;
    configJson: string;
}

@Component({
    selector: "sub-account-dialog",
    templateUrl: "./sub-account-dialog.component.html",
    styleUrls: ["./sub-account-dialog.component.css"],
})
export class SubAccountDialogComponent implements OnInit {

    licenseCount: string = '9999';
    validForYears: string = '99';
    isAccount: boolean = false;
    account: ClientAccount = new ClientAccount();
    response: any;
    uploader: FileUploader;
    accountNameDebounce: any;
    clientAccountNameDuplicated = false;
    @ViewChild("clientAccountName", { static: true })
    clientAccountName: UntypedFormControl;
    subAccount: ClientSubAccount = new ClientSubAccount();
    clientSubAccountName: any = [];
    clientSubAccountIconUrl: any;
    saving = false;
    dataDomiciledLocations: any = [];
    selectedLocation = 'au';
    loading = false;

    constructor(
        private dataService: DataService,
        private appState: AppStateService,
        private oidcSecurityService: OidcSecurityService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<SubAccountDialogComponent>,
        private cloudinary: Cloudinary,
        private zone: NgZone
    ) { }

    ngOnInit() {
        this.isAccount = this.data.isAccount;

        this.loading = true;
        this.getDataDomLocation().subscribe((locations) => {
            this.dataDomiciledLocations = locations;
            this.selectedLocation = locations[0].locationCode;

            if (this.isAccount) {
                if (this.data.mode == "Add") {
                    this.initUploader();
                    this.account = {
                        clientAccountName: "",
                        clientAccountVanityName: "",
                        clientAccountIconUrl:
                            "https://res.cloudinary.com/twistoflime/Portal/Site/HumanListening_Icon.svg",
                        config: {
                            hideTitle: '',
                        },
                        configJson: '',
                        clientAccountLicense: {}
                    };
                    this.subAccount = {
                        clientAccountGuid: '',
                        clientSubAccountName: 'Default',
                        clientSubAccountIconUrl:
                            "https://res.cloudinary.com/twistoflime/Portal/Site/HumanListening_Icon.svg",
                        config: {
                            isPeopleListening: false,
                            hideTitle: false,
                            useNewShaper: true,
                            location: '',
                            surveyBaseUrl: '',
                            enableSMS: false,
                            helpUrl: '',
                            enableEmail: true,
                            useNewControlPanel: true,
                            enableTranslations: false,
                            enableCaseManagerPIINotifications: false,
                            useLearningLoops: true,
                            defaultDatastoreRuleJSON: {
                                FinishMarkerIds: [0, 1, 2, 3, 4, 5], // Note - uppercase F
                                includeTestEntries: true,
                            },
                        },
                        configJson: ''
                    };
                }

                this.accountNameDebounce = _.debounce(
                    (accountName) => {
                        this.checkAccountName(accountName);
                    },
                    500,
                    {}
                );

                this.loading = false;
            }
            else {
                if (this.data.mode == "Add") {
                    this.initUploader(this.data.clientAccount.clientAccountGuid);
                    this.subAccount = {
                        clientAccountGuid: this.data.clientAccount.clientAccountGuid,
                        clientSubAccountName: '',
                        clientSubAccountIconUrl:
                            "https://res.cloudinary.com/twistoflime/Portal/Site/HumanListening_Icon.svg",
                        config: {
                            isPeopleListening: false,
                            hideTitle: false,
                            useNewShaper: true,
                            location: '',
                            surveyBaseUrl: '',
                            enableSMS: false,
                            helpUrl: '',
                            enableEmail: true,
                            useNewControlPanel: true,
                            enableTranslations: false,
                            useLearningLoops: true,
                            enableFileBankUpload: false,
                            enableCaseManagerPIINotifications: false,
                            defaultDatastoreRuleJSON: {
                                FinishMarkerIds: [0, 1, 2, 3, 4, 5], // Note - uppercase F
                                includeTestEntries: true,
                            },
                        },
                        configJson: ''
                    };

                    this.loading = false;

                } else if (this.data.mode == "Edit") {
                    this.initUploader(this.data.clientSubAccount.clientSubAccountGuid);
                    this.loading = true;
                    this.dataService.getGetSubAccountByGuid(this.data.clientSubAccount.clientSubAccountGuid).subscribe(
                        (subAcc) => {

                            this.subAccount = {
                                clientAccountGuid: subAcc.clientAccountGuid,
                                clientSubAccountGuid: this.data.clientSubAccount.clientSubAccountGuid,
                                clientSubAccountName: subAcc.clientSubAccountName,
                                clientSubAccountIconUrl: subAcc.clientSubAccountIconUrl,
                                config: subAcc.config,
                                configJson: ''
                            }

                            this.selectedLocation = subAcc.config.location;
                        },
                        (error) => {
                        },
                        () => {
                            this.loading = false;
                        }
                    );
                }
            }
        }, err => {

        }, () => {

        });
    }

    private getDataDomLocation() {
        return this.dataService.getDataDomiciledLocation();
    }

    private checkAccountName(accountName: string) {
        // TODO REPLACE CHARS
        // this.accountVanityName = accountName;
        if (accountName === "" || this.clientAccountName.errors) {
            return;
        }

        // this.appState.checkAccountNameExist(accountName).subscribe((exists: boolean) => {
        //     this.clientAccountNameDuplicated = exists;
        //     // this.checkAccountVanityName(this.accountVanityName);
        // });
    }

    save() {
        this.saving = true;
        if (this.isAccount) {
            if (this.data.mode == "Add") {
                this.account.configJson = JSON.stringify(this.account.config); // NOTE: The C# is stupid and relies on the json to deserialize rather than using the dynamic
                this.subAccount.configJson = JSON.stringify(this.subAccount.config); // NOTE: The C# is stupid and relies on the json to deserialize rather than using the dynamic
                this.subAccount.clientAccountGuid = "00000000-0000-0000-0000-000000000000";
                this.account.clientAccountLicense = {
                    clientAccountLicenseGuid: "00000000-0000-0000-0000-000000000000",
                    clientAccountGuid: "00000000-0000-0000-0000-000000000000",
                    productSuiteGuid: "93719E66-DDB1-49FB-9D4E-646D23B0F4E1",
                    licenseType: 2,
                    licenseCount: parseInt(this.licenseCount),
                    validForYears: parseInt(this.validForYears)
                };
                let req = {
                    clientAccount: this.account,
                    clientSubAccount: this.subAccount,
                }
                this.dataService.addAccount(req).subscribe(
                    (data) => {
                        this.dialogRef.close(data);
                    },
                    (error) => {
                        this.saving = false;
                        if (error.status === 400 && error.message) {
                            console.log(error);
                            alert("Error - " + error.message);
                            return;
                        }
                        if (error.status === 500) {
                            console.log(error);
                            alert(
                                "Error - An unexpected error occurred. Please contact support. " + error.error.messgae
                            );
                            return;
                        }
                        // this.oidcSecurityService.handleError(error);
                    },
                    () => {
                        //console.log('Add Account completed')
                    }
                );
            }
        }
        else {
            if (this.data.mode == "Add") {
                this.subAccount.configJson = JSON.stringify(this.subAccount.config); // NOTE: The C# is stupid and relies on the json to deserialize rather than using the dynamic
                this.dataService.addSecurityGroup(this.subAccount).subscribe(
                    (data) => {
                        this.dialogRef.close(data);
                    },
                    (error) => {
                        this.saving = false;
                        if (error.status === 400 && error.message) {
                            console.log(error);
                            alert("Error - " + error.message);
                            return;
                        }
                        if (error.status === 500) {
                            console.log(error);
                            alert(
                                "Error - An unexpected error occurred. Please contact support."
                            );
                            return;
                        }
                        // this.oidcSecurityService.handleError(error);
                    },
                    () => {
                        console.log("Add Sub Account completed");
                    }
                );
            }
            else {
                this.subAccount.configJson = JSON.stringify(this.subAccount.config); // NOTE: The C# is stupid and relies on the json to deserialize rather than using the dynamic
                this.dataService
                    .updateSecurityGroup(this.subAccount)
                    .subscribe(
                        (data) => {
                            this.dialogRef.close(data);
                        },
                        (error) => {
                            this.saving = false;
                            if (error.status === 400 && error.message) {
                                console.log(error);
                                alert("Error - " + error.message);
                                return;
                            }
                            if (error.status === 500) {
                                console.log(error);
                                alert("Error - An unexpected error occurred. Please contact support.");
                                return;
                            }
                            // this.oidcSecurityService.handleError(error);
                        },
                        () => {
                            console.log("Update Sub Account completed");
                        }
                    );
            }
        }

    }

    onLocationChange(event: any) {
        this.selectedLocation = event.itemData.locationCode;
        this.subAccount.config.location = this.selectedLocation;
    }

    cancel() {
        this.dialogRef.close();
    }

    // Delete an uploaded image
    // Requires setting "Return delete token" to "Yes" in your upload preset configuration
    // See also https://support.cloudinary.com/hc/en-us/articles/202521132-How-to-delete-an-image-from-the-client-side-
    deleteImage = function (data: any, index: number) {
        console.log(`Deleting image - ${data.public_id}`);
        // We have 10 minutes to delete the image
        const url = `https://api.cloudinary.com/v1_1/${this.cloudinary.config().cloud_name
            }/delete_by_token`;
        let headers = new HttpHeaders({
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
        });
        const body = {
            token: data.delete_token,
        };
        this.http
            .post(url, body, { headers: headers })
            .toPromise()
            .then((response) => {
                console.log(
                    `Deleted image - ${data.public_id} ${response.json().result
                    }`
                );
                // Remove deleted item for responses
                this.response = null;
                //this.responses.splice(index, 1);
            })
            .catch((err: any) => {
                console.log(`Failed to delete image ${data.public_id} ${err}`);
            });
    };

    private initUploader(accSubAccGuid: string = '') {
        // Create the file uploader, wire it to upload to your account
        const uploaderOptions: FileUploaderOptions = {
            url: `https://api.cloudinary.com/v1_1/${this.cloudinary.config().cloud_name
                }/upload`,
            // Upload files automatically upon addition to upload queue
            autoUpload: true,
            // Use xhrTransport in favor of iframeTransport
            isHTML5: true,
            // Calculate progress independently for each uploaded file
            removeAfterUpload: true,
            // XHR request headers
            headers: [
                {
                    name: "X-Requested-With",
                    value: "XMLHttpRequest",
                },
            ],
        };
        this.uploader = new FileUploader(uploaderOptions);

        this.uploader.onBuildItemForm = (
            fileItem: any,
            form: FormData
        ): any => {
            // Add Cloudinary's unsigned upload preset to the upload form
            form.append(
                "upload_preset",
                this.cloudinary.config().upload_preset
            );
            // Add built-in and custom tags for displaying the uploaded photo in the list
            form.append(
                "folder",
                "/Portal/Clients/" + accSubAccGuid
            );
            form.append("public_id", fileItem.file.name);
            form.append("file", fileItem);

            // Use default "withCredentials" value for CORS requests
            fileItem.withCredentials = false;
            return { fileItem, form };
        };

        // Insert or update an entry in the responses array
        const upsertresponse = (fileItem) => {
            // Run the update in a custom zone since for some reason change detection isn't performed
            // as part of the XHR request to upload the files.
            // Running in a custom zone forces change detection
            this.zone.run(() => {
                // Update an existing entry if it's upload hasn't completed yet

                //// Find the id of an existing item
                //const existingId = this.responses.reduce((prev, current, index) => {
                //    if (current.file.name === fileItem.file.name && !current.status) {
                //        return index;
                //    }
                //    return prev;
                //}, -1);
                //if (existingId > -1) {
                //    // Update existing item with new data
                //    this.responses[existingId] = Object.assign(this.responses[existingId], fileItem);
                //} else {
                //    // Create new response
                //    this.responses.push(fileItem);
                //}

                // We only allow single file uploads
                this.response = fileItem;
                if (this.response.data.secure_url != null) {
                    let imageUrl = this.response.data.secure_url.replace(
                        "https:",
                        ""
                    );
                    if (this.isAccount) {
                        this.account.clientAccountIconUrl = imageUrl;
                    }
                    else {
                        this.subAccount.clientSubAccountIconUrl = imageUrl;
                    }
                }
            });
        };

        // Update model on completion of uploading a file
        this.uploader.onCompleteItem = (
            item: any,
            response: string,
            status: number,
            headers: ParsedResponseHeaders
        ) =>
            upsertresponse({
                file: item.file,
                status,
                data: JSON.parse(response),
            });

        // Update model on upload progress event
        this.uploader.onProgressItem = (fileItem: any, progress: any) =>
            upsertresponse({
                file: fileItem.file,
                progress,
                data: {},
            });
    }

    onAccountNameChanged(event: any) {
        //console.log(event.target.value)
        // this.clientAccountNameDuplicated = false;
        // this.accountNameDebounce(event.target.value);
        this.account.clientAccountVanityName = event.target.value
            .replace(/[ ,.]/g, "-")
            .replace(/\-+/g, "-");
        if (this.account.clientAccountVanityName.endsWith("-")) {
            this.account.clientAccountVanityName = this.account.clientAccountVanityName.slice(
                0,
                -1
            );
        }
    }
}
