import * as i0 from '@angular/core';
import { EventEmitter, Component, Input, Output, ViewChild, NgModule } from '@angular/core';
import * as i2 from '@angular/forms';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import * as i5 from '@angular/material/core';
import { MatRipple, MatRippleModule } from '@angular/material/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as i1 from '@theevolvedgroup/component-library/icon-button';
import { IconButtonModule } from '@theevolvedgroup/component-library/icon-button';
import * as i3 from '@angular/material/input';
import { MatInputModule } from '@angular/material/input';
import * as i4 from '@angular/material/form-field';
class SearchFilterComponent {
  constructor(cdr) {
    this.cdr = cdr;
    /** The debounce time for input value changes. */
    this.debounceTime = 0;
    /** Event emitted container the user's search filter input */
    this.filterTermChange = new EventEmitter();
    /** Event emitted when clear icon button is clicked */
    this.clearSearchTerm = new EventEmitter();
    this.searchControl = new FormControl("", {});
  }
  ngOnChanges(changes) {
    if (changes["focusSearch"]) {
      this.search();
    }
    if (changes["resetSearch"]) {
      this.clearSearch();
    }
  }
  ngOnInit() {}
  set content(element) {
    if (element != null) {
      this.searchInput = element;
      this.searchControl.valueChanges.pipe(debounceTime(this.debounceTime), distinctUntilChanged()).subscribe(input => {
        // on iOS this is sometimes null for the first keypress
        if (input == null) {
          input = "";
        }
        this.filterTermChange.emit(input);
      });
    }
  }
  search() {
    /* Fix issue of failing to focus when switching from tab without filter visible */
    setTimeout(() => {
      this.searchInput.focus();
      this.launchRipple();
    }, 0);
  }
  closeSearch() {
    this.searchControl.setValue("");
  }
  clearSearch() {
    this.searchControl.setValue("");
    this.filterTermChange.emit("");
    this.clearSearchTerm.emit();
  }
  launchRipple() {
    const rippleRef = this.ripple.launch({
      persistent: true,
      centered: true,
      color: "var(--theme-account-switcher-underline-ripple-color)",
      radius: 312,
      animation: {
        enterDuration: 0,
        exitDuration: 1500
      }
    });
    // Fade out the ripple later.
    rippleRef.fadeOut();
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: SearchFilterComponent,
      deps: [{
        token: i0.ChangeDetectorRef
      }],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: SearchFilterComponent,
      selector: "teg-search-filter",
      inputs: {
        focusSearch: "focusSearch",
        resetSearch: "resetSearch",
        filterTerm: "filterTerm",
        debounceTime: "debounceTime"
      },
      outputs: {
        filterTermChange: "filterTermChange",
        clearSearchTerm: "clearSearchTerm"
      },
      viewQueries: [{
        propertyName: "ripple",
        first: true,
        predicate: MatRipple,
        descendants: true
      }, {
        propertyName: "content",
        first: true,
        predicate: ["searchInput"],
        descendants: true
      }],
      usesOnChanges: true,
      ngImport: i0,
      template: "<div class=\"search-filter-container\">\n    <div class=\"search-box\"\n         (click)=\"$event.stopPropagation()\">\n        <teg-icon-button matPrefix\n                         class=\"search-icon\"\n                         color=\"primary\"\n                         (click)=\"search();$event.stopPropagation()\"\n                         matTooltip=\"Filter Accounts\">\n            search\n        </teg-icon-button>\n        <input #searchInput=\"matInput\"\n               matInput\n               [formControl]=\"searchControl\"\n               type=\"text\"\n               placeholder=\"Search\"\n               #input>\n        <teg-icon-button matSuffix\n                         color=\"primary\"\n                         (click)=\"clearSearch()\"\n                         matTooltip=\"Close\"\n                         size=\"xs\">\n            close\n        </teg-icon-button>\n    </div>\n    <div class=\"underline\"\n         matRipple\n         [matRippleTrigger]=\"input\"\n         [matRippleCentered]=\"true\"\n         matRippleColor=\"var(--theme-account-switcher-underline-ripple-color)\"></div>\n</div>\n",
      styles: [".search-filter-container{display:flex;flex-direction:column;width:100%;justify-content:start;align-items:center;row-gap:var(--theme-spacing-sm)}.search-filter-container .search-box{display:flex;align-items:center;width:100%;column-gap:var(--theme-spacing-sm)}.search-filter-container .search-box .search-icon{display:flex;align-items:center}.search-filter-container .search-box input{font:inherit;font-size:1rem;background:transparent;color:var(--theme-account-switcher-text-color);caret-color:var(--theme-account-switcher-text-color);border:none;outline:none;padding:0;margin:0;width:100%;max-width:100%;vertical-align:bottom;text-align:inherit;box-sizing:content-box;font-weight:400}.search-filter-container .underline{position:relative;bottom:0;background-color:var(--theme-color-grey-50);height:1px;width:100%}.search-filter-container:focus-within .underline{background-color:var(--theme-account-switcher-underline-color)}\n"],
      dependencies: [{
        kind: "component",
        type: i1.IconButtonComponent,
        selector: "teg-icon-button",
        inputs: ["customClass", "icon", "outline", "color", "size", "variant", "library", "opticalSize", "iconWeight", "action", "tooltip", "tooltipPosition", "disabled", "loading", "iconSrc", "svgIcon"],
        outputs: ["iconClicked"]
      }, {
        kind: "directive",
        type: i2.DefaultValueAccessor,
        selector: "input:not([type=checkbox])[formControlName],textarea[formControlName],input:not([type=checkbox])[formControl],textarea[formControl],input:not([type=checkbox])[ngModel],textarea[ngModel],[ngDefaultControl]"
      }, {
        kind: "directive",
        type: i2.NgControlStatus,
        selector: "[formControlName],[ngModel],[formControl]"
      }, {
        kind: "directive",
        type: i2.FormControlDirective,
        selector: "[formControl]",
        inputs: ["formControl", "disabled", "ngModel"],
        outputs: ["ngModelChange"],
        exportAs: ["ngForm"]
      }, {
        kind: "directive",
        type: i3.MatInput,
        selector: "input[matInput], textarea[matInput], select[matNativeControl],      input[matNativeControl], textarea[matNativeControl]",
        inputs: ["disabled", "id", "placeholder", "name", "required", "type", "errorStateMatcher", "aria-describedby", "value", "readonly"],
        exportAs: ["matInput"]
      }, {
        kind: "directive",
        type: i4.MatPrefix,
        selector: "[matPrefix], [matIconPrefix], [matTextPrefix]",
        inputs: ["matTextPrefix"]
      }, {
        kind: "directive",
        type: i4.MatSuffix,
        selector: "[matSuffix], [matIconSuffix], [matTextSuffix]",
        inputs: ["matTextSuffix"]
      }, {
        kind: "directive",
        type: i5.MatRipple,
        selector: "[mat-ripple], [matRipple]",
        inputs: ["matRippleColor", "matRippleUnbounded", "matRippleCentered", "matRippleRadius", "matRippleAnimation", "matRippleDisabled", "matRippleTrigger"],
        exportAs: ["matRipple"]
      }]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: SearchFilterComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: "teg-search-filter",
      template: "<div class=\"search-filter-container\">\n    <div class=\"search-box\"\n         (click)=\"$event.stopPropagation()\">\n        <teg-icon-button matPrefix\n                         class=\"search-icon\"\n                         color=\"primary\"\n                         (click)=\"search();$event.stopPropagation()\"\n                         matTooltip=\"Filter Accounts\">\n            search\n        </teg-icon-button>\n        <input #searchInput=\"matInput\"\n               matInput\n               [formControl]=\"searchControl\"\n               type=\"text\"\n               placeholder=\"Search\"\n               #input>\n        <teg-icon-button matSuffix\n                         color=\"primary\"\n                         (click)=\"clearSearch()\"\n                         matTooltip=\"Close\"\n                         size=\"xs\">\n            close\n        </teg-icon-button>\n    </div>\n    <div class=\"underline\"\n         matRipple\n         [matRippleTrigger]=\"input\"\n         [matRippleCentered]=\"true\"\n         matRippleColor=\"var(--theme-account-switcher-underline-ripple-color)\"></div>\n</div>\n",
      styles: [".search-filter-container{display:flex;flex-direction:column;width:100%;justify-content:start;align-items:center;row-gap:var(--theme-spacing-sm)}.search-filter-container .search-box{display:flex;align-items:center;width:100%;column-gap:var(--theme-spacing-sm)}.search-filter-container .search-box .search-icon{display:flex;align-items:center}.search-filter-container .search-box input{font:inherit;font-size:1rem;background:transparent;color:var(--theme-account-switcher-text-color);caret-color:var(--theme-account-switcher-text-color);border:none;outline:none;padding:0;margin:0;width:100%;max-width:100%;vertical-align:bottom;text-align:inherit;box-sizing:content-box;font-weight:400}.search-filter-container .underline{position:relative;bottom:0;background-color:var(--theme-color-grey-50);height:1px;width:100%}.search-filter-container:focus-within .underline{background-color:var(--theme-account-switcher-underline-color)}\n"]
    }]
  }],
  ctorParameters: () => [{
    type: i0.ChangeDetectorRef
  }],
  propDecorators: {
    focusSearch: [{
      type: Input
    }],
    resetSearch: [{
      type: Input
    }],
    filterTerm: [{
      type: Input
    }],
    debounceTime: [{
      type: Input
    }],
    filterTermChange: [{
      type: Output
    }],
    clearSearchTerm: [{
      type: Output
    }],
    ripple: [{
      type: ViewChild,
      args: [MatRipple]
    }],
    content: [{
      type: ViewChild,
      args: ["searchInput"]
    }]
  }
});
class SearchFilterModule {
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: SearchFilterModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: SearchFilterModule,
      declarations: [SearchFilterComponent],
      imports: [IconButtonModule, ReactiveFormsModule, MatInputModule, MatRippleModule],
      exports: [SearchFilterComponent]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: SearchFilterModule,
      imports: [IconButtonModule, ReactiveFormsModule, MatInputModule, MatRippleModule]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: SearchFilterModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [IconButtonModule, ReactiveFormsModule, MatInputModule, MatRippleModule],
      exports: [SearchFilterComponent],
      declarations: [SearchFilterComponent],
      providers: []
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { SearchFilterComponent, SearchFilterModule };
