/**
 * Dialog Button Type for CustomDialogButton configuration.
 * - Default
 * - Next (Navigates to next step of stepper - only for Stepper Dialog's)
 * - Back (Navigates to previous step of stepper - only for Stepper Dialog's)
 */
var DialogButtonType;
(function (DialogButtonType) {
  DialogButtonType[DialogButtonType["Default"] = 0] = "Default";
  DialogButtonType[DialogButtonType["Next"] = 1] = "Next";
  DialogButtonType[DialogButtonType["Back"] = 2] = "Back";
})(DialogButtonType || (DialogButtonType = {}));
/** Dialog Base Config for dialogs
 * - (Optional) Provide type to specify type of dialog content properties/model
 */
class DialogBaseConfig {
  /**
   * Dialog Base Config constructor.
   * @param headerText The header text for the dialog. Defaults to "Message".
   * @param buttons The dialog buttons configuration. Default contains an "ok" and "cancel" button.
   * @param headerColor The color theme of the dialog header. Defaults to "accent-1".
   * @param contentComponentProperties The dialog content component properties object.
   */
  constructor(headerText = "Message", contentComponentProperties = null, buttons = [{
    id: "cancel",
    colorType: "secondary",
    caption: "cancel"
  }, {
    id: "ok",
    colorType: "primary",
    caption: "OK"
  }], headerColor = "accent-1", subheaderText = null) {
    this.headerText = headerText;
    this.headerColor = headerColor;
    this.buttons = buttons;
    this.contentComponentProperties = contentComponentProperties;
    this.subheaderText = subheaderText;
  }
}
var CommentStatisticType;
(function (CommentStatisticType) {
  CommentStatisticType[CommentStatisticType["NegativeSentiment"] = -1] = "NegativeSentiment";
  CommentStatisticType[CommentStatisticType["NeutralSentiment"] = 0] = "NeutralSentiment";
  CommentStatisticType[CommentStatisticType["PositiveSentiment"] = 1] = "PositiveSentiment";
  CommentStatisticType[CommentStatisticType["MixedSentiment"] = 2] = "MixedSentiment";
  CommentStatisticType[CommentStatisticType["Percentage"] = 3] = "Percentage";
})(CommentStatisticType || (CommentStatisticType = {}));
const DEFAULT_OK_BUTTON = {
  id: "ok",
  colorType: "primary",
  caption: "OK",
  canDisable: true
};
const DEFAULT_CANCEL_BUTTON = {
  id: "cancel",
  colorType: "secondary",
  caption: "Cancel",
  canDisable: false
};
const DEFAULT_BUTTONS = [DEFAULT_CANCEL_BUTTON, DEFAULT_OK_BUTTON];
const DEFAULT_STEPPER_BUTTONS = [{
  id: "close",
  colorType: "secondary",
  caption: "close",
  type: DialogButtonType.Default
}, /** Back and Next button configuration with appropriate type required to navigate stepper using a button */
{
  id: "back",
  colorType: "primary",
  caption: "back",
  type: DialogButtonType.Back
}, {
  id: "next",
  colorType: "primary",
  caption: "next",
  type: DialogButtonType.Next,
  canDisable: true
}];

/**
 * Generated bundle index. Do not edit.
 */

export { CommentStatisticType, DEFAULT_BUTTONS, DEFAULT_CANCEL_BUTTON, DEFAULT_OK_BUTTON, DEFAULT_STEPPER_BUTTONS, DialogBaseConfig, DialogButtonType };
