import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppStateService } from '../../services/app-state.service';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.css']
})
export class ForbiddenComponent implements OnInit, OnDestroy {

    constructor(public appState: AppStateService, private router: Router) {
    }

    ngOnInit() {
        this.appState.showForbidden = true;
        const pathFractions = this.router.url.split('/');
        if (pathFractions.length === 2 && pathFractions[1].toLowerCase() === '404') {
            this.appState.forbiddenMessage = '<div>The page you requested could not be found</div>';
            this.appState.forbiddenImageUrl = '//res.cloudinary.com/twistoflime/image/upload/Portal/Site/HLError404.svg ';
        }
    }

    goHome() {
        window.location.href = '/';
    }

    ngOnDestroy() {
        this.appState.showForbidden = false;
    }
}
