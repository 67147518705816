import { Component, Input, OnInit } from '@angular/core';
import { ClientAccount, ClientSubAccount } from 'src/app/services/app-state.model';
import * as _ from 'lodash';
import { FieldSettingsModel } from '@syncfusion/ej2-dropdowns';
import { AppStateService } from 'src/app/services/app-state.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'sub-account-selector-dialog',
  templateUrl: './sub-account-selector-dialog.component.html',
  styleUrl: './sub-account-selector-dialog.component.less'
})
export class SubAccountSelectorDialogComponent {
  @Input() accounts: ClientAccount[] = [];
  @Input() selectedSubAccountGuid: string;

  subAccounts: ClientSubAccount[] = [];

  accountFields: FieldSettingsModel = { text: "clientAccountName", value: "clientAccountGuid" };
  subAccountFields: FieldSettingsModel = { text: "clientSubAccountName", value: "clientSubAccountGuid" };

  constructor(public appState: AppStateService,
    public dataService: DataService,) { }

  onAccountChanged(event) {
    let selectedAccount: ClientAccount = this.accounts.find(a => a.clientAccountGuid === event.value);

    this.dataService.getClientSubAccounts(selectedAccount.clientAccountId).subscribe((response: any) => {
      selectedAccount.subAccounts = response.data.clientSubAccounts;
      this.subAccounts = selectedAccount.subAccounts;
    });
  }

  onSubAccountChanged(event) {
    this.selectedSubAccountGuid = event.value;
  }
}
