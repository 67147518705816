import * as i0 from '@angular/core';
import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS } from '@angular/forms';
class MaxWordsValidatorDirective {
  constructor() {
    this.maxWords = null;
  }
  validate(control) {
    return this.maxWords ? maxWordsValidator(this.maxWords)(control) : null;
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: MaxWordsValidatorDirective,
      deps: [],
      target: i0.ɵɵFactoryTarget.Directive
    });
  }
  static {
    this.ɵdir = i0.ɵɵngDeclareDirective({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: MaxWordsValidatorDirective,
      isStandalone: true,
      selector: "[maxWordsValidator]",
      inputs: {
        maxWords: ["maxWordsValidator", "maxWords"]
      },
      providers: [{
        provide: NG_VALIDATORS,
        useExisting: MaxWordsValidatorDirective,
        multi: true
      }],
      ngImport: i0
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: MaxWordsValidatorDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[maxWordsValidator]',
      providers: [{
        provide: NG_VALIDATORS,
        useExisting: MaxWordsValidatorDirective,
        multi: true
      }],
      standalone: true
    }]
  }],
  propDecorators: {
    maxWords: [{
      type: Input,
      args: ["maxWordsValidator"]
    }]
  }
});
function maxWordsValidator(maxWords) {
  return control => {
    const words = control.value?.split(/\s+/).length;
    return words > maxWords ? {
      maxWords: true
    } : null;
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { MaxWordsValidatorDirective, maxWordsValidator };
