import * as i0 from '@angular/core';
import { Component, ViewChild, Input, NgModule } from '@angular/core';
import * as Utils from '@theevolvedgroup/component-library/utils';
import { take } from 'rxjs';
import * as i1 from '@angular/material/icon';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@ngui/common';
import { NguiInviewModule } from '@ngui/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
class IconComponent {
  constructor(elementRef, _iconRegistry) {
    this.elementRef = elementRef;
    this._iconRegistry = _iconRegistry;
    /** Material library of icons to use */
    this.library = "symbols";
    /** Material Symbols Optical Size - must be between 20 and 48 */
    this.opticalSize = 24;
    /** Material Symbols Weight - must be between 100 and 700 with intervals of 100 */
    this.iconWeight = 400;
    this.svgIconElement = null;
    this.sizeValue = "var(--theme-icon-sm)";
  }
  ngOnInit() {
    this.setIconColor();
    this.setSize();
    this.setVariant();
    this.setOpticalSize();
    this.setIconWeight();
    if (!this.library) {
      this.library = "symbols";
    }
  }
  ngOnChanges(simpleChanges) {
    if (simpleChanges["color"]) {
      this.setIconColor();
    }
    if (simpleChanges["size"]) {
      this.setSize();
    }
    if (simpleChanges["variant"]) {
      this.setVariant();
    }
    if (simpleChanges["opticalSize"]) {
      this.setOpticalSize();
    }
    if (simpleChanges["weight"]) {
      this.setIconWeight();
    }
  }
  onInview() {
    if (this.svgIcon) {
      this.setSvgIcon(this.svgIcon);
    } else {
      this.clearSvgIcon();
    }
  }
  setIconColor() {
    let {
      theme,
      customColor
    } = Utils.getColorConfig(this.color, "primary");
    this.theme = theme;
    this.customColor = customColor;
  }
  setSize() {
    if (this.size && this.size == "xs") {
      this.sizeValue = "var(--theme-icon-xs)";
    } else if (this.size && this.size == "small") {
      this.sizeValue = "var(--theme-icon-sm)";
    } else if (this.size && this.size == "medium") {
      this.sizeValue = "var(--theme-icon-md)";
    } else if (this.size && this.size == "large") {
      this.sizeValue = "var(--theme-icon-lg)";
    } else if (this.size) {
      this.sizeValue = this.size;
    }
  }
  setVariant() {
    if (!this.variant) {
      this.variant = "outlined";
    } else if (this.library == "icons" && this.variant == "rounded") {
      this.variant = "round";
    }
  }
  setOpticalSize() {
    if (!this.opticalSize) {
      this.opticalSize = 24;
    }
    this.elementRef.nativeElement.style.setProperty("--icon-optical-size", this.opticalSize);
  }
  setIconWeight() {
    if (!this.iconWeight) {
      this.iconWeight = 400;
    }
    this.elementRef.nativeElement.style.setProperty("--icon-weight", this.iconWeight);
  }
  setSvgIcon(svgIcon) {
    this._iconRegistry.getNamedSvgIcon(svgIcon, "").pipe(take(1)).subscribe(svg => {
      this.svgIconContainer?.nativeElement.replaceChildren(svg);
    }, err => {
      const errorMessage = `Error retrieving icon ${svgIcon}! ${err.message}`;
      console.error(errorMessage);
    });
  }
  clearSvgIcon() {
    const layoutElement = this.svgIconContainer?.nativeElement;
    let childCount = layoutElement?.childNodes.length ?? 0;
    // Remove existing non-element child nodes and SVGs, and add the new SVG element. Note that
    // we can't use innerHTML, because IE will throw if the element has a data binding.
    while (childCount--) {
      const child = layoutElement.childNodes[childCount];
      // 1 corresponds to Node.ELEMENT_NODE. We remove all non-element nodes in order to get rid
      // of any loose text nodes, as well as any SVG elements in order to remove any old icons.
      if (child.nodeType !== 1 || child.nodeName.toLowerCase() === 'svg') {
        child.remove();
      }
    }
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: IconComponent,
      deps: [{
        token: i0.ElementRef
      }, {
        token: i1.MatIconRegistry
      }],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: IconComponent,
      selector: "teg-icon",
      inputs: {
        customClass: "customClass",
        icon: "icon",
        outline: "outline",
        color: "color",
        size: "size",
        variant: "variant",
        library: "library",
        opticalSize: "opticalSize",
        iconWeight: "iconWeight",
        iconSrc: "iconSrc",
        svgIcon: "svgIcon"
      },
      host: {
        properties: {
          "style.--icon-size": "sizeValue"
        }
      },
      viewQueries: [{
        propertyName: "svgIconContainer",
        first: true,
        predicate: ["svgIconContainer"],
        descendants: true
      }],
      usesOnChanges: true,
      ngImport: i0,
      template: "<div class=\"icon-container material-{{library}}-{{variant}} {{theme}} {{customClass}}\"\n     [ngClass]=\"{ 'outlined': outline }\"\n     [ngStyle]=\"{ 'color': customColor }\"\n     nguiInview\n     (nguiInview)=\"onInview()\">\n    <div class=\"svg-icon-container\"\n         #svgIconContainer>\n    </div>\n    <ng-container *ngIf=\"!icon && !iconSrc && !svgIcon\"\n                  #content>\n        <ng-content></ng-content>\n    </ng-container>\n    <ng-container *ngIf=\"!iconSrc\">\n        {{ icon }}\n    </ng-container>\n    <ng-container *ngIf=\"iconSrc\">\n        <img src=\"{{iconSrc}}\">\n    </ng-container>\n</div>",
      styles: [".icon-container{display:flex;align-items:center;justify-content:center;-webkit-user-select:none;user-select:none;color:var(--theme-icon-default);height:var(--icon-height, var(--icon-size));width:var(--icon-width, var(--icon-size));font-size:var(--icon-width, var(--icon-size))}.icon-container.outlined{font-variation-settings:\"FILL\" 0,\"opsz\" var(--icon-optical-size),\"wght\" var(--icon-weight)}.icon-container.primary{color:var(--theme-icon-primary)}.icon-container.secondary{color:var(--theme-icon-secondary)}.icon-container.accent-1{color:var(--theme-color-accent-1)}.icon-container.accent-2{color:var(--theme-color-accent-2)}.icon-container.accent-3{color:var(--theme-color-accent-3)}.icon-container.accent-4{color:var(--theme-color-accent-4)}.icon-container.info{color:var(--theme-color-info)}.icon-container.success{color:var(--theme-color-success)}.icon-container.warn{color:var(--theme-color-warn)}.icon-container.error{color:var(--theme-color-error)}.icon-container.add-tile{color:var(--theme-add-tile-icon-color);width:80px!important;height:80px!important;font-size:80px!important}.icon-container .svg-icon-container{display:contents}.icon-container .svg-icon-container:empty{display:none}.material-symbols-outlined,.material-symbols-rounded,.material-symbols-sharp{font-variation-settings:\"FILL\" 1,\"opsz\" var(--icon-optical-size),\"wght\" var(--icon-weight)}\n"],
      dependencies: [{
        kind: "directive",
        type: i2.NgClass,
        selector: "[ngClass]",
        inputs: ["class", "ngClass"]
      }, {
        kind: "directive",
        type: i2.NgIf,
        selector: "[ngIf]",
        inputs: ["ngIf", "ngIfThen", "ngIfElse"]
      }, {
        kind: "directive",
        type: i2.NgStyle,
        selector: "[ngStyle]",
        inputs: ["ngStyle"]
      }, {
        kind: "directive",
        type: i3.NguiInviewDirective,
        selector: "[nguiInview], [nguiOutview]",
        inputs: ["observerOptions", "options"],
        outputs: ["nguiInview", "nguiOutview"]
      }]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: IconComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: "teg-icon",
      host: {
        "[style.--icon-size]": "sizeValue"
      },
      template: "<div class=\"icon-container material-{{library}}-{{variant}} {{theme}} {{customClass}}\"\n     [ngClass]=\"{ 'outlined': outline }\"\n     [ngStyle]=\"{ 'color': customColor }\"\n     nguiInview\n     (nguiInview)=\"onInview()\">\n    <div class=\"svg-icon-container\"\n         #svgIconContainer>\n    </div>\n    <ng-container *ngIf=\"!icon && !iconSrc && !svgIcon\"\n                  #content>\n        <ng-content></ng-content>\n    </ng-container>\n    <ng-container *ngIf=\"!iconSrc\">\n        {{ icon }}\n    </ng-container>\n    <ng-container *ngIf=\"iconSrc\">\n        <img src=\"{{iconSrc}}\">\n    </ng-container>\n</div>",
      styles: [".icon-container{display:flex;align-items:center;justify-content:center;-webkit-user-select:none;user-select:none;color:var(--theme-icon-default);height:var(--icon-height, var(--icon-size));width:var(--icon-width, var(--icon-size));font-size:var(--icon-width, var(--icon-size))}.icon-container.outlined{font-variation-settings:\"FILL\" 0,\"opsz\" var(--icon-optical-size),\"wght\" var(--icon-weight)}.icon-container.primary{color:var(--theme-icon-primary)}.icon-container.secondary{color:var(--theme-icon-secondary)}.icon-container.accent-1{color:var(--theme-color-accent-1)}.icon-container.accent-2{color:var(--theme-color-accent-2)}.icon-container.accent-3{color:var(--theme-color-accent-3)}.icon-container.accent-4{color:var(--theme-color-accent-4)}.icon-container.info{color:var(--theme-color-info)}.icon-container.success{color:var(--theme-color-success)}.icon-container.warn{color:var(--theme-color-warn)}.icon-container.error{color:var(--theme-color-error)}.icon-container.add-tile{color:var(--theme-add-tile-icon-color);width:80px!important;height:80px!important;font-size:80px!important}.icon-container .svg-icon-container{display:contents}.icon-container .svg-icon-container:empty{display:none}.material-symbols-outlined,.material-symbols-rounded,.material-symbols-sharp{font-variation-settings:\"FILL\" 1,\"opsz\" var(--icon-optical-size),\"wght\" var(--icon-weight)}\n"]
    }]
  }],
  ctorParameters: () => [{
    type: i0.ElementRef
  }, {
    type: i1.MatIconRegistry
  }],
  propDecorators: {
    svgIconContainer: [{
      type: ViewChild,
      args: ["svgIconContainer"]
    }],
    customClass: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    outline: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    variant: [{
      type: Input
    }],
    library: [{
      type: Input
    }],
    opticalSize: [{
      type: Input
    }],
    iconWeight: [{
      type: Input
    }],
    iconSrc: [{
      type: Input
    }],
    svgIcon: [{
      type: Input
    }]
  }
});
class IconModule {
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: IconModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: IconModule,
      declarations: [IconComponent],
      imports: [CommonModule, NguiInviewModule],
      exports: [IconComponent]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: IconModule,
      providers: [provideHttpClient(withInterceptorsFromDi())],
      imports: [CommonModule, NguiInviewModule]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: IconModule,
  decorators: [{
    type: NgModule,
    args: [{
      exports: [IconComponent],
      declarations: [IconComponent],
      imports: [CommonModule, NguiInviewModule],
      providers: [provideHttpClient(withInterceptorsFromDi())]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { IconComponent, IconModule };
