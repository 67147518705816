<div *ngIf="showAccountMetrics" class="content-section-container">
    <teg-content-section 
        title="Account Metrics"
        [tiles]="accountMetricsTiles"
        [loading]= "false"
        [showAddTile] = "false"
        (tileClicked)="onTileClicked($event)">
    </teg-content-section>
</div>

<div *ngIf="showUserMetrics" class="content-section-container">
    <teg-content-section 
        title="User Access Metrics"
        [tiles]="userMetricsTiles"
        [loading]= "false"
        [showAddTile] = "false"
        (tileClicked)="onTileClicked($event)">
    </teg-content-section>
</div>

<div *ngIf="showApplicationMetrics"  class="content-section-container">
    <teg-content-section 
        title="Application Metrics"
        [tiles]="applicationMetricsTiles"
        [loading]= "false"
        [showAddTile] = "false"
        (tileClicked)="onTileClicked($event)">
    </teg-content-section>
</div>