import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map, tap } from 'rxjs/operators';
import { AppStateService } from './app-state.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuardService  {

    constructor(
        private router: Router,
        private oidcSecurityService: OidcSecurityService,
        private appState: AppStateService
    ) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const canActivate$ = this.oidcSecurityService.isAuthenticated().pipe(
            map((isAuthorized: boolean) => {
                if (isAuthorized) {
                    return true;
                }
                this.router.navigate(['/Unauthorized']);
                return false;
            }));
        return canActivate$;
        
    }
    public canLoad(route: Route): boolean {
        if (!this.appState.isAuthorized) {
            this.router.navigate(['/Unauthorized']);
            return;
        }
        return true;
    }
}
