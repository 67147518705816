import { DataService } from '../../services/data.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppStateService } from '../../services/app-state.service';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { ActionType, MenuOptionsType } from '@theevolvedgroup/component-library/action-bar/types';
import { TileClickEvent, TileField } from '@theevolvedgroup/component-library/tile';
import { ViewType } from '@theevolvedgroup/component-library/content-section';
import { ContentTypeGuids, Survey } from "../../services/app-state.model";
import { DialogFactoryService, DialogService, NameDialogComponent } from '@theevolvedgroup/component-library/dialog-base';
import { ShareDialogComponent } from '../dialogs/share-dialog/share-dialog.component';
import { DialogCloseResult } from '@theevolvedgroup/component-library/dialog-base/types';
import { SubAccountSelectorDialogComponent } from '../dialogs/sub-account-selector-dialog/sub-account-selector-dialog.component';
import { FieldSettingsModel } from '@syncfusion/ej2-angular-dropdowns';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class OverviewComponent implements OnInit {
  ViewType: ViewType = ViewType.Tile;
  headerHeight: number = 220;
  viewportHeight: number = window.innerHeight - this.headerHeight;

  selectedProdDateId: number = 1;
  prodDateFields: FieldSettingsModel = { text: "prodDateName", value: "prodDateId" };

  prodDates: any[] = [
    {
      prodDateId: 1,
      prodDateName: "This Year",
    },
    {
      prodDateId: 2,
      prodDateName: "Last Year",
    },
    {
      prodDateId: 3,
      prodDateName: "Older",
    }
  ];

  surveyTiles: any = [];
  surveyActions: any = [
    {
      icon: "list",
      outline: false,
      color: "primary",
      action: {
        name: "viewType",
        type: ActionType.Toggle,
        data: {
          selected: false,
          selectedIcon: {
            icon: "view_module",
            outline: false,
            color: "primary",
            tooltip: "Tile View",
          },
          unselectedIcon: {
            icon: "list",
            outline: false,
            color: "primary",
            tooltip: "List View",
          },
        },
      },
    },
  ];

  surveyListViewFields: TileField[] = [
    {
      field: "surveyGuid",
      label: "Survey Guid",
      textAlign: "left",
      width: "100px",
      showOnTile: false,
      showOnList: true,
    },
    {
      field: "surveyName",
      label: "Name",
      textAlign: "left",
      width: "20%",
      showOnTile: false,
      showOnList: true,
    },
    {
      field: "surveyTitle",
      label: "Title To",
      textAlign: "left",
      width: "20%",
      showOnTile: false,
      showOnList: true,
    },
    {
      field: "productionDateTime",
      label: "Prod. Date",
      textAlign: "left",
      width: "100px",
      type: "date",
      format: "dd-MMM-yyyy HH:mm",
      showOnTile: true,
      showOnList: true,
    },
    {
      field: "versionNo",
      label: "Version",
      textAlign: "center",
      width: "50px",
      showOnTile: true,
      showOnList: true,
    },
    {
      field: "sampleRetentionPeriod",
      label: "Sample Retention",
      textAlign: "center",
      width: "60px",
      showOnTile: false,
      showOnList: true,
    },
    {
      field: "dataRetentionPeriod",
      label: "Data Retention",
      width: "60px",
      showOnTile: false,
      showOnList: true,
    }
  ];

  constructor(
    public appState: AppStateService,
    public dataService: DataService,
    public dialog: MatDialog,
    private dialogFactoryService: DialogFactoryService) {

  }

  ngOnInit(): void {
    this.loadOverview();
  }

  loadSurveyTiles() {
    this.surveyTiles = [];

    const currentYear = new Date().getFullYear();
    const startOfCurrentYear = new Date(currentYear, 0, 1);
    const startOfLastYear = new Date(currentYear - 1, 0, 1);

    _.forEach(this.appState.currentSubAccount.surveys, (survey: any) => {
      let productionDateTime = new Date(survey.productionDateTime);

      if ((productionDateTime < startOfCurrentYear && this.selectedProdDateId == 1) ||
        ((productionDateTime < startOfLastYear || productionDateTime >= startOfCurrentYear) && this.selectedProdDateId == 2) ||
        (productionDateTime >= startOfLastYear && this.selectedProdDateId == 3))
        return;

      this.surveyTiles.push({
        avatarUrl: "https://res.cloudinary.com/twistoflime/Portal/Site/SurveyShaperIcon.svg",
        title: survey.surveyTitle,
        actions: [
          {
            icon: "share",
            tooltip: "Share",
            outline: false,
            color: "var(--theme-color-grey-0)",
            action: { name: "share" },
          },
          {
            icon: "more_vert",
            action: { name: "more" },
            menuOptions: {
              groups: [
                {
                  type: MenuOptionsType.Default,
                  options: [
                    {
                      label: "Move",
                      action: {
                        name: "move",
                      },
                    },
                  ],
                }
              ],
            },
          },
        ],
        fields: [...this.surveyListViewFields],
        selectable: false,
        data: {
          surveyGuid: survey.surveyGuid,
          surveyName: survey.surveyName,
          surveyTitle: survey.surveyTitle,
          productionDateTime: survey.productionDateTime,
          versionNo: survey.versionNo,
          sampleRetentionPeriod: survey.sampleRetentionPeriod,
          dataRetentionPeriod: survey.dataRetentionPeriod,
        },
      });
    });

    this.surveyTiles = [...this.surveyTiles];
  }

  loadSurveys() {
    this.appState.dataLoaded = false;
    this.dataService
      .getSurveys(this.appState.currentSubAccount.clientSubAccountGuid)
      .subscribe(response => {
        this.appState.currentSubAccount.surveys = response.data.surveys;
        this.loadSurveyTiles();
        this.appState.dataLoaded = true;

        this.dataService
          .getclientSubAccountShares(this.appState.currentSubAccount.clientSubAccountGuid)
          .subscribe(response => {
            this.appState.currentSubAccount.shareByEmails = response.data.shareByEmails;
          });
      });
  }

  loadSurveyDetails(survey: Survey) {
    this.appState.dataLoaded = false;
    this.dataService
      .getSurveyShares(survey.surveyGuid)
      .subscribe(response => {
        survey.shareByEmails = response.data.shareByEmails;
        this.appState.dataLoaded = true;

        this.dataService.getSurveyStats(survey.surveyGuid).subscribe(response => {
          survey.invitationSent = response.data.surveyStats.invitationSent;
          survey.surveyStarted = response.data.surveyStats.surveyStarted;
          survey.surveyCompleted = response.data.surveyStats.surveyCompleted;
        });
      });
  }

  archiveSurvey(survey) {
    this.dataService
      .surveyArchive(survey)
      .subscribe(response => {
        survey.isArchived = response.data.surveyArchive.isArchived;
        survey.archivedByEmailAddress = response.data.surveyArchive.archivedByEmailAddress;
        survey.archivedDateTime = response.data.surveyArchive.archivedDateTime;
        survey.isLoading = false;
      });
  }

  deleteSurvey(survey) {
    survey.isLoading = true;
    this.dataService
      .surveyDelete(survey)
      .subscribe(response => {
        survey.isDeleted = response.data.surveyDelete.isDeleted;
        survey.deletedByEmailAddress = response.data.surveyDelete.deletedByEmailAddress;
        survey.deletedDateTime = response.data.surveyDelete.deletedDateTime;
        survey.isLoading = false;
      });
  }

  loadOverview() {
    this.loadSurveys();
  }

  onItemOptionClicked(event: TileClickEvent, section: string) {
    if (event.action && event.item.data) {
      if (section == "survey") {
        let survey = _.find(this.appState.currentSubAccount.surveys, { surveyGuid: event.item.data.surveyGuid });

        if (event.action.action) {
          if (event.action.action.name == "move") {
            this.moveSurvey(survey);
          }
        }
        else {
          if (event.action.name == "share") {
            this.shareSurvey(survey);
          }
        }
      }
    }
  }

  onProdDateChanged(event: any) {
    this.selectedProdDateId = event.value;
    this.loadSurveyTiles();
  }

  shareSurvey(survey: Survey) {
    this.dataService.getSurveyShares(survey.surveyGuid)
      .subscribe(response => {
        survey.shareByEmails = response.data.shareByEmails;

        let contentComponentProperties = {
          shareByEmails: survey.shareByEmails,
          contentTypeGuid: ContentTypeGuids.Survey,
          contentItemGuid: survey.surveyGuid,
        };

        let shareDialog: DialogService = this.dialogFactoryService.openDynamicDialog({
          headerText: `Share Survey - ${survey.surveyTitle}`,
          contentComponent: ShareDialogComponent,
          buttons: [{ id: "close", caption: "DONE", colorType: "primary" }],
          contentComponentProperties,
        }, { widthValue: 640 });

        shareDialog.closed$.subscribe((result: DialogCloseResult) => {

        });
      });
  }

  moveSurvey(survey: Survey) {
    let contentComponentProperties = {
      accounts: _.filter(this.appState.clientAccounts, { isDeleted: false, isArchived: false }),
      selectedSubAccountGuid: "",
    };

    let dialog: DialogService = this.dialogFactoryService.openDynamicDialog({
      headerText: `Move Survey - ${survey.surveyTitle}`,
      contentComponent: SubAccountSelectorDialogComponent,
      buttons: [{ id: "cancel", caption: "CANCEL", colorType: "secondary" }, { id: "ok", caption: "OK", colorType: "primary" }],
      contentComponentProperties,
    }, { widthValue: 480 });

    dialog.closed$.subscribe({
      next: (result) => {
        if (result.button && result.button.id == "ok") {

          this.dataService.moveSurvey(survey.surveyGuid, result.contentComponentProperties.selectedSubAccountGuid)
            .subscribe(survey => {
              this.loadSurveys();
            });
        }
      }
    });
  }
}
