import { DataService } from '../../services/data.service';
import { Component, EventEmitter, Input, input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AppStateService } from '../../services/app-state.service';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { AccountDialogComponent } from '../dialogs/account-dialog/account-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ClientAccount, ClientSubAccount, ContentStatusEnum, ContentTypeGuids, ContentStatusFields, ContentStatuses } from 'src/app/services/app-state.model';
import { debug } from 'console';
import { SubAccountDialogComponent } from '../dialogs/sub-account-dialog/sub-account-dialog.component';
import { TileClickEvent, TileField } from '@theevolvedgroup/component-library/tile';
import { DialogFactoryService, DialogService } from '@theevolvedgroup/component-library/dialog-base';
import { DialogCloseResult } from '@theevolvedgroup/component-library/dialog-base/types';
import { ShareDialogComponent } from '../dialogs/share-dialog/share-dialog.component';
import { ViewType } from '@theevolvedgroup/component-library/content-section';
import { ActionType, MenuOptionsType } from '@theevolvedgroup/component-library/action-bar/types';

@Component({
  selector: 'app-sub-account',
  templateUrl: './sub-account.component.html',
  styleUrls: ['./sub-account.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class SubAccountComponent implements OnInit {
  subAccountTiles: any = [];
  ViewType: ViewType = ViewType.Tile;

  contentStatusFields = ContentStatusFields;
  contentStatuses = [...ContentStatuses];
  selectedContentStatusId: number = ContentStatusEnum.Active;

  subAccountActions: any = [
    {
      icon: "list",
      outline: false,
      color: "primary",
      action: {
        name: "viewType",
        type: ActionType.Toggle,
        data: {
          selected: false,
          selectedIcon: {
            icon: "view_module",
            outline: false,
            color: "primary",
            tooltip: "Tile View",
          },
          unselectedIcon: {
            icon: "list",
            outline: false,
            color: "primary",
            tooltip: "List View",
          },
        },
      },
    },
  ];

  listViewFields: TileField[] = [
    {
      field: "clientSubAccountGuid",
      label: "Sub Account Guid",
      textAlign: "left",
      width: "320px",
      showOnTile: false,
      showOnList: true,
    },
    {
      field: "clientSubAccountId",
      label: "Sub Account ID",
      textAlign: "left",
      width: "240px",
      showOnTile: false,
      showOnList: true,
    },
    {
      field: "clientSubAccountName",
      label: "Sub Account Name",
      textAlign: "left",
      width: "80%",
      showOnTile: false,
      showOnList: true,
    }
  ];

  constructor(
    public appState: AppStateService,
    public dataService: DataService,
    public dialog: MatDialog,
    private router: Router,
    private dialogFactoryService: DialogFactoryService) {

  }

  ngOnInit(): void {
    this.loadSubAccountTiles();
  }

  loadSubAccountTiles() {
    this.subAccountTiles = [];

    const currentDate = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);

    _.forEach(this.appState.currentAccount.subAccounts, (clientSubAccount: ClientSubAccount) => {
      if ((!clientSubAccount.isDeleted && this.selectedContentStatusId == ContentStatusEnum.Deleted) ||
        (!clientSubAccount.isArchived && this.selectedContentStatusId == ContentStatusEnum.Archived) ||
        ((clientSubAccount.isDeleted || clientSubAccount.isArchived) && this.selectedContentStatusId == ContentStatusEnum.Active))
        return;

      // Exclude anything deleted more than 30 days ago
      if (clientSubAccount.isDeleted && clientSubAccount.deletedDateTime && new Date(clientSubAccount.deletedDateTime) < thirtyDaysAgo)
        return;

      this.subAccountTiles.push({
        avatarUrl: clientSubAccount.clientSubAccountIconUrl,
        title: clientSubAccount.clientSubAccountName,
        data: {
          clientSubAccountGuid: clientSubAccount.clientSubAccountGuid,
          clientSubAccountId: clientSubAccount.clientSubAccountId,
          clientSubAccountName: clientSubAccount.clientSubAccountName
        },
        fields: [...this.listViewFields],
        selectable: false,
        loading: false,
        actions: [
          {
            icon: "share",
            tooltip: "Share",
            outline: true,
            color: "var(--theme-color-grey-0)",
            action: {
              name: "share",
              data: { selected: false },
            },
          },
          {
            icon: "more_vert",
            action: { name: "more" },
            menuOptions: {
              groups: [
                {
                  type: MenuOptionsType.Default,
                  options: [
                    {
                      label: "Edit",
                      action: {
                        name: "edit",
                      },
                    },
                    {
                      label: clientSubAccount.isDeleted ? "Activate" : "Delete",
                      action: {
                        name: "delete",
                      },
                    },
                    {
                      label: clientSubAccount.isArchived ? "Unarchive" : "Archive",
                      action: {
                        name: "archive",
                      },
                    },
                  ],
                }
              ],
            },
          },
          {
            icon: "arrow_forward",
            tooltip: "Manage",
            outline: true,
            color: "var(--theme-color-grey-0)",
            action: {
              name: "manage",
              data: { selected: false },
            },
          },
        ],
        menuOptions: null,
        url: "#"
      });
    });

    this.subAccountTiles = [...this.subAccountTiles];
  }

  onSubAccountTileClicked(subAccount) {
    this.appState.currentSubAccount = _.find(this.appState.currentAccount.subAccounts, { clientSubAccountGuid: subAccount.item.data.clientSubAccountGuid });
    this.router.navigateByUrl(this.appState.currentAccount.clientAccountVanityName + "/" + this.appState.currentSubAccount.clientSubAccountName);
  }

  onAddSubAccountTileClicked(event) {
    const dialogRef = this.dialog.open(SubAccountDialogComponent, {
      width: '600px',
      hasBackdrop: true,
      disableClose: true, // Whether the user can use escape or clicking outside to close a modal.
      data: {
        isAccount: false,
        clientAccount: this.appState.currentAccount,
        mode: 'Add',
      }
    });

    dialogRef.afterClosed().subscribe((account: any) => {
      if (account) {
        this.appState.currentAccount.subAccounts = [];
        this.appState.dataLoaded = false;
        this.dataService.getClientSubAccounts(this.appState.currentAccount.clientAccountId).subscribe((response: any) => {
          this.appState.currentAccount.subAccounts = response.data.clientSubAccounts;

          this.loadSubAccountTiles();

          this.appState.dataLoaded = true;
        });
      }
    });
  }

  onItemOptionClicked(event: TileClickEvent) {
    if (!event.item.data)
      return;

    let subAccount = _.find(this.appState.currentAccount.subAccounts, { clientSubAccountGuid: event.item.data.clientSubAccountGuid });

    if (event.action.action) {
      if (event.action.action.name == "edit") {
        this.editSubAccount(subAccount);
      }
      else if (event.action.action.name == "delete") {
        this.toggleDeleteSubAccount(subAccount);
      }
      else if (event.action.action.name == "archive") {
        this.toggleArchiveSubAccount(subAccount);
      }
    } else if (event.action) {

      if (event.action.name == "manage") {
        this.onSubAccountTileClicked(event);
      }
      else if (event.action.name == "share") {
        this.shareSubAccount(subAccount);
      }
    }
  }

  onContentStatusChanged(event: any) {
    this.selectedContentStatusId = event.value;
    this.loadSubAccountTiles();
  }

  editSubAccount(subAccount: any) {
    const dialogRef = this.dialog.open(SubAccountDialogComponent, {
      width: '600px',
      hasBackdrop: true,
      disableClose: true, // Whether the user can use escape or clicking outside to close a modal.
      data: {
        mode: 'Edit',
        clientSubAccount: subAccount,
        clientAccount: this.appState.currentAccount,
        isAccount: false
      }
    });

    dialogRef.afterClosed().subscribe((response: any) => {
      if (response) {
        subAccount.clientSubAccountName = response.clientSubAccountName;
        subAccount.clientSubAccountVanityName = response.clientSubAccountVanityName;
        subAccount.clientSubAccountIconUrl = response.clientSubAccountIconUrl;

        this.loadSubAccountTiles();
      }
    });
  }

  shareSubAccount(subAccount: ClientSubAccount) {
    this.dataService.getclientSubAccountShares(subAccount.clientSubAccountGuid)
      .subscribe(response => {
        subAccount.shareByEmails = response.data.shareByEmails;

        let contentComponentProperties = {
          shareByEmails: subAccount.shareByEmails,
          contentTypeGuid: ContentTypeGuids.SecurityGroup,
          contentItemGuid: subAccount.clientSubAccountGuid
        };

        let shareDialog: DialogService = this.dialogFactoryService.openDynamicDialog({
          headerText: `Share Account - ${subAccount.clientSubAccountName}`,
          contentComponent: ShareDialogComponent,
          buttons: [{ id: "close", caption: "DONE", colorType: "primary" }],
          contentComponentProperties,
        }, { widthValue: 640 });

        shareDialog.closed$.subscribe((result: DialogCloseResult) => {

        });
      });
  }

  toggleArchiveSubAccount(subAccount) {
    let statusName = subAccount.isArchived ? "unarchive" : "archive";
    let headerText = _.capitalize(statusName) + " Sub Account";

    let confirmDialog = this.dialogFactoryService.openMessageDialog({
      headerText: headerText,
      contentComponentProperties: {
        message: `Are you sure you want to ${statusName} this Sub Account?`,
      },
      buttons: [{ id: "no", colorType: "secondary", caption: "NO" }, { id: "yes", colorType: "warn", caption: "YES" }],
      headerColor: "warn",
    });

    confirmDialog.closed$.subscribe((result: DialogCloseResult) => {
      if (result.button.id == 'yes') {
        this.appState.dataLoaded = false;

        subAccount.isArchived = !subAccount.isArchived;
        this.dataService
          .clientSubAccountArchive(subAccount)
          .subscribe(response => {
            subAccount.isArchived = response.data.clientSubAccountArchive.isArchived;
            subAccount.archivedByEmailAddress = response.data.clientSubAccountArchive.archivedByEmailAddress;
            subAccount.archivedDateTime = response.data.clientSubAccountArchive.archivedDateTime;

            this.appState.dataLoaded = true;

            this.loadSubAccountTiles();
          });
      }
    });
  }

  toggleDeleteSubAccount(subAccount) {
    let statusName = subAccount.isDeleted ? "activate" : "delete";
    let headerText = _.capitalize(statusName) + " Sub Account";

    let confirmDialog = this.dialogFactoryService.openMessageDialog({
      headerText: headerText,
      contentComponentProperties: {
        message: `Are you sure you want to ${statusName} this Sub Account?`,
      },
      buttons: [{ id: "no", colorType: "secondary", caption: "NO" }, { id: "yes", colorType: "warn", caption: "YES" }],
      headerColor: "warn",
    });

    confirmDialog.closed$.subscribe((result: DialogCloseResult) => {
      if (result.button.id == 'yes') {
        this.appState.dataLoaded = false;
        subAccount.isDeleted = !subAccount.isDeleted;
        this.dataService
          .clientSubAccountDelete(subAccount)
          .subscribe(response => {
            subAccount.isDeleted = response.data.clientSubAccountDelete.isDeleted;
            subAccount.deletedByEmailAddress = response.data.clientSubAccountDelete.deletedByEmailAddress;
            subAccount.deletedDateTime = response.data.clientSubAccountDelete.deletedDateTime;

            this.appState.dataLoaded = true;

            this.loadSubAccountTiles();
          });
      }
    });
  }
}
