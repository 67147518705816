import * as i0 from '@angular/core';
import { Component, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@angular/material/tooltip';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActionMenuModule } from '@theevolvedgroup/component-library/action-menu';
import { IconButtonModule } from '@theevolvedgroup/component-library/icon-button';
class AvatarComponent {
  constructor() {}
  ngOnChanges() {
    this.switch = this.avatarUrl ? "avatarUrl" : "initials";
    this.initials = "";
    if (this.fullName) {
      if (!!this.fullName.trim()) {
        var names = this.fullName.includes(" ") ? this.fullName.split(' ') : [this.fullName, " "];
        this.initials = (names[0].substring(0, 1) + names[1].substring(0, 1)).toUpperCase();
      }
    }
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: AvatarComponent,
      deps: [],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: AvatarComponent,
      selector: "teg-avatar",
      inputs: {
        avatarUrl: "avatarUrl",
        fullName: "fullName",
        emailAddress: "emailAddress"
      },
      usesOnChanges: true,
      ngImport: i0,
      template: "<div #buttonAvatar class=\"div-button-avatar\" matTooltip={{emailAddress}}>\n    <ng-container *ngIf=\"switch == 'avatarUrl'\">\n        <img [src]=\"avatarUrl\" class=\"avatar-image\">\n    </ng-container>\n    <ng-container *ngIf=\"switch == 'initials'\">\n        <span class=\"avatar-initials\">{{initials}}</span>\n    </ng-container>\n</div>\n",
      styles: [".div-button-avatar{border:1;border-radius:50%;background-color:var(--theme-color-info);width:var(--theme-avatar-size);height:var(--theme-avatar-size);cursor:pointer;align-items:center;justify-content:center;display:flex}.div-button-avatar .avatar-initials{color:#fff;font-size:12px;font-weight:700}.div-button-avatar .avatar-image{border-radius:50%;width:100%;height:100%}\n"],
      dependencies: [{
        kind: "directive",
        type: i1.NgIf,
        selector: "[ngIf]",
        inputs: ["ngIf", "ngIfThen", "ngIfElse"]
      }, {
        kind: "directive",
        type: i2.MatTooltip,
        selector: "[matTooltip]",
        inputs: ["matTooltipPosition", "matTooltipPositionAtOrigin", "matTooltipDisabled", "matTooltipShowDelay", "matTooltipHideDelay", "matTooltipTouchGestures", "matTooltip", "matTooltipClass"],
        exportAs: ["matTooltip"]
      }]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: AvatarComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'teg-avatar',
      template: "<div #buttonAvatar class=\"div-button-avatar\" matTooltip={{emailAddress}}>\n    <ng-container *ngIf=\"switch == 'avatarUrl'\">\n        <img [src]=\"avatarUrl\" class=\"avatar-image\">\n    </ng-container>\n    <ng-container *ngIf=\"switch == 'initials'\">\n        <span class=\"avatar-initials\">{{initials}}</span>\n    </ng-container>\n</div>\n",
      styles: [".div-button-avatar{border:1;border-radius:50%;background-color:var(--theme-color-info);width:var(--theme-avatar-size);height:var(--theme-avatar-size);cursor:pointer;align-items:center;justify-content:center;display:flex}.div-button-avatar .avatar-initials{color:#fff;font-size:12px;font-weight:700}.div-button-avatar .avatar-image{border-radius:50%;width:100%;height:100%}\n"]
    }]
  }],
  ctorParameters: () => [],
  propDecorators: {
    avatarUrl: [{
      type: Input
    }],
    fullName: [{
      type: Input
    }],
    emailAddress: [{
      type: Input
    }]
  }
});
class AvatarModule {
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: AvatarModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: AvatarModule,
      declarations: [AvatarComponent],
      imports: [CommonModule, ActionMenuModule, MatTooltipModule, IconButtonModule],
      exports: [AvatarComponent]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: AvatarModule,
      imports: [CommonModule, ActionMenuModule, MatTooltipModule, IconButtonModule]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: AvatarModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [CommonModule, ActionMenuModule, MatTooltipModule, IconButtonModule],
      exports: [AvatarComponent],
      declarations: [AvatarComponent],
      providers: []
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { AvatarComponent, AvatarModule };
