import { AppStateService } from './app-state.service';
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Dictionary } from './app-state.model';


@Injectable()
export class DataService {
  constructor(
    public http: HttpClient,
    public appState: AppStateService
  ) { }


  protected get(url): Observable<any> {
    let headers: HttpHeaders = this.getHeaders();
    let options = { headers: headers, body: '' };
    return this.http.get(url, options);
  }

  protected post(url, data): Observable<any> {
    let headers: HttpHeaders = this.getHeaders();
    let options = { headers: headers };
    return this.http.post(url, data, options);
  }

  private getHeaders(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json");
    headers = headers.set("Accept", "application/json");
    return headers;
  }
  getClientAccounts(): any {
    return this.http.post(
      this.appState.clientConfiguration.graphqlUrl,
      {
        query: `
        {
          clientAccounts {
            clientAccountId
            clientAccountGuid
            clientAccountName
            clientAccountVanityName
            clientAccountIconUrl
            isArchived
            archivedByEmailAddress
            archivedDateTime
            isDeleted
            deletedByEmailAddress
            deletedDateTime
          }
        }
        `,
        variables: null,
        operationName: null
      },
      { headers: this.getHeaders() }
    );
  }
  getclientAccountShares(clientAccountGuid: string): any {
    return this.http.post(
      this.appState.clientConfiguration.graphqlUrl,
      {
        query: `
        {
          shareByEmails(contentTypeId: 1, contentItemGuid: "${clientAccountGuid}") {
            shareByEmailId
            contentTypeId
            contentItemGuid
            shareRoleId
            emailAddress
          }
        }
        `,
        variables: null,
        operationName: null
      },
      { headers: this.getHeaders() }
    );
  }
  getClientSubAccounts(clientAccountId: number): any {
    return this.http.post(
      this.appState.clientConfiguration.graphqlUrl,
      {
        query: `
        {
          clientSubAccounts(clientAccountId: ${clientAccountId}) {
            clientSubAccountId
            clientSubAccountGuid
            clientSubAccountName
            clientSubAccountIconUrl
            isArchived
            archivedByEmailAddress
            archivedDateTime
            isDeleted
            deletedByEmailAddress
            deletedDateTime
          }
        }
        `,
        variables: null,
        operationName: null
      },
      { headers: this.getHeaders() }
    );
  }
  getclientSubAccountShares(clientSubAccountGuid: string): any {
    return this.http.post(
      this.appState.clientConfiguration.graphqlUrl,
      {
        query: `
        {
          shareByEmails(contentTypeId: 2, contentItemGuid: "${clientSubAccountGuid}") {
            shareByEmailId
            contentTypeId
            contentItemGuid
            shareRoleId
            emailAddress
          }
        }
        `,
        variables: null,
        operationName: null
      },
      { headers: this.getHeaders() }
    );
  }
  getSurveys(clientSubAccountGuid: string): any {
    return this.http.post(
      this.appState.clientConfiguration.graphqlUrl,
      {
        query: `
        {
          surveys(clientSubAccountGuid: "${clientSubAccountGuid}") {
            surveyGuid
            surveyName
            surveyTitle
            productionDateTime
            versionNo
            sampleRetentionPeriod
            dataRetentionPeriod
            isArchived
            archivedByEmailAddress
            archivedDateTime
            isDeleted
            deletedByEmailAddress
            deletedDateTime
          }
        }
        `,
        variables: null,
        operationName: null
      },
      { headers: this.getHeaders() }
    );
  }
  getSurveyStats(surveyGuid: string): any {
    return this.http.post(
      this.appState.clientConfiguration.graphqlUrl,
      {
        query: `
        {
          surveyStats(surveyGuid: "${surveyGuid}") {
            invitationSent
            surveyStarted
            surveyCompleted
          }
        }
        `,
        variables: null,
        operationName: null
      },
      { headers: this.getHeaders() }
    );
  }
  getSurveyShares(surveyGuid: string): any {
    return this.http.post(
      this.appState.clientConfiguration.graphqlUrl,
      {
        query: `
        {
          shareByEmails(contentTypeId: 13, contentItemGuid: "${surveyGuid}") {
            shareByEmailId
            contentTypeId
            contentItemGuid
            shareRoleId
            emailAddress
          }
        }
        `,
        variables: null,
        operationName: null
      },
      { headers: this.getHeaders() }
    );
  }
  moveSurvey(surveyGuid: string, clientSubAccountGuid: string): any {
    return this.http.post(
      this.appState.clientConfiguration.graphqlUrl,
      {
        query: `
      mutation ($surveyGuid: String!, $clientSubAccountGuid: String!) {
        moveSurvey(surveyGuid: $surveyGuid, clientSubAccountGuid: $clientSubAccountGuid) {
          surveyGuid
        }
      }`,
        variables: {
          surveyGuid: `${surveyGuid}`,
          clientSubAccountGuid: `${clientSubAccountGuid}`
        },
        operationName: null
      },
      { headers: this.getHeaders() }
    );
  }
  clientAccountArchive(clientAccount: any): any {
    return this.http.post(
      this.appState.clientConfiguration.graphqlUrl,
      {
        query: `
      mutation ($clientAccountGuid: String!, $isArchived: Boolean!) {
        clientAccountArchive(clientAccountGuid: $clientAccountGuid, isArchived: $isArchived) {
          clientAccountGuid
          isArchived
          archivedByEmailAddress
          archivedDateTime
        }
      }`,
        variables: {
          clientAccountGuid: `${clientAccount.clientAccountGuid}`,
          isArchived: `${clientAccount.isArchived}`
        },
        operationName: null
      },
      { headers: this.getHeaders() }
    );
  }
  clientAccountDelete(clientAccount: any): any {
    return this.http.post(
      this.appState.clientConfiguration.graphqlUrl,
      {
        query: `
      mutation ($clientAccountGuid: String!, $isDeleted: Boolean!) {
        clientAccountDelete(clientAccountGuid: $clientAccountGuid, isDeleted: $isDeleted) {
          clientAccountGuid
          isDeleted
          deletedByEmailAddress
          deletedDateTime
        }
      }`,
        variables: {
          clientAccountGuid: `${clientAccount.clientAccountGuid}`,
          isDeleted: `${clientAccount.isDeleted}`
        },
        operationName: null
      },
      { headers: this.getHeaders() }
    );
  }
  clientSubAccountArchive(clientSubAccount: any): any {
    return this.http.post(
      this.appState.clientConfiguration.graphqlUrl,
      {
        query: `
      mutation ($clientSubAccountGuid: String!, $isArchived: Boolean!) {
        clientSubAccountArchive(clientSubAccountGuid: $clientSubAccountGuid, isArchived: $isArchived) {
          clientSubAccountGuid
          isArchived
          archivedByEmailAddress
          archivedDateTime
        }
      }`,
        variables: {
          clientSubAccountGuid: `${clientSubAccount.clientSubAccountGuid}`,
          isArchived: `${clientSubAccount.isArchived}`
        },
        operationName: null
      },
      { headers: this.getHeaders() }
    );
  }
  clientSubAccountDelete(clientSubAccount: any): any {
    return this.http.post(
      this.appState.clientConfiguration.graphqlUrl,
      {
        query: `
      mutation ($clientSubAccountGuid: String!, $isDeleted: Boolean!) {
        clientSubAccountDelete(clientSubAccountGuid: $clientSubAccountGuid, isDeleted: $isDeleted) {
          clientSubAccountGuid
          isDeleted
          deletedByEmailAddress
          deletedDateTime
        }
      }`,
        variables: {
          clientSubAccountGuid: `${clientSubAccount.clientSubAccountGuid}`,
          isDeleted: `${clientSubAccount.isDeleted}`
        },
        operationName: null
      },
      { headers: this.getHeaders() }
    );
  }
  surveyArchive(survey: any): any {
    return this.http.post(
      this.appState.clientConfiguration.graphqlUrl,
      {
        query: `
      mutation ($surveyGuid: String!, $isArchived: Boolean!) {
        surveyArchive(surveyGuid: $surveyGuid, isArchived: $isArchived) {
          surveyGuid
          isArchived
          archivedByEmailAddress
          archivedDateTime
        }
      }`,
        variables: {
          surveyGuid: `${survey.surveyGuid}`,
          isArchived: `${survey.isArchived}`
        },
        operationName: null
      },
      { headers: this.getHeaders() }
    );
  }
  surveyDelete(survey: any): any {
    return this.http.post(
      this.appState.clientConfiguration.graphqlUrl,
      {
        query: `
      mutation ($surveyGuid: String!, $isDeleted: Boolean!) {
        surveyDelete(surveyGuid: $surveyGuid, isDeleted: $isDeleted) {
          surveyGuid
          isDeleted
          deletedByEmailAddress
          deletedDateTime
        }
      }`,
        variables: {
          surveyGuid: `${survey.surveyGuid}`,
          isDeleted: `${survey.isDeleted}`
        },
        operationName: null
      },
      { headers: this.getHeaders() }
    );
  }
  getShareRoles(): any {
    return this.http.post(
      this.appState.clientConfiguration.graphqlUrl,
      {
        query: `
        {
          shareRoles {
            shareRoleId
            shareRoleGuid
            shareRoleName
            contentTypeGuid
            shareRoleIcon
            isDefault
          }
        }
        `,
        variables: null,
        operationName: null
      },
      { headers: this.getHeaders() }
    );
  }
  shareByEmailAdd(
    contentTypeId: number,
    contentItemGuid: string,
    shareRoleId: number,
    emailAddress: string
  ): any {
    return this.http.post(
      this.appState.clientConfiguration.graphqlUrl,
      {
        query: `
      mutation ($contentTypeId: Int!, $contentItemGuid: String!, $shareRoleId: Int!, $emailAddress: String!) {
        shareByEmailAdd(contentTypeId: $contentTypeId, contentItemGuid: $contentItemGuid, shareRoleId: $shareRoleId, emailAddress: $emailAddress) {
          shareByEmailId
        }
      }`,
        variables: {
          contentTypeId: `${contentTypeId}`,
          contentItemGuid: `${contentItemGuid}`,
          shareRoleId: `${shareRoleId}`,
          emailAddress: `${emailAddress}`
        },
        operationName: null
      },
      { headers: this.getHeaders() }
    );
  }
  shareByEmailDelete(shareByEmailId: number): any {
    return this.http.post(
      this.appState.clientConfiguration.graphqlUrl,
      {
        query: `
      mutation ($shareByEmailId: Int!) {
        shareByEmailDelete(shareByEmailId: $shareByEmailId)
      }`,
        variables: {
          shareByEmailId: `${shareByEmailId}`
        },
        operationName: null
      },
      { headers: this.getHeaders() }
    );
  }
  getApplicationList(): any {
    return this.http.post(
      this.appState.clientConfiguration.webApiUrl +
      "/application/list",
      {},
      { headers: this.getHeaders() }
    );
  }
  getUserList(patternMatch: string): any {
    return this.http.post(
      this.appState.clientConfiguration.webApiUrl + "/user/list",
      { patternMatch },
      { headers: this.getHeaders() }
    );
  }
  getUserListDetail(patternMatch: string): any {
    return this.http.post(
      this.appState.clientConfiguration.webApiUrl + "/user/listdetail",
      { patternMatch },
      { headers: this.getHeaders() }
    );
  }
  getUsersRoleList(claimValue: string): any {
    return this.http.post(
      this.appState.clientConfiguration.webApiUrl + "/user/roles",
      { claimValue },
      { headers: this.getHeaders() }
    );
  }

  addUserRole(role: any): any {
    return this.http.post(
      this.appState.clientConfiguration.webApiUrl + "/user/roles/add",
      role,
      { headers: this.getHeaders() }
    );
  }

  deleteUserRole(role: any): any {
    return this.http.post(
      this.appState.clientConfiguration.webApiUrl + "/user/roles/delete",
      role,
      { headers: this.getHeaders() }
    );
  }

  getApplicationLicenseUserList(): any {
    return this.http.post(
      this.appState.clientConfiguration.webApiUrl +
      "/applicationlicenseuser/list",
      {},
      { headers: this.getHeaders() }
    );
  }
  userLicenseAdd(user, application): any {
    return this.http.post(
      this.appState.clientConfiguration.webApiUrl +
      "/applicationlicenseuser/add",
      {
        email: user.email,
        applicationId: application.applicationId
      },
      { headers: this.getHeaders() }
    );
  }
  userLicenseRemove(user, application): any {
    return this.http.post(
      this.appState.clientConfiguration.webApiUrl +
      "/applicationlicenseuser/remove",
      {
        email: user.email,
        applicationId: application.applicationId
      },
      { headers: this.getHeaders() }
    );
  }
  userUpdate(user): any {
    return this.http.post(
      this.appState.clientConfiguration.webApiUrl +
      "/user/update",
      {
        user: user
      },
      { headers: this.getHeaders() }
    );
  }
  getAllSharesForClientSubAccount(clientSubAccountGuid: string): any {
    return this.http.post(
      this.appState.clientConfiguration.webApiUrl + "/sharebyemail/list",
      {
        clientSubAccountGuid: clientSubAccountGuid
      }
      ,
      { headers: this.getHeaders() }
    );
  }

  addAccount(account) {
    return this.post(this.appState.clientConfiguration.webApiUrl
      + "/ClientAccount/Add", account);
  }

  updateAccount(account) {
    return this.post(this.appState.clientConfiguration.webApiUrl
      + `/ClientAccount/${account.clientAccountGuid}/Update`, account);
  }

  getDataDomiciledLocation(): any {
    let url = this.appState.clientConfiguration.webApiUrl + '/DataDomiciled/Location/Get';
    return this.get(url);
  }

  getGetSubAccountByGuid(subAccountGuid: string): any {
    let url = this.appState.clientConfiguration.webApiUrl + '/SecurityGroup/' + subAccountGuid + '/Get';
    return this.get(url);
  }

  getGetAccountByGuid(accountGuid: string): any {
    let url = this.appState.clientConfiguration.webApiUrl + '/Account/' + accountGuid + '/Get';
    return this.get(url);
  }

  addSecurityGroup(securityGroup) {
    return this.post(this.appState.clientConfiguration.webApiUrl +
      `/ClientAccount/${securityGroup.clientAccountGuid}/SecurityGroup/Add`, securityGroup);
  }

  dupSecurityGroup(securityGroup) {
    return this.post(this.appState.clientConfiguration.webApiUrl +
      `/ClientAccount/${securityGroup.clientAccountGuid}/SecurityGroup/Dup`, securityGroup);
  }

  updateSecurityGroup(securityGroup) {
    return this.post(this.appState.clientConfiguration.webApiUrl +
      `/SecurityGroup/${securityGroup.clientSubAccountGuid}/Update`, securityGroup);
  }

  getUserProfile(email: string = null): Observable<any> {
    let baseUrl = this.appState.clientConfiguration.webApiUrl;
    return email ? this.get(baseUrl + '/UserProfile/' + email) : this.get(baseUrl + '/UserProfile');
  }

  getMetrics(reportName: string, parameters: Dictionary): Observable<any> {
    return this.post(`${this.appState.clientConfiguration.portalApiUrl}/api/Metrics/Report/${reportName}`, parameters);
  }
}
