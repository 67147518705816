import * as i0 from '@angular/core';
import { EventEmitter, Component, Input, Output, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@theevolvedgroup/component-library/icon';
import { IconModule } from '@theevolvedgroup/component-library/icon';
import { RouterModule } from '@angular/router';
class BreadcrumbsComponent {
  constructor() {
    this.breadcrumbs = [];
    this.clicked = new EventEmitter();
  }
  ngOnInit() {}
  click(breadcrumb) {
    this.clicked.emit(breadcrumb);
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: BreadcrumbsComponent,
      deps: [],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: BreadcrumbsComponent,
      selector: "teg-breadcrumbs",
      inputs: {
        breadcrumbs: "breadcrumbs"
      },
      outputs: {
        clicked: "clicked"
      },
      ngImport: i0,
      template: "<div class=\"breadcrumbs-container\">\n    <ng-container *ngFor=\"let breadcrumb of breadcrumbs; let i = index; last as isLast\">\n        <a *ngIf=\"!isLast; else currentPage\" class=\"breadcrumb-link\" (click)=\"click(breadcrumb)\">{{breadcrumb.label}}</a>\n        <ng-template #currentPage>\n            <span class=\"breadcrumb-current-page\">{{breadcrumb.label}}</span>\n        </ng-template>\n\n        <teg-icon class=\"breadcrumb-chevron\" *ngIf=\"i < breadcrumbs.length - 1\" color=\"var(--theme-breadcrumb-separator-color)\">chevron_right</teg-icon>\n    </ng-container>\n</div>",
      styles: [".breadcrumbs-container{height:32px;display:flex;flex-direction:row;align-items:center;background-color:var(--theme-breadcrumb-background-color);font-size:var(--theme-breadcrumb-font-size);gap:1rem;padding-left:var(--theme-spacing-lg)}.breadcrumbs-container .breadcrumb-link{text-decoration:none;color:var(--theme-breadcrumb-text-color);cursor:pointer}.breadcrumbs-container .breadcrumb-current-page{color:var(--theme-breadcrumb-text-selected-color)}.breadcrumbs-container .breadcrumb-chevron{color:var(--theme-breadcrumb-separator-color)}\n"],
      dependencies: [{
        kind: "directive",
        type: i1.NgForOf,
        selector: "[ngFor][ngForOf]",
        inputs: ["ngForOf", "ngForTrackBy", "ngForTemplate"]
      }, {
        kind: "directive",
        type: i1.NgIf,
        selector: "[ngIf]",
        inputs: ["ngIf", "ngIfThen", "ngIfElse"]
      }, {
        kind: "component",
        type: i2.IconComponent,
        selector: "teg-icon",
        inputs: ["customClass", "icon", "outline", "color", "size", "variant", "library", "opticalSize", "iconWeight", "iconSrc", "svgIcon"]
      }]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: BreadcrumbsComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: "teg-breadcrumbs",
      template: "<div class=\"breadcrumbs-container\">\n    <ng-container *ngFor=\"let breadcrumb of breadcrumbs; let i = index; last as isLast\">\n        <a *ngIf=\"!isLast; else currentPage\" class=\"breadcrumb-link\" (click)=\"click(breadcrumb)\">{{breadcrumb.label}}</a>\n        <ng-template #currentPage>\n            <span class=\"breadcrumb-current-page\">{{breadcrumb.label}}</span>\n        </ng-template>\n\n        <teg-icon class=\"breadcrumb-chevron\" *ngIf=\"i < breadcrumbs.length - 1\" color=\"var(--theme-breadcrumb-separator-color)\">chevron_right</teg-icon>\n    </ng-container>\n</div>",
      styles: [".breadcrumbs-container{height:32px;display:flex;flex-direction:row;align-items:center;background-color:var(--theme-breadcrumb-background-color);font-size:var(--theme-breadcrumb-font-size);gap:1rem;padding-left:var(--theme-spacing-lg)}.breadcrumbs-container .breadcrumb-link{text-decoration:none;color:var(--theme-breadcrumb-text-color);cursor:pointer}.breadcrumbs-container .breadcrumb-current-page{color:var(--theme-breadcrumb-text-selected-color)}.breadcrumbs-container .breadcrumb-chevron{color:var(--theme-breadcrumb-separator-color)}\n"]
    }]
  }],
  ctorParameters: () => [],
  propDecorators: {
    breadcrumbs: [{
      type: Input
    }],
    clicked: [{
      type: Output
    }]
  }
});
class BreadcrumbsModule {
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: BreadcrumbsModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: BreadcrumbsModule,
      declarations: [BreadcrumbsComponent],
      imports: [CommonModule, IconModule, RouterModule],
      exports: [BreadcrumbsComponent]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: BreadcrumbsModule,
      imports: [CommonModule, IconModule, RouterModule]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: BreadcrumbsModule,
  decorators: [{
    type: NgModule,
    args: [{
      declarations: [BreadcrumbsComponent],
      imports: [CommonModule, IconModule, RouterModule],
      exports: [BreadcrumbsComponent],
      providers: []
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { BreadcrumbsComponent, BreadcrumbsModule };
