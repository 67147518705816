<div class="headerRow" style="display:flex;height:40px;margin-right:18px;">
    <div style="border:1px solid black;box-sizing: border-box;overflow: hidden;flex-grow:1;">
        Filter: <input type=text [(ngModel)]="patternMatch" (ngModelChange)="changed($event)"> ({{ users.length }} matching users)
        <br> Email
    </div>
    <div style="border:1px solid black;box-sizing: border-box;overflow: hidden;width:70px;text-align:center;">Login<br>Count</div>
    <div style="border:1px solid black;box-sizing: border-box;overflow: hidden;width:150px;text-align:center;">Last<br>Login</div>
    <div style="border:1px solid black;box-sizing: border-box;overflow: hidden;width:70px;text-align:center;">Fail<br>Count</div>
    <div style="border:1px solid black;box-sizing: border-box;overflow: hidden;width:70px;text-align:center;">Email<br>Conirmed</div>
</div>
<div *ngIf="loading" style="padding-top:40px;">Loading...</div>
<div class="userList" *ngIf="!loading" style="border:1px solid black;padding-top:40px;height:calc(100% - 82px)">
    <div class="bodyRows" style="height:100%;">
        <cdk-virtual-scroll-viewport itemSize="22" style='height:100%;overflow-y:scroll;'>
            <div *cdkVirtualFor="let user of users">
                <div style="display:flex;height:22px;">
                    <div style="border:1px solid black;box-sizing: border-box;flex-grow:1">{{ user.email }}</div>
                    <div style="border:1px solid black;box-sizing: border-box;width:70px;text-align: right;padding-right:5px;">{{ user.loginCount | number : '1.' }}</div>
                    <div style="border:1px solid black;box-sizing: border-box;width:150px;text-align: right;padding-right:5px;">{{ user.lastLoginDateTime | date : 'd MMM yy HH:mm' }}</div>
                    <div style="border:1px solid black;box-sizing: border-box;width:70px;text-align: right;padding-right:5px;">{{ user.accessFailedCount | number : '1.' }}</div>
                    <div style="border:1px solid black;box-sizing: border-box;width:70px;text-align: center;">
                        <input *ngIf="!user.updating" type="checkbox" [(ngModel)]="user.emailConfirmed" (ngModelChange)="onUserChange($event, user)">
                        <div *ngIf="user.updating">updating</div>
                    </div>
                </div>
            </div>
        </cdk-virtual-scroll-viewport>
    </div>
</div>
