import { DataService } from "../../services/data.service";
import { Component, OnInit } from "@angular/core";
import { forkJoin, Subject } from "rxjs";
import * as _ from "lodash";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "app-user-admin",
  templateUrl: "./user-admin.component.html",
  styleUrls: ["./user-admin.component.css"]
})
export class UserAdminComponent implements OnInit {
  users: any[] = [];
  loading = true;
  patternMatch = "%@theevolvedgroup.com";
  modelChanged: Subject<string> = new Subject<string>();
  constructor(public dataService: DataService) {
    this.loadData();
    this.modelChanged
      .pipe(
        debounceTime(1000), // wait 300ms after the last event before emitting last event
        distinctUntilChanged()
      ) // only emit if value is different from previous value
      .subscribe(() => this.loadData());
  }
  loadData() {
    this.loading = true;
    this.dataService
      .getUserListDetail(this.patternMatch)
      .subscribe((data: any) => {
        this.users = data;
        this.loading = false;
      });
  }

  ngOnInit() {}
  onChange(event, user, application) {
    // user.licenses[application.applicationId].updating = true;
    // if (event.target.checked) {
    //   this.dataService.userLicenseAdd(user, application).subscribe(data => {
    //     user.licenses[application.applicationId].checked = true;
    //     user.licenses[application.applicationId].updating = false;
    //   });
    // } else {
    //   this.dataService.userLicenseRemove(user, application).subscribe(data => {
    //     user.licenses[application.applicationId].checked = false;
    //     user.licenses[application.applicationId].updating = false;
    //   });
    // }
  }
  onUserChange(event, user) {
    user.updating = true;
    this.dataService.userUpdate(user).subscribe(data => {
      user = _.merge(user, data);
      user.updating = false;
    });
  }
  changed(event) {
    this.modelChanged.next(event);
  }
}
