import { Component, Input, OnInit } from '@angular/core';
import { ColumnModel } from '@syncfusion/ej2-angular-grids';
import { Action } from '@theevolvedgroup/component-library/action-bar/types';
import { ContentTypeGuids, ContentTypeIds, ShareByEmail, ShareRole } from 'src/app/services/app-state.model';
import { AppStateService } from 'src/app/services/app-state.service';
import * as _ from 'lodash';
import { FieldSettingsModel } from '@syncfusion/ej2-dropdowns';
import { DataService } from 'src/app/services/data.service';
import { DialogFactoryService } from '@theevolvedgroup/component-library/dialog-base';
import { DialogCloseResult } from '@theevolvedgroup/component-library/dialog-base/types';

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrl: './share-dialog.component.less'
})
export class ShareDialogComponent implements OnInit {
  @Input() shareByEmails: ShareByEmail[] = [];
  @Input() contentTypeGuid: string;
  @Input() contentItemGuid: string;

  private EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  shareRoles: ShareRole[] = [];
  newShares: ShareByEmail[] = [];
  dataSource: Partial<ShareByEmail>[] = [];

  newShare: ShareByEmail = {
    shareByEmailId: null,
    emailAddress: "",
    shareRoleId: null,
    isShareByEmail: true
  };

  shareRoleFields: FieldSettingsModel = { text: "shareRoleName", value: "shareRoleId" };

  shareColumns: ColumnModel[] = [
    {
      field: "emailAddress",
      headerText: "Email",
      width: "70%",
      type: "string",
      allowFiltering: true,
      allowSorting: true
    },
    {
      field: "shareRoleName",
      headerText: "Role",
      width: "30%",
      type: "string",
      allowFiltering: true,
      allowSorting: true
    }
  ];

  shareActions: Action[] = [
    {
      icon: "delete",
      tooltip: "Delete",
      outline: false,
      color: "var(--theme-table-action-color)",
      action: { name: "delete" },
    },
  ];

  constructor(private appState: AppStateService,
    private dataService: DataService,
    private dialogFactoryService: DialogFactoryService) { }

  ngOnInit() {
    this.resolveShareRoles();
    this.buildDataSource();
  }

  buildDataSource() {
    this.dataSource = _.orderBy(this.shareByEmails.map(share => {
      return {
        emailAddress: share.emailAddress,
        shareRoleName: _.find(this.shareRoles, { shareRoleId: share.shareRoleId }).shareRoleName
      };
    }), ["emailAddress"], ["asc"]);

    this.dataSource = [...this.dataSource];
  }

  resolveShareRoles() {
    if (!this.contentTypeGuid || this.contentTypeGuid === "") {
      return;
    }

    let defaultShareRole = _.find(this.shareRoles, { isDefault: true });
    this.shareRoles = _.filter(_.clone(this.appState.shareRoles), { contentTypeGuid: this.contentTypeGuid });
    this.newShare.shareRoleId = (defaultShareRole) ? defaultShareRole.shareRoleId : this.shareRoles[0].shareRoleId;
    this.shareRoles = [...this.shareRoles];
  }

  onShareActionClicked(event: any) {
    if (event.action && event.action.name == "delete") {
      this.deleteShare(event.item.emailAddress);
    }
  }

  getContentTypeId(contentItemGuid: string): number {
    switch (contentItemGuid) {
      case ContentTypeGuids.Account:
        return ContentTypeIds.Account;
      case ContentTypeGuids.SecurityGroup:
        return ContentTypeIds.SecurityGroup;
      case ContentTypeGuids.Survey:
        return ContentTypeIds.Survey;
      default:
        return
    }
  }

  deleteShare(emailAddress: string) {
    if (this.shareByEmails.length == 1) {
      let headerText = "Delete Share";
      let message = "At least one share must exist.";
      let buttons = [{ id: "ok", colorType: "error", caption: "OK" }];
      this.dialogFactoryService.openMessageDialog({ headerText, contentComponentProperties: { message }, buttons, headerColor: "error" });
      return;
    }

    let shareByEmailId = _.find(this.shareByEmails, { emailAddress }).shareByEmailId;
    const headerText = "Delete Share";
    let message = "Are you sure you want to delete this share?";
    let buttons = [
      { id: "no", colorType: "secondary", caption: "CANCEL" },
      { id: "yes", colorType: "warn", caption: "YES" }
    ];

    let dialog = this.dialogFactoryService.openMessageDialog({ headerText, contentComponentProperties: { message }, buttons, headerColor: "warn" });

    dialog.closed$.subscribe((result: DialogCloseResult) => {
      if (result.button.id == 'yes') {
        this.appState.dataLoaded = false;
        this.dataService.shareByEmailDelete(shareByEmailId).subscribe((response: any) => {
          this.appState.dataLoaded = true;

          _.remove(this.shareByEmails, { emailAddress: emailAddress });
          this.shareByEmails = [...this.shareByEmails];
          this.buildDataSource();

          let headerText = "Delete Share";
          let message = "Share deleted successfully.";
          let buttons = [{ id: "ok", colorType: "primary", caption: "OK" }];
          this.dialogFactoryService.openMessageDialog({ headerText, contentComponentProperties: { message }, buttons });
        });
      }
    });
  }

  onAddShareClicked() {
    if (this.EMAIL_REGEX.test(this.newShare.emailAddress) === false) {
      let headerText = "Add Share";
      let message = "Invalid email address.";
      let buttons = [{ id: "ok", colorType: "error", caption: "OK" }];
      this.dialogFactoryService.openMessageDialog({ headerText, contentComponentProperties: { message }, buttons, headerColor: "error" });
      return;
    }

    let existingShare = _.find(this.shareByEmails, { emailAddress: this.newShare.emailAddress });

    if (existingShare) {
      let headerText = "Add Share";
      let message = "Email address already exists.";
      let buttons = [{ id: "ok", colorType: "error", caption: "OK" }];
      this.dialogFactoryService.openMessageDialog({ headerText, contentComponentProperties: { message }, buttons, headerColor: "error" });
      return;
    }

    let ownerShareRole = _.find(this.shareRoles, { shareRoleName: "Is Owner" });
    if (ownerShareRole != null) {
      let ownerShare = _.find(this.shareByEmails, { shareRoleId: (ownerShareRole as ShareRole).shareRoleId });

      if (ownerShare != null && this.newShare.shareRoleId == (ownerShareRole as ShareRole).shareRoleId) {
        let headerText = "Add Share";
        let message = "Owner already exists.";
        let buttons = [{ id: "ok", colorType: "error", caption: "OK" }];
        this.dialogFactoryService.openMessageDialog({ headerText, contentComponentProperties: { message }, buttons, headerColor: "error" });
        return;
      }
    }

    this.shareByEmails.push(_.cloneDeep(this.newShare));

    this.appState.dataLoaded = false;
    this.dataService.shareByEmailAdd(this.getContentTypeId(this.contentTypeGuid), this.contentItemGuid, this.newShare.shareRoleId, this.newShare.emailAddress)
      .subscribe((response: any) => {
        this.appState.dataLoaded = true;
        let headerText = "Add Share";
        let message = "Share added successfully.";
        let buttons = [{ id: "ok", colorType: "primary", caption: "OK" }];
        this.dialogFactoryService.openMessageDialog({ headerText, contentComponentProperties: { message }, buttons });
      });

    this.newShare = {
      shareByEmailId: null,
      emailAddress: "",
      shareRoleId: null,
      isShareByEmail: true
    };
    this.shareByEmails = [...this.shareByEmails];

    this.buildDataSource();
    this.resolveShareRoles();
  }

  onRoleChanged(role: ShareRole) {

    //this.newShare.shareRoleId = role.shareRoleId

  }
}
