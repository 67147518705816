import { __decorate, __param } from 'tslib';
import { Optional, Self } from '@angular/core';
import * as _ from 'lodash';
function getColorConfig(color, defaultTheme) {
  let customColor = undefined;
  let theme = undefined;
  if (color) {
    switch (color) {
      case "primary":
      case "secondary":
      case "accent-1":
      case "accent-2":
      case "accent-3":
      case "accent-4":
      case "info":
      case "success":
      case "warn":
      case "error":
        theme = color;
        break;
      default:
        customColor = color;
        break;
    }
  } else {
    theme = defaultTheme;
  }
  return {
    theme,
    customColor
  };
}
const MOUSE_BUTTON = {
  /** Primary action (usually left button) */
  PRIMARY: 0,
  /** Auxilliary action (usually wheel button or middle button) */
  AUXILLIARY: 1,
  /** Secondary action (usually right button) */
  SECONDARY: 2
};
let CustomControlValueAccessor = class CustomControlValueAccessor {
  constructor(ngControl) {
    this.ngControl = ngControl;
    /** ControlValueAccessor onChange Event
     * - Invoked when the model has been changed
     */
    this._onChange = () => {};
    /** ControlValueAccessor onTouched Event
     * - Invoked when the model has been touched
     */
    this._onTouched = () => {};
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  writeValue(value) {
    this.value = value == null ? "" : value;
  }
  registerOnChange(fn) {
    this._onChange = fn;
  }
  registerOnTouched(fn) {
    this._onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
};
CustomControlValueAccessor = __decorate([__param(0, Optional()), __param(0, Self())], CustomControlValueAccessor);

/** DragDropHelper utility class. */
class DragDropHelper {
  /**
   * Take items from the source list and copy to target list. Removes dropped items from source list.
   * @param sourceList The list data of the source container.
   * @param targetList The list data of the target container.
   * @param event The drag drop event.
   * @param selectedFieldName The name of the field to determine selected state. Defaults to 'isSelected'.
   * @param uniqueFieldName The name of the field to determine uniqueness. Default to 'variableName'.
   * @param allowDuplicate Whether to allow variables to be copied to target list when it already exists in the target list. Defaults to false;
   * @param mapCallback A function that is called to map sourceList items to targetList items. Defaults to null;
   * @returns The state of the provided lists after performing drag drop.
   */
  static take(sourceList, targetList, event, selectedFieldName = "isSelected", uniqueFieldName = "variableName", allowDuplicate = false, mapCallback = null) {
    const copyDropState = DragDropHelper.copy(sourceList, targetList, event, selectedFieldName, uniqueFieldName, allowDuplicate, mapCallback);
    const availableList = _.pullAllBy(copyDropState.sourceList, copyDropState.targetList, uniqueFieldName);
    const resultState = {
      sourceList: availableList,
      targetList: copyDropState.targetList
    };
    return resultState;
  }
  /**
   * Copies items from the source list to the target list.
   * @param sourceList The list data of the source container.
   * @param targetList The list data of the target container.
   * @param event The drag drop event.
   * @param selectedFieldName The name of the field to determine selected state. Defaults to 'isSelected'.
   * @param uniqueFieldName The name of the field to determine uniqueness. Default to 'variableName'.
   * @param allowDuplicate Whether to allow variables to be copied to target list when it already exists in the target list. Defaults to false;
   * @param mapCallback A function that is called to map sourceList items to targetList items. Defaults to null;
   * @returns The state of the provided lists after performing drag drop.
   */
  static copy(sourceList, targetList, event, selectedFieldName = "isSelected", uniqueFieldName = "variableName", allowDuplicate = false, mapCallback = null) {
    const itemIndex = parseInt(event.item.element.nativeElement.dataset["index"], 10);
    const droppedItem = event.previousContainer.data[itemIndex];
    let dropzoneList = targetList;
    // One or Many selected tiles have been dropped in
    if (droppedItem[selectedFieldName]) {
      const droppedSelection = _.filter(event.previousContainer.data, selectedFieldName);
      const clonedItems = _.cloneDeep(droppedSelection);
      dropzoneList = dropzoneList.concat(clonedItems);
    } else {
      // Just one unselected tile has been dropped in
      const clonedItem = _.cloneDeep(droppedItem);
      dropzoneList = dropzoneList.concat([clonedItem]);
    }
    if (!allowDuplicate) {
      dropzoneList = _.uniqBy(dropzoneList, uniqueFieldName);
    }
    if (mapCallback) {
      targetList = dropzoneList.map(mapCallback);
    } else {
      targetList = dropzoneList;
    }
    const resultState = {
      sourceList,
      targetList
    };
    return resultState;
  }
  /**
   * Move one or more items after performing a drag drop.
   * @param sourceList List of all items.
   * @param filteredList List of all visible items after filtering.
   * @param event The drag drop event.
   * @param selectedItems The items selected while performing drag drop event.
   * @param identifiedField The field to uniquely identify an item.
   * @returns The new sorted order of all items.
   */
  static move(sourceList, filteredList, event, selectedItems, identifiedField) {
    /** Sorting */
    let sortedItems = [event.item.data];
    let targetIndex = _.findIndex(sourceList, item => _.isEqual(item[identifiedField], filteredList[event.currentIndex][identifiedField]));
    if (selectedItems.length > 1) {
      const selectedItemsClone = _.cloneDeep(selectedItems);
      _.pullAllBy(sourceList, selectedItemsClone, identifiedField);
      sortedItems = selectedItemsClone;
      const shift = event.previousIndex < event.currentIndex;
      if (shift) {
        targetIndex = targetIndex - (selectedItemsClone.length - 1);
      }
    } else {
      const removeIndex = _.findIndex(sourceList, item => _.isEqual(item, event.item.data));
      sourceList.splice(removeIndex, 1);
    }
    const insertIndex = targetIndex < 0 ? 0 : targetIndex;
    sourceList = [...sourceList.slice(0, insertIndex), ...sortedItems, ...sourceList.slice(insertIndex)].map((item, index) => {
      item.customSortOrder = index;
      return item;
    });
    return sourceList;
  }
}
const htmlDecode = html => {
  var doc = new DOMParser().parseFromString(html, "text/html");
  return doc.documentElement.textContent;
};

/**
 * Generated bundle index. Do not edit.
 */

export { CustomControlValueAccessor, DragDropHelper, MOUSE_BUTTON, getColorConfig, htmlDecode };
