/* src/app/components/overview/overview.component.less */
.content-section-wrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: middle;
}
.content-section-wrapper .filter-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: end;
}
.content-section-wrapper .filter-container .filter {
  flex-basis: 200px;
}
.no-content-message {
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: var(--theme-h2-font-size);
  color: var(--theme-section-header-color);
  font-weight: 400;
}
/*# sourceMappingURL=overview.component.css.map */
