import { Injectable, Inject } from '@angular/core';
import * as _ from 'lodash';
import { DOCUMENT } from '@angular/common';
import {
  Router,
  RouteConfigLoadStart,
  RouteConfigLoadEnd
} from '@angular/router';
import { ClientAccount, ClientSubAccount, ShareRole, UserProfile } from './app-state.model';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class AppStateService {
  clientAccounts: ClientAccount[];
  clientSubAccounts: any[];
  shareRoles: ShareRole[];
  data: any;
  dataLoaded = false;
  isAuthorized = false;
  userProfile: UserProfile;
  showForbidden = false;
  forbiddenMessage = '';
  forbiddenImageUrl = '';
  isAuthorizedSubscription: any;
  showArchived = false;
  showDeleted = false;
  clientConfiguration: any;

  public static readonly DefaultUserAvatarUrl = '/assets/images/user_logo.png';

  private readonly _currentAccount = new BehaviorSubject<ClientAccount>(null);
  readonly currentAccount$ = this._currentAccount.asObservable();

  get currentAccount(): ClientAccount {
    return this._currentAccount.getValue();
  }

  set currentAccount(account: ClientAccount) {
    this._currentAccount.next(account);
  }

  private readonly _currentSubAccount = new BehaviorSubject<ClientSubAccount>(null);
  readonly currentSubAccount$ = this._currentSubAccount.asObservable();

  get currentSubAccount(): ClientSubAccount {
    return this._currentSubAccount.getValue();
  }

  set currentSubAccount(account: ClientSubAccount) {
    this._currentSubAccount.next(account);
  }

  private readonly _breadcrumbs = new BehaviorSubject<any[]>([]);
  readonly breadcrumbs$ = this._breadcrumbs.asObservable();

  get breadcrumbs(): any[] {
    return this._breadcrumbs.getValue();
  }

  set breadcrumbs(breadcrumbs: any[]) {
    this._breadcrumbs.next(breadcrumbs);
  }

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: any,
    private dialog: MatDialog,) {
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        // console.log("AppState - RouteConfigLoadStart");
      } else if (event instanceof RouteConfigLoadEnd) {
        // console.log("AppState - RouteConfigLoadEnd");
      }
    });
  }

  public IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  public uuidv4() {
    return ('' + [1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, ch => {
      let c = Number(ch);
      return (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16);
    });
  }
}
