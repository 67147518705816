import { HttpHeaders } from "@angular/common/http";
import {
    Component, Inject, NgZone, OnInit, ViewChild, Input
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Cloudinary } from "@theevolvedgroup/component-library/cloudinary";
import { OidcSecurityService } from "angular-auth-oidc-client";
import * as _ from "lodash";
import {
    FileUploader,
    FileUploaderOptions,
    ParsedResponseHeaders
} from "ng2-file-upload";
//import { AppStateService } from "../../services/app-state.service";
import { DataService } from '../../../services/data.service';


interface ClientAccount {
    clientAccountGuid?: string;
    clientAccountName: string;
    clientAccountVanityName: string;
    clientAccountIconUrl: string;
    config: any;
    configJson: string; // NOTE: The C# is stupid and relies on the json to deserialize rather than using the dynamic
    clientAccountLicense:any;

}

@Component({
    selector: "account-dialog",
    templateUrl: "./account-dialog.component.html",
    styleUrls: ["./account-dialog.component.css"],
})
export class AccountDialogComponent implements OnInit {

    loading = false;
    account: ClientAccount;
    response: any;
    uploader: FileUploader;
    iconUrlEditing: boolean = false;
    accountNameDebounce: any;
    clientAccountNameDuplicated = false;
    @ViewChild("clientAccountName", { static: true })
    clientAccountName: UntypedFormControl;
    saving = false;

    constructor(
        private oidcSecurityService: OidcSecurityService,
        private dataService: DataService,
        private cloudinary: Cloudinary,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<AccountDialogComponent>,
        private zone: NgZone
    ) { }

    ngOnInit() {
        this.loading = true;
        this.initUploader();
        if (this.data.mode == "Add") {
            // this.account = {
            //     clientAccountName: "",
            //     clientAccountVanityName: "",
            //     clientAccountIconUrl:
            //         "https://res.cloudinary.com/twistoflime/Portal/Site/HumanListening_Icon.svg",
            //     config: {
            //         hideTitle: false
            //     },
            //     configJson: '',
            //     clientAccountLicense:{}
            // };
            // this.loading = false;
        } else if (this.data.mode == "Edit") {
            this.dataService.getGetAccountByGuid(this.data.clientAccount.clientAccountGuid).subscribe(
                (acc) => {
                    this.account = {
                        clientAccountGuid: acc.clientAccountGuid,
                        clientAccountName: acc.clientAccountName,
                        clientAccountVanityName: acc.clientAccountVanityName,
                        clientAccountIconUrl: acc.clientAccountIconUrl,
                        config: acc.config,
                        configJson: '',
                        clientAccountLicense:acc.clientAccountLicense
                    };
                    this.loading = false;
                },
                (error) => {
                    console.log(error);
                    alert(error)
                },
                () => {
                    this.loading = false;
                }
            );

        }
        this.accountNameDebounce = _.debounce(
            (accountName) => {
                this.checkAccountName(accountName);
            },
            500,
            {}
        );
    }

    onAccountNameChanged(event: any) {
        //console.log(event.target.value)
        // this.clientAccountNameDuplicated = false;
        // this.accountNameDebounce(event.target.value);
        this.account.clientAccountVanityName = event.target.value
            .replace(/[ ,.]/g, "-")
            .replace(/\-+/g, "-");
        if (this.account.clientAccountVanityName.endsWith("-")) {
            this.account.clientAccountVanityName = this.account.clientAccountVanityName.slice(
                0,
                -1
            );
        }
    }

    private checkAccountName(accountName: string) {
        // TODO REPLACE CHARS
        // this.accountVanityName = accountName;
        if (accountName === "" || this.clientAccountName.errors) {
            return;
        }

        // this.appState.checkAccountNameExist(accountName).subscribe((exists: boolean) => {
        //     this.clientAccountNameDuplicated = exists;
        //     // this.checkAccountVanityName(this.accountVanityName);
        // });
    }

    save() {
        this.saving = true;
        if (this.data.mode == "Edit") {
            this.account.configJson = JSON.stringify(this.account.config); // NOTE: The C# is stupid and relies on the json to deserialize rather than using the dynamic
            this.dataService.updateAccount(this.account).subscribe(
                (data) => {
                    this.dialogRef.close(data);
                },
                (error) => {
                    this.saving = false;
                    if (error.status === 400 && error.error) {
                        console.log(error);
                        alert("Error - " + error.error);
                        return;
                    }
                    if (error.status === 500) {
                        console.log(error);
                        alert(
                            "Error - An unexpected error occurred. Please contact support."
                        );
                        return;
                    }
                    // this.oidcSecurityService.handleError(error);
                },
                () => {
                    //console.log('Edit Account completed')
                }
            );
        }
    }

    cancel() {
        this.dialogRef.close();
    }
    // Delete an uploaded image
    // Requires setting "Return delete token" to "Yes" in your upload preset configuration
    // See also https://support.cloudinary.com/hc/en-us/articles/202521132-How-to-delete-an-image-from-the-client-side-
    deleteImage = function (data: any, index: number) {
        // We have 10 minutes to delete the image
        const url = `https://api.cloudinary.com/v1_1/${this.cloudinary.config().cloud_name
            }/delete_by_token`;
        let headers = new HttpHeaders({
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
        });
        const body = {
            token: data.delete_token,
        };
        this.http
            .post(url, body, { headers: headers })
            .toPromise()
            .then((response) => {
                console.log(
                    `Deleted image - ${data.public_id} ${response.json().result
                    }`
                );
                // Remove deleted item for responses
                this.response = null;
                //this.responses.splice(index, 1);
            })
            .catch((err: any) => {
                console.log(`Failed to delete image ${data.public_id} ${err}`);
            });
    };
    private initUploader() {
        // Create the file uploader, wire it to upload to your account
        const uploaderOptions: FileUploaderOptions = {
            url: `https://api.cloudinary.com/v1_1/${this.cloudinary.config().cloud_name
                }/upload`,
            // Upload files automatically upon addition to upload queue
            autoUpload: true,
            // Use xhrTransport in favor of iframeTransport
            isHTML5: true,
            // Calculate progress independently for each uploaded file
            removeAfterUpload: true,
            // XHR request headers
            headers: [
                {
                    name: "X-Requested-With",
                    value: "XMLHttpRequest",
                },
            ],
        };
        this.uploader = new FileUploader(uploaderOptions);

        this.uploader.onBuildItemForm = (
            fileItem: any,
            form: FormData
        ): any => {
            // Add Cloudinary's unsigned upload preset to the upload form
            form.append(
                "upload_preset",
                this.cloudinary.config().upload_preset
            );
            // Add built-in and custom tags for displaying the uploaded photo in the list
            form.append(
                "folder",
                "/Portal/Clients/" + this.data.clientAccount.clientAccountGuid
            );
            form.append("public_id", fileItem.file.name);
            form.append("file", fileItem);

            // Use default "withCredentials" value for CORS requests
            fileItem.withCredentials = false;
            return { fileItem, form };
        };

        // Insert or update an entry in the responses array
        const upsertResponse = (fileItem) => {
            // Run the update in a custom zone since for some reason change detection isn't performed
            // as part of the XHR request to upload the files.
            // Running in a custom zone forces change detection
            this.zone.run(() => {
                // Update an existing entry if it's upload hasn't completed yet

                //// Find the id of an existing item
                //const existingId = this.responses.reduce((prev, current, index) => {
                //    if (current.file.name === fileItem.file.name && !current.status) {
                //        return index;
                //    }
                //    return prev;
                //}, -1);
                //if (existingId > -1) {
                //    // Update existing item with new data
                //    this.responses[existingId] = Object.assign(this.responses[existingId], fileItem);
                //} else {
                //    // Create new response
                //    this.responses.push(fileItem);
                //}

                // We only allow single file uploads
                this.response = fileItem;
                if (this.response.data.secure_url != null) {
                    let imageUrl = this.response.data.secure_url.replace(
                        "https:",
                        ""
                    );
                    this.account.clientAccountIconUrl = imageUrl;
                }
            });
        };

        // Update model on completion of uploading a file
        this.uploader.onCompleteItem = (
            item: any,
            response: string,
            status: number,
            headers: ParsedResponseHeaders
        ) =>
            upsertResponse({
                file: item.file,
                status,
                data: JSON.parse(response),
            });

        // Update model on upload progress event
        this.uploader.onProgressItem = (fileItem: any, progress: any) =>
            upsertResponse({
                file: fileItem.file,
                progress,
                data: {},
            });
    }
}
