/* src/app/components/metrics/report/metrics-report.component.less */
.report-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 24px 0px 24px;
}
.report-container .report-header-container {
  display: flex;
  flex-direction: row;
}
.report-container .report-header-container .title-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.report-container .report-header-container .title-container .title {
  font-size: var(--theme-h2-font-size);
  color: var(--theme-section-header-color);
  font-weight: 500;
  text-transform: uppercase;
}
.report-container .report-header-container .title-container .subtitle {
  font-size: var(--theme-h4-font-size);
  color: var(--theme-section-header-color);
  font-weight: 400;
}
.report-container .report-header-container .actions-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-left: auto;
  justify-content: right;
  align-items: center;
}
/*# sourceMappingURL=metrics-report.component.css.map */
