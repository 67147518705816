/* src/app/components/dialogs/account-dialog/account-dialog.component.css */
.mat-mdc-dialog-actions button {
  word-spacing: 5px;
  color: #eee !important;
  text-transform: uppercase;
  margin: 0 .5em 0 8px;
  padding: 0 1.5em;
}
.mat-mdc-dialog-actions button[color=primary] {
  background-color: #2093cc;
}
.mat-mdc-dialog-actions button[color=secondary] {
  background-color: #999;
}
.mat-mdc-dialog-actions button[disabled] {
  background-color: #bbb;
}
/*# sourceMappingURL=account-dialog.component.css.map */
