import * as i0 from '@angular/core';
import { Component, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
class ProgressIndicatorComponent {
  constructor() {
    this.percent = 0;
  }
  ngOnInit() {}
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: ProgressIndicatorComponent,
      deps: [],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: ProgressIndicatorComponent,
      selector: "teg-progress-indicator",
      inputs: {
        percent: "percent"
      },
      ngImport: i0,
      template: "<div class=\"liquid-fill-container\">\n    <h2 class=\"liquid-fill-number\">{{percent}}%</h2>\n</div>\n",
      styles: [".liquid-fill-container{position:relative;background:#aaede8;border:.5px solid var(--theme-color-grey-0);box-sizing:border-box;height:100%;width:100%;outline:0;overflow:hidden;border-radius:50%;display:flex;align-items:center;justify-content:center}.liquid-fill-container .liquid-fill-number{position:absolute;color:#000;font-weight:500;font-size:var(--theme-body-font-size-sm)}.liquid-fill-container .image{height:90%;width:90%}.liquid-fill-container:before{content:\"\";color:var(--theme-color-grey-0);height:100%;width:100%;display:flex;align-items:center;justify-content:center}.liquid-fill-container:after{content:\"\";position:absolute;height:200%;width:200%;box-sizing:border-box;background-color:var(--theme-color-grey-30);border-radius:38%;opacity:.9;animation:spin 6s linear infinite;animation-direction:alternate}@keyframes spin{0%{transform:translateY(0) rotate(0)}to{transform:translateY(-100%) rotate(360deg)}}\n"]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: ProgressIndicatorComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: "teg-progress-indicator",
      template: "<div class=\"liquid-fill-container\">\n    <h2 class=\"liquid-fill-number\">{{percent}}%</h2>\n</div>\n",
      styles: [".liquid-fill-container{position:relative;background:#aaede8;border:.5px solid var(--theme-color-grey-0);box-sizing:border-box;height:100%;width:100%;outline:0;overflow:hidden;border-radius:50%;display:flex;align-items:center;justify-content:center}.liquid-fill-container .liquid-fill-number{position:absolute;color:#000;font-weight:500;font-size:var(--theme-body-font-size-sm)}.liquid-fill-container .image{height:90%;width:90%}.liquid-fill-container:before{content:\"\";color:var(--theme-color-grey-0);height:100%;width:100%;display:flex;align-items:center;justify-content:center}.liquid-fill-container:after{content:\"\";position:absolute;height:200%;width:200%;box-sizing:border-box;background-color:var(--theme-color-grey-30);border-radius:38%;opacity:.9;animation:spin 6s linear infinite;animation-direction:alternate}@keyframes spin{0%{transform:translateY(0) rotate(0)}to{transform:translateY(-100%) rotate(360deg)}}\n"]
    }]
  }],
  ctorParameters: () => [],
  propDecorators: {
    percent: [{
      type: Input
    }]
  }
});
class ProgressIndicatorModule {
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: ProgressIndicatorModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: ProgressIndicatorModule,
      declarations: [ProgressIndicatorComponent],
      imports: [CommonModule],
      exports: [ProgressIndicatorComponent]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: ProgressIndicatorModule,
      imports: [CommonModule]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: ProgressIndicatorModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [ProgressIndicatorComponent],
      declarations: [ProgressIndicatorComponent],
      providers: []
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { ProgressIndicatorComponent, ProgressIndicatorModule };
