import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from 'rxjs';
import { AppStateService } from "./services/app-state.service";
import { DataService } from "./services/data.service";
import _ from "lodash";

@Injectable()
export class AccountResolver {
    constructor(
        private router: Router,
        public appState: AppStateService,
        public dataService: DataService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> {
        return new Observable((observer) => {
            var account = decodeURI(route.params.account);
            var subAccount = decodeURI(route.params.subAccount);

            // Home
            if (account == "undefined") {
                this.appState.currentAccount = null;
                this.appState.currentSubAccount = null;

                this.appState.dataLoaded = false;
                this.dataService.getClientAccounts().subscribe((response: any) => {
                    this.appState.clientAccounts = response.data.clientAccounts;
                    this.appState.dataLoaded = true;

                    this.appState.breadcrumbs = [{ label: 'Home', url: '/' }];

                    observer.next();
                    observer.complete();
                });

            }
            // /:account
            else if (account != "undefined" && subAccount == "undefined") {
                this.appState.currentSubAccount = null;

                if (!this.appState.clientAccounts || this.appState.clientAccounts.length == 0) {
                    this.appState.dataLoaded = false;
                    this.dataService.getClientAccounts().subscribe((response: any) => {
                        this.appState.clientAccounts = response.data.clientAccounts;
                        this.appState.dataLoaded = true;

                        this.appState.currentAccount = _.find(this.appState.clientAccounts, { clientAccountVanityName: account });


                        this.appState.dataLoaded = false;
                        this.dataService.getClientSubAccounts(this.appState.currentAccount.clientAccountId).subscribe((response: any) => {
                            this.appState.currentAccount.subAccounts = response.data.clientSubAccounts;
                            this.appState.dataLoaded = true;

                            this.appState.breadcrumbs = [{ label: 'Home', url: '/' }, { label: this.appState.currentAccount.clientAccountName, url: account }];

                            observer.next();
                            observer.complete();
                        });
                    });
                } else if (!this.appState.currentAccount.subAccounts || this.appState.currentAccount.subAccounts.length == 0) {
                    this.appState.dataLoaded = false;
                    this.dataService.getClientSubAccounts(this.appState.currentAccount.clientAccountId).subscribe((response: any) => {
                        this.appState.currentAccount.subAccounts = response.data.clientSubAccounts;
                        this.appState.dataLoaded = true;

                        this.appState.currentAccount = _.find(this.appState.clientAccounts, { clientAccountVanityName: account });
                        this.appState.breadcrumbs = [{ label: 'Home', url: '/' }, { label: this.appState.currentAccount.clientAccountName, url: account }];

                        observer.next();
                        observer.complete();
                    });

                } else {
                    this.appState.currentAccount = _.find(this.appState.clientAccounts, { clientAccountVanityName: account });
                    this.appState.breadcrumbs = [{ label: 'Home', url: '/' }, { label: this.appState.currentAccount.clientAccountName, url: account }];

                    observer.next();
                    observer.complete();
                }
            }
            // /:account/:subAccount
            else if (account != "undefined" && subAccount != "undefined") {
                if (!this.appState.clientAccounts || this.appState.clientAccounts.length == 0) {
                    this.appState.dataLoaded = false;
                    this.dataService.getClientAccounts().subscribe((response: any) => {
                        this.appState.clientAccounts = response.data.clientAccounts;
                        this.appState.dataLoaded = true;

                        this.appState.currentAccount = _.find(this.appState.clientAccounts, { clientAccountVanityName: account });

                        this.appState.dataLoaded = false;
                        this.dataService.getClientSubAccounts(this.appState.currentAccount.clientAccountId).subscribe((response: any) => {
                            this.appState.currentAccount.subAccounts = response.data.clientSubAccounts;
                            this.appState.dataLoaded = true;

                            this.appState.currentSubAccount = _.find(this.appState.currentAccount.subAccounts, { clientSubAccountName: subAccount });
                            this.appState.breadcrumbs = [
                                { label: 'Home', url: '/' },
                                { label: this.appState.currentAccount.clientAccountName, url: account },
                                { label: this.appState.currentSubAccount.clientSubAccountName, url: account + '/' + subAccount }
                            ];

                            observer.next();
                            observer.complete();
                        });
                    });
                }
                else if (!this.appState.currentAccount.subAccounts || this.appState.currentAccount.subAccounts.length == 0) {
                    this.appState.dataLoaded = false;
                    this.dataService.getClientSubAccounts(this.appState.currentAccount.clientAccountId).subscribe((response: any) => {
                        this.appState.currentAccount.subAccounts = response.data.clientSubAccounts;
                        this.appState.dataLoaded = true;

                        this.appState.currentSubAccount = _.find(this.appState.currentAccount.subAccounts, { clientSubAccountName: subAccount });
                        this.appState.breadcrumbs = [
                            { label: 'Home', url: '/' },
                            { label: this.appState.currentAccount.clientAccountName, url: account },
                            { label: this.appState.currentSubAccount.clientSubAccountName, url: account + '/' + subAccount }
                        ];

                        observer.next();
                        observer.complete();
                    });

                }
                else {
                    this.appState.currentAccount = _.find(this.appState.clientAccounts, { clientAccountVanityName: account });
                    this.appState.currentSubAccount = _.find(this.appState.currentAccount.subAccounts, { clientSubAccountName: subAccount });
                    this.appState.breadcrumbs = [
                        { label: 'Home', url: '/' },
                        { label: this.appState.currentAccount.clientAccountName, url: account },
                        { label: this.appState.currentSubAccount.clientSubAccountName, url: account + '/' + subAccount }
                    ];

                    observer.next();
                    observer.complete();
                }
            }
        });
    }
}
