import { Component } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent {
  constructor(public oidcSecurityService: OidcSecurityService) {
    this.oidcSecurityService.authorize();
    // for future use, this can allow you to authorize in a popup so you don't need to unload/reload the angular app.
    // this.oidcSecurityService.authorize(authUrl => {
    //   // handle the authorrization URL
    //   window.open(authUrl, '_blank', 'toolbar=0,location=0,menubar=0');
    // });
  }
}
