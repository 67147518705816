import { DataService } from '../../services/data.service';
import { Component } from '@angular/core';
import { AppStateService } from '../../services/app-state.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { SubAccountDialogComponent } from '../dialogs/sub-account-dialog/sub-account-dialog.component';
import { AccountDialogComponent } from '../dialogs/account-dialog/account-dialog.component';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ActionType, DisplayType, MenuOptionsType } from '@theevolvedgroup/component-library/action-bar/types';

interface ClientAccount {
  clientAccountName: string;
  clientAccountId: number;
  clientAccountGuid: string;
  clientAccountVanityName: string;
  clientSubAccounts: ClientSubAccount[];
  shareByEmails: ShareByEmail[];
  isClientAccount: boolean;
  dataLoaded: boolean;
  isLoading: boolean;
}
interface ClientSubAccount {
  clientSubAccountName: string;
  clientSubAccountId: number;
  clientSubAccountGuid: string;
  clientAccountId: number;
  shareByEmails: ShareByEmail[];
  surveys: Survey[];
  isClientSubAccount: boolean;
  dataLoaded: boolean;
  isLoading: boolean;
  parent: ClientAccount;
}
interface Survey {
  surveyGuid: string;
  surveyName: string;
  surveyTitle: string;
  isSurvey: boolean;
  parent: ClientSubAccount;
  shareByEmails: ShareByEmail[];
  dataLoaded: boolean;
  invitationSent: number;
  surveyStarted: number;
  surveyCompleted: number;
}
interface ShareByEmail {
  emailAddress: string;
  shareRoleId: number;
  isShareByEmail: boolean;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  constructor(
    public appState: AppStateService,
    public dataService: DataService,
    public dialog: MatDialog,
    private router: Router,
    private oidcSecurityService: OidcSecurityService,
  ) {
    this.loadUserProfile();
  }

  loadUserProfile() {
    this.dataService.getUserProfile().subscribe(profile => {
      profile.fullName = _.trim(profile.firstName + " " + profile.surname);
      this.appState.userProfile = { ...profile };
      if (!this.appState.userProfile.avatarUrl && this.appState.userProfile.fullName.toLowerCase() == "n/a")
        this.appState.userProfile.avatarUrl = AppStateService.DefaultUserAvatarUrl;
    });
  }
  //#region Header Component

  defaultArgs = {
    homeIcon: "https://res.cloudinary.com/twistoflime/Portal/Site/HLIcon.svg",
    menuItems: [
      { label: "Accounts", topLevel: true, url: "/", active: true },
      { label: "Licenses", topLevel: true, url: "/licenses" },
      { label: "Users", topLevel: true, url: "/users" },
      { label: "Roles", topLevel: true, url: "/roles" },
      { label: "Account Metrics", topLevel: true, url: "/metrics/account" },
      { label: "User Access Metrics", topLevel: true, url: "/metrics/user" },
      { label: "App Metrics", topLevel: true, url: "/metrics/application" },
    ],
    showSearch: true,
    actions: [
      // {
      //   icon: "more_vert",
      //   outline: false,
      //   color: "#fff",
      //   action: { name: "more-menu" },
      //   tooltip: "More",
      //   menuOptions: {
      //     groups: [
      //       {
      //         type: MenuOptionsType.Check,
      //         options: [
      //           {
      //             label: "Edit Layout",
      //             action: {
      //               name: "edit-mode",
      //               type: ActionType.Toggle,
      //               data: {
      //                 selected: false,
      //               },
      //             },
      //           },
      //         ],
      //       },
      //       {
      //         type: MenuOptionsType.Radio,
      //         options: [
      //           {
      //             label: "Light",
      //             action: {
      //               name: "light-mode",
      //               type: ActionType.Toggle,
      //               data: {
      //                 selected: localStorage.getItem("theme") === "light-mode",
      //               },
      //             },
      //           },
      //           {
      //             label: "Dark",
      //             action: {
      //               name: "dark-mode",
      //               type: ActionType.Toggle,
      //               data: {
      //                 selected: localStorage.getItem("theme") === "dark-mode",
      //               },
      //             },
      //           },
      //         ],
      //         trigger: {
      //           label: "Theme",
      //           action: {
      //             name: "nested-menu",
      //             type: ActionType.OpenMenu,
      //           },
      //         },
      //       },
      //       {
      //         type: MenuOptionsType.Radio,
      //         options: [
      //           {
      //             label: "List View",
      //             action: {
      //               name: "list-view",
      //               type: ActionType.Toggle,
      //               data: {
      //                 selected: localStorage.getItem("layout") === "list-view",
      //               },
      //             },
      //           },
      //           {
      //             label: "Tile View",
      //             action: {
      //               name: "tile-view",
      //               type: ActionType.Toggle,
      //               data: {
      //                 selected: localStorage.getItem("layout") === "tile-view",
      //               },
      //             },
      //           },
      //         ],
      //         trigger: {
      //           label: "Layout",
      //           action: {
      //             name: "nested-menu",
      //             type: ActionType.OpenMenu,
      //           },
      //         },
      //       },
      //     ],
      //   },
      // },
    ],
    avatarUrl: null,
    emailAddress: "michael.white@theevolvedgroup.com",
    fullName: "michael white",
    avatarMenuOptions: {
      groups: [
        {
          type: MenuOptionsType.Default,
          options: [
            {
              label: "Change Account",
              action: { name: "change-account" },
            },
            {
              label: "Logout",
              action: { name: "logout" }
            },
          ],
        },
      ],
    },
    accountList: [],
    currentAccount: {
      // name: this.appState.currentAccount?.clientAccountName,
      // vanityName: this.appState.currentAccount?.clientAccountVanityName,
      // iconUrl: this.appState.currentAccount?.clientAccountIconUrl,
      // subAccounts: [..._.map(this.appState.currentAccount?.subAccounts, subAccount => {
      //   return {
      //     name: subAccount.clientSubAccountName,
      //     iconUrl: subAccount.clientSubAccountIconUrl,
      //     vanityName: subAccount.clientSubAccountName,
      //   };
      // })],
    },
    currentSubAccount: {
      name: this.appState.currentSubAccount?.clientSubAccountName,
      iconUrl: this.appState.currentSubAccount?.clientSubAccountIconUrl,
    },
    helpHidden: false,
    helpText: "Help",
    helpUrl: "https://help.humanlistening.com/humanlistening/Content/HomeHL.htm",
    subHeaderActions: [
      // {
      //   icon: "settings",
      //   outline: false,
      //   color: "var(--theme-color-primary)",
      //   action: { name: "navbar-settings" },
      //   tooltip: "Settings",
      // }
    ]
  };

  onAvatarMenuClicked(ev: any) {
    switch (ev.name) {
      case "change-account": {
        this.appState.currentAccount = null;
        this.appState.currentSubAccount = null;
        this.router.navigateByUrl("/");
        break;
      }
      case "logout": {
        this.oidcSecurityService.logoff().subscribe();
        break;
      }
    }
  }

  onNavBarItemSelected(menuItem: any) {
    if (menuItem.url.startsWith("http")) {
      window.open(menuItem.url, "_blank");
    }
    else if (menuItem.url == "goBackToPortal") {
      //this.appState.showPortal();
    }
    else if (menuItem.url != "") {
      this.router.navigateByUrl(menuItem.url);
    }
    //this.apponMenuItemClicked.emit({ data: menuItem });
    //this.appEventService.notifyEventRaised("MenuItemClicked", menuItem);
  }

  onBreadcrumbClicked(breadcrumb: any) {
    if (breadcrumb.url == "/") {
      this.appState.currentAccount = null;
      this.appState.currentSubAccount = null;
    }

    this.router.navigateByUrl(breadcrumb.url);
  }
  //#endregion

  // addShare(contentTypeId: number, contentItemGuid: string, shareRoleId: number, emailAddress: string, activeNode: any) {
  //   this.dataService.shareByEmailAdd(
  //     contentTypeId,
  //     contentItemGuid,
  //     shareRoleId,
  //     emailAddress
  //   ).subscribe(response => {
  //     // Either add this to the existing list or refresh the active node's share list. The latter might be better, even if slower?
  //     if (activeNode.isClientAccount) {
  //       this.dataService.getclientAccountShares(activeNode.clientAccountGuid).subscribe(response => {
  //         activeNode.shareByEmails = response.data.shareByEmails;
  //       });
  //     }
  //     if (activeNode.isClientSubAccount) {
  //       this.dataService.getclientSubAccountShares(activeNode.clientSubAccountGuid).subscribe(response => {
  //         activeNode.shareByEmails = response.data.shareByEmails;
  //       });
  //     }
  //     if (activeNode.isSurvey) {
  //       this.dataService.getSurveyShares(activeNode.surveyGuid).subscribe(response => {
  //         activeNode.shareByEmails = response.data.shareByEmails;
  //       });
  //     }
  //   });
  // }
  
  // deleteShare(shareByEmail: any, activeNode: any) {
  //   this.dataService.shareByEmailDelete(
  //     shareByEmail.shareByEmailId
  //   ).subscribe(response => {
  //     // Either add this to the existing list or refresh the active node's share list. The latter might be better, even if slower?
  //     if (activeNode.isClientAccount) {
  //       this.dataService.getclientAccountShares(activeNode.clientAccountGuid).subscribe(response => {
  //         activeNode.shareByEmails = response.data.shareByEmails;
  //       });
  //     }
  //     if (activeNode.isClientSubAccount) {
  //       this.dataService.getclientSubAccountShares(activeNode.clientSubAccountGuid).subscribe(response => {
  //         activeNode.shareByEmails = response.data.shareByEmails;
  //       });
  //     }
  //     if (activeNode.isSurvey) {
  //       this.dataService.getSurveyShares(activeNode.surveyGuid).subscribe(response => {
  //         activeNode.shareByEmails = response.data.shareByEmails;
  //       });
  //     }
  //   });
  // }
}
