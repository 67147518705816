import * as i0 from '@angular/core';
import { Directive, Input, ContentChild, EventEmitter, Component, Inject, Optional, ViewChild, ContentChildren, Output, NgModule } from '@angular/core';
import * as i2 from '@angular/material/tabs';
import { MAT_TABS_CONFIG, MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
class TabContentDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: TabContentDirective,
      deps: [{
        token: i0.TemplateRef
      }],
      target: i0.ɵɵFactoryTarget.Directive
    });
  }
  static {
    this.ɵdir = i0.ɵɵngDeclareDirective({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: TabContentDirective,
      selector: "[tabContent]",
      ngImport: i0
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: TabContentDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: "[tabContent]"
    }]
  }],
  ctorParameters: () => [{
    type: i0.TemplateRef
  }]
});
class TabLabelDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: TabLabelDirective,
      deps: [{
        token: i0.TemplateRef
      }],
      target: i0.ɵɵFactoryTarget.Directive
    });
  }
  static {
    this.ɵdir = i0.ɵɵngDeclareDirective({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: TabLabelDirective,
      selector: "[tabLabel]",
      ngImport: i0
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: TabLabelDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: "[tabLabel]"
    }]
  }],
  ctorParameters: () => [{
    type: i0.TemplateRef
  }]
});
class TabDirective {
  constructor() {
    /** Whether the tab is disabled */
    this.disabled = false;
    this.visited = false;
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: TabDirective,
      deps: [],
      target: i0.ɵɵFactoryTarget.Directive
    });
  }
  static {
    this.ɵdir = i0.ɵɵngDeclareDirective({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: TabDirective,
      selector: "[tab]",
      inputs: {
        label: "label",
        disabled: "disabled"
      },
      queries: [{
        propertyName: "tabLabel",
        first: true,
        predicate: TabLabelDirective,
        descendants: true,
        static: true
      }, {
        propertyName: "tabContent",
        first: true,
        predicate: TabContentDirective,
        descendants: true,
        static: true
      }],
      ngImport: i0
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: TabDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: "[tab]"
    }]
  }],
  ctorParameters: () => [],
  propDecorators: {
    label: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    tabLabel: [{
      type: ContentChild,
      args: [TabLabelDirective, {
        static: true
      }]
    }],
    tabContent: [{
      type: ContentChild,
      args: [TabContentDirective, {
        static: true
      }]
    }]
  }
});
class TabsComponent {
  constructor(elementRef, defaultConfig) {
    this.elementRef = elementRef;
    this.defaultConfig = defaultConfig;
    /** Whether pagination should be disabled. This can be used to avoid unnecessary layout recalculations if it's known that pagination won't be required. */
    this.disablePagination = true;
    /** The index of the active tab. */
    this.selectedIndex = null;
    /** The color variant to use for the tabs */
    this.variant = "primary";
    /** The maximum width of the tabs header container - CSS width style string */
    this.tabsHeaderMaxWidth = null;
    /** Event emitted when the body animation has completed */
    this.animationDone = new EventEmitter();
    /** Event emitted when focus has changed within a tab group. */
    this.focusChange = new EventEmitter();
    /** Output to enable support for two-way binding on [(selectedIndex)]  */
    this.selectedIndexChange = new EventEmitter();
    /** Event emitted when the tab selection has changed. */
    this.selectedTabChange = new EventEmitter();
    this.firstLoad = true;
    this.initTabGroup(defaultConfig);
  }
  ngOnInit() {
    this.initTabGroup(this.defaultConfig);
  }
  ngAfterViewInit() {
    this.initCurrentTab();
    if (this.tabsHeaderMaxWidth) {
      this.setTabHeaderMaxWidth(this.tabsHeaderMaxWidth);
    }
  }
  onAnimationDone() {
    /** Set tabs width on first load/after first animation completion.
     * - Ensure ink bar aligns correctly initially
    */
    if (this.firstLoad) {
      this.firstLoad = false;
      if (this.tabsHeaderMaxWidth) {
        const maxWidth = `${this.elementRef.nativeElement.offsetWidth}px`;
        this.setTabHeaderMaxWidth(maxWidth);
        this.elementRef.nativeElement.style.setProperty("--tab-width", `calc(var(--max-tab-width) / ${this.tabs.length})`);
      }
      this.matTabGroup.realignInkBar();
    }
    this.animationDone.emit();
  }
  onFocusChange(changeEvent) {
    this.focusChange.emit(changeEvent);
  }
  onSelectedIndexChange(selectedInex) {
    this.selectedIndexChange.emit(selectedInex);
  }
  onSelectedTabChange(changeEvent) {
    let selectedTab = this.tabs.get(changeEvent.index);
    if (selectedTab) {
      selectedTab.visited = true;
    }
    this.selectedTabChange.emit(changeEvent);
  }
  initTabGroup(defaultConfig) {
    this.animationDuration = !this.animationDuration ? defaultConfig && defaultConfig.animationDuration ? defaultConfig.animationDuration : "500ms" : this.animationDuration;
    this.disablePagination = !this.disablePagination ? defaultConfig && defaultConfig.disablePagination != null ? defaultConfig.disablePagination : false : this.disablePagination;
    this.dynamicHeight = !this.dynamicHeight ? defaultConfig && defaultConfig.dynamicHeight != null ? defaultConfig.dynamicHeight : false : this.dynamicHeight;
    this.disableRipple = !this.disableRipple ? false : this.disableRipple;
    this.headerPosition = !this.headerPosition ? "above" : this.headerPosition;
    this.selectedIndex = !this.selectedIndex ? 0 : this.selectedIndex;
  }
  initCurrentTab() {
    let currentTab;
    if (this.selectedIndex) {
      currentTab = this.tabs.get(this.selectedIndex);
    } else {
      currentTab = this.tabs.get(0);
    }
    if (currentTab) {
      currentTab.visited = true;
    }
  }
  setTabHeaderMaxWidth(maxWidth) {
    const hasMaxWidth = getComputedStyle(this.elementRef.nativeElement).getPropertyValue("--max-tab-width");
    if (hasMaxWidth == null || hasMaxWidth.length == 0) {
      this.elementRef.nativeElement.style.setProperty("--max-tab-width", maxWidth);
    }
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: TabsComponent,
      deps: [{
        token: i0.ElementRef
      }, {
        token: MAT_TABS_CONFIG,
        optional: true
      }],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: TabsComponent,
      selector: "teg-tabs",
      inputs: {
        animationDuration: "animationDuration",
        disablePagination: "disablePagination",
        disableRipple: "disableRipple",
        dynamicHeight: "dynamicHeight",
        headerPosition: "headerPosition",
        selectedIndex: "selectedIndex",
        variant: "variant",
        tabsHeaderMaxWidth: "tabsHeaderMaxWidth"
      },
      outputs: {
        animationDone: "animationDone",
        focusChange: "focusChange",
        selectedIndexChange: "selectedIndexChange",
        selectedTabChange: "selectedTabChange"
      },
      queries: [{
        propertyName: "tabs",
        predicate: TabDirective
      }],
      viewQueries: [{
        propertyName: "matTabGroup",
        first: true,
        predicate: MatTabGroup,
        descendants: true
      }],
      ngImport: i0,
      template: "<mat-tab-group [backgroundColor]=\"variant\"\n               [color]=\"variant\"\n               [animationDuration]=\"animationDuration\"\n               [disablePagination]=\"disablePagination\"\n               [disableRipple]=\"disableRipple\"\n               [dynamicHeight]=\"dynamicHeight\"\n               [headerPosition]=\"headerPosition\"\n               [(selectedIndex)]=\"selectedIndex\"\n               (animationDone)=\"onAnimationDone()\"\n               (focusChange)=\"onFocusChange($event)\"\n               (selectedIndexChange)=\"onSelectedIndexChange($event)\"\n               (selectedTabChange)=\"onSelectedTabChange($event)\">\n    <mat-tab *ngFor=\"let tab of tabs; let i = index\"\n             [label]=\"tab.label\"\n             [disabled]=\"tab.disabled\">\n        <ng-template mat-tab-label\n                     *ngIf=\"tab.tabLabel\">\n            <ng-container [ngTemplateOutlet]=\"tab.tabLabel.templateRef\"></ng-container>\n        </ng-template>\n        <div *ngIf=\"tab.visited\"\n             class=\"tab-container\">\n            <ng-container [ngTemplateOutlet]=\"tab.tabContent.templateRef\"></ng-container>\n        </div>\n    </mat-tab>\n</mat-tab-group>",
      styles: [":host{display:flex;width:100%;height:100%}.tab-container{display:flex;flex:1;flex-flow:column;padding-top:var(--theme-spacing-md);max-height:calc(100% - var(--theme-spacing-md));overflow-x:hidden}\n"],
      dependencies: [{
        kind: "directive",
        type: i1.NgForOf,
        selector: "[ngFor][ngForOf]",
        inputs: ["ngForOf", "ngForTrackBy", "ngForTemplate"]
      }, {
        kind: "directive",
        type: i1.NgIf,
        selector: "[ngIf]",
        inputs: ["ngIf", "ngIfThen", "ngIfElse"]
      }, {
        kind: "directive",
        type: i1.NgTemplateOutlet,
        selector: "[ngTemplateOutlet]",
        inputs: ["ngTemplateOutletContext", "ngTemplateOutlet", "ngTemplateOutletInjector"]
      }, {
        kind: "directive",
        type: i2.MatTabLabel,
        selector: "[mat-tab-label], [matTabLabel]"
      }, {
        kind: "component",
        type: i2.MatTab,
        selector: "mat-tab",
        inputs: ["disabled", "label", "aria-label", "aria-labelledby", "labelClass", "bodyClass"],
        exportAs: ["matTab"]
      }, {
        kind: "component",
        type: i2.MatTabGroup,
        selector: "mat-tab-group",
        inputs: ["color", "fitInkBarToContent", "mat-stretch-tabs", "dynamicHeight", "selectedIndex", "headerPosition", "animationDuration", "contentTabIndex", "disablePagination", "disableRipple", "preserveContent", "backgroundColor", "aria-label", "aria-labelledby"],
        outputs: ["selectedIndexChange", "focusChange", "animationDone", "selectedTabChange"],
        exportAs: ["matTabGroup"]
      }]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: TabsComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: "teg-tabs",
      template: "<mat-tab-group [backgroundColor]=\"variant\"\n               [color]=\"variant\"\n               [animationDuration]=\"animationDuration\"\n               [disablePagination]=\"disablePagination\"\n               [disableRipple]=\"disableRipple\"\n               [dynamicHeight]=\"dynamicHeight\"\n               [headerPosition]=\"headerPosition\"\n               [(selectedIndex)]=\"selectedIndex\"\n               (animationDone)=\"onAnimationDone()\"\n               (focusChange)=\"onFocusChange($event)\"\n               (selectedIndexChange)=\"onSelectedIndexChange($event)\"\n               (selectedTabChange)=\"onSelectedTabChange($event)\">\n    <mat-tab *ngFor=\"let tab of tabs; let i = index\"\n             [label]=\"tab.label\"\n             [disabled]=\"tab.disabled\">\n        <ng-template mat-tab-label\n                     *ngIf=\"tab.tabLabel\">\n            <ng-container [ngTemplateOutlet]=\"tab.tabLabel.templateRef\"></ng-container>\n        </ng-template>\n        <div *ngIf=\"tab.visited\"\n             class=\"tab-container\">\n            <ng-container [ngTemplateOutlet]=\"tab.tabContent.templateRef\"></ng-container>\n        </div>\n    </mat-tab>\n</mat-tab-group>",
      styles: [":host{display:flex;width:100%;height:100%}.tab-container{display:flex;flex:1;flex-flow:column;padding-top:var(--theme-spacing-md);max-height:calc(100% - var(--theme-spacing-md));overflow-x:hidden}\n"]
    }]
  }],
  ctorParameters: () => [{
    type: i0.ElementRef
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [MAT_TABS_CONFIG]
    }, {
      type: Optional
    }]
  }],
  propDecorators: {
    matTabGroup: [{
      type: ViewChild,
      args: [MatTabGroup]
    }],
    tabs: [{
      type: ContentChildren,
      args: [TabDirective]
    }],
    animationDuration: [{
      type: Input
    }],
    disablePagination: [{
      type: Input
    }],
    disableRipple: [{
      type: Input
    }],
    dynamicHeight: [{
      type: Input
    }],
    headerPosition: [{
      type: Input
    }],
    selectedIndex: [{
      type: Input
    }],
    variant: [{
      type: Input
    }],
    tabsHeaderMaxWidth: [{
      type: Input
    }],
    animationDone: [{
      type: Output
    }],
    focusChange: [{
      type: Output
    }],
    selectedIndexChange: [{
      type: Output
    }],
    selectedTabChange: [{
      type: Output
    }]
  }
});
class TabsModule {
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: TabsModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: TabsModule,
      declarations: [TabsComponent, TabLabelDirective, TabContentDirective, TabDirective],
      imports: [CommonModule, MatTabsModule],
      exports: [TabsComponent, TabLabelDirective, TabContentDirective, TabDirective]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: TabsModule,
      imports: [CommonModule, MatTabsModule]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: TabsModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [CommonModule, MatTabsModule],
      exports: [TabsComponent, TabLabelDirective, TabContentDirective, TabDirective],
      declarations: [TabsComponent, TabLabelDirective, TabContentDirective, TabDirective],
      providers: []
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { TabContentDirective, TabDirective, TabLabelDirective, TabsComponent, TabsModule };
