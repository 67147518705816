/* src/app/components/dialogs/share-dialog/share-dialog.component.less */
.share-dialog-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.share-dialog-content .share-table-container {
  display: block;
}
.share-dialog-content .new-share-container {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  gap: 16px;
}
.share-dialog-content .new-share-container .new-share-form {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.share-dialog-content .new-share-container .new-share-form .email-address {
  flex-basis: 160px;
}
.share-dialog-content .new-share-container .new-share-form .share-role {
  flex-basis: 80px;
}
.share-dialog-content .section-title {
  font-size: var(--theme-h3-font-size);
  color: var(--theme-section-header-color);
  font-weight: 500;
}
/*# sourceMappingURL=share-dialog.component.css.map */
