import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from 'rxjs';
import { AppStateService } from "./services/app-state.service";
import { DataService } from "./services/data.service";
import _ from "lodash";

@Injectable()
export class HomeResolver {
    constructor(
        private router: Router,
        public appState: AppStateService,
        public dataService: DataService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> {
        return new Observable((observer) => {
            if (!this.appState.shareRoles || this.appState.shareRoles.length < 0) {
                this.appState.dataLoaded = false;

                this.dataService.getShareRoles().subscribe(response => {
                    this.appState.shareRoles = response.data.shareRoles;
                    this.appState.dataLoaded = true;

                    observer.next();
                    observer.complete();
                });
            }
        });
    }
}
