import { HttpClient } from "@angular/common/http";
import { NgModule } from '@angular/core';
import { AuthModule, OpenIdConfiguration, StsConfigHttpLoader, StsConfigLoader } from "angular-auth-oidc-client";
import { map } from "rxjs";
import { AppStateService } from "./services/app-state.service";

export const initApp = (
    httpClient: HttpClient,
    appState: AppStateService,
) => {
    const config$ = httpClient.get<any>('/assets/' + window.location.hostname + '.config.json').pipe(map((configResult) => {
        const siteUrl = window.location.origin;
        appState.clientConfiguration = configResult;
        const identityServer: string = configResult.stsServer;
        const appApis = ['PortalAdminApi'];

        const config: OpenIdConfiguration = {
            authority: identityServer,
            redirectUrl: siteUrl + '/auth-callback', // This sites url
            clientId: 'PortalAdminApp',
            responseType: 'id_token token',
            scope:
                'openid email profile ' + appApis.join(' '), // This must match ClientClaims in identity
            postLogoutRedirectUri:
                siteUrl + '/Unauthorized',
            startCheckSession: true,
            silentRenew: true,
            silentRenewUrl:
                siteUrl + '/assets/silent-renew.html',
            forbiddenRoute: '/Forbidden',
            unauthorizedRoute: '/Unauthorized',
            maxIdTokenIatOffsetAllowedInSeconds: 3600, // This needs to match the backend
            authWellknownEndpoints: {
                issuer: 'https://identity.twistoflime.com.au',
                jwksUri: identityServer + '/.well-known/openid-configuration/jwks',
                authorizationEndpoint: identityServer + '/connect/authorize',
                tokenEndpoint: identityServer + '/connect/token',
                userInfoEndpoint: identityServer + '/connect/userinfo',
                endSessionEndpoint: identityServer + '/connect/endsession',
                checkSessionIframe: identityServer + '/connect/checksession',
                revocationEndpoint: identityServer + '/connect/revocation',
                introspectionEndpoint: identityServer + '/connect/introspect',
            },
            secureRoutes: [configResult.graphqlUrl, configResult.webApiUrl, configResult.portalApiUrl],
        };

        const url = window.location.pathname + window.location.search;

        if (
            !url.startsWith('/auth-callback') &&
            url !== '/Unauthorized' &&
            url !== '/Forbidden'
        ) {
            config.postLoginRoute =
                window.location.pathname + window.location.search;
        }
        return config;
    }));

    return new StsConfigHttpLoader(config$);
};

@NgModule({
    imports: [
        AuthModule.forRoot({
            loader: {
                provide: StsConfigLoader,
                useFactory: initApp,
                deps: [
                    HttpClient,
                    AppStateService
                ],
            },
        })
    ],
    exports: [AuthModule],
})
export class AuthConfigModule { }
