import * as i0 from '@angular/core';
import { Component, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
class ClockComponent {
  constructor(host, zone) {
    this.host = host;
    this.zone = zone;
  }
  ngOnInit() {
    this.init();
  }
  ngOnChanges() {
    this.init();
  }
  ngAfterViewInit() {}
  ngOnDestroy() {
    // Make sure to remove any timeout or intervals that are still active
    clearTimeout(this.timeoutHandle);
    clearInterval(this.intervalHandle);
  }
  timeInterval() {
    if (this.inputDate) {
      // Increment by 1 minute each interval
      this.inputDate = new Date(this.inputDate?.getTime() + 60000);
      this.evaluateInputDate();
    }
  }
  evaluateInputDate() {
    this.currentDate = this.inputDate?.toDateString().substring(4, 10);
    this.currentTime = this.inputDate?.toTimeString().substring(0, 5);
    this.currentDay = this.inputDate?.toDateString().substring(0, 3);
  }
  init() {
    // Make sure to remove any timeout or intervals that are still active
    clearTimeout(this.timeoutHandle);
    clearInterval(this.intervalHandle);
    if (this.inputDate) {
      this.evaluateInputDate();
      // Calculate how long until the next minute
      let delay = 60000 - this.inputDate?.getSeconds() * 1000;
      this.timeoutHandle = setTimeout(() => {
        // Calculate the first minute roll over
        this.timeInterval();
        // Create an interval to update every minute from then on
        this.intervalHandle = setInterval(() => {
          this.timeInterval();
        }, 60000);
      }, delay, {});
    }
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: ClockComponent,
      deps: [{
        token: i0.ElementRef
      }, {
        token: i0.NgZone
      }],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: ClockComponent,
      selector: "teg-clock",
      inputs: {
        title: "title",
        inputDate: "inputDate",
        inputTimeZone: "inputTimeZone"
      },
      usesOnChanges: true,
      ngImport: i0,
      template: "<div class=\"clock-container\">\n    <ng-container *ngIf=\"!inputDate\">\n        <div>Loading Styles Go Here...</div>\n    </ng-container>\n    <ng-container *ngIf=\"inputDate\">\n        <div *ngIf=\"title\" class=\"clock-title\" [innerHtml]=\"title\"></div>\n        <div class=\"clock-date-container\">\n            <div class=\"clock-time\" [innerHtml]=\"currentTime\"></div>\n            <div class=\"clock-date-day-container\">\n                <div class=\"clock-day-of-week\" [innerHtml]=\"currentDay\"></div>\n                <div class=\"clock-date\" [innerHtml]=\"currentDate\"></div>\n            </div>\n        </div>\n    </ng-container>\n</div>\n",
      styles: [".clock-container{display:flex;flex-direction:row}.clock-title{color:var(--theme-text-color);font-size:var(--theme-h4-font-size);line-height:var(--theme-h4-font-size);text-align:center;align-self:center;font-weight:500}.clock-date-container{display:flex;flex-direction:row;align-items:center;justify-content:center;padding-left:var(--theme-spacing-xs);padding-right:var(--theme-spacing-xs)}.clock-time{font-size:var(--theme-h1-font-size);line-height:var(--theme-h1-font-size);color:var(--theme-color-accent-2);margin-right:var(--theme-spacing-xs);font-weight:500}.clock-date-day-container{display:flex;flex-direction:row;justify-content:center;color:var(--theme-h1-text-color);height:100%;font-weight:500}.clock-day-of-week{font-size:var(--theme-h1-font-size);line-height:var(--theme-h1-font-size);padding-right:var(--theme-spacing-xs);font-weight:500}.clock-date{font-size:var(--theme-h1-font-size);line-height:var(--theme-h1-font-size);font-weight:500}\n"],
      dependencies: [{
        kind: "directive",
        type: i1.NgIf,
        selector: "[ngIf]",
        inputs: ["ngIf", "ngIfThen", "ngIfElse"]
      }]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: ClockComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: "teg-clock",
      template: "<div class=\"clock-container\">\n    <ng-container *ngIf=\"!inputDate\">\n        <div>Loading Styles Go Here...</div>\n    </ng-container>\n    <ng-container *ngIf=\"inputDate\">\n        <div *ngIf=\"title\" class=\"clock-title\" [innerHtml]=\"title\"></div>\n        <div class=\"clock-date-container\">\n            <div class=\"clock-time\" [innerHtml]=\"currentTime\"></div>\n            <div class=\"clock-date-day-container\">\n                <div class=\"clock-day-of-week\" [innerHtml]=\"currentDay\"></div>\n                <div class=\"clock-date\" [innerHtml]=\"currentDate\"></div>\n            </div>\n        </div>\n    </ng-container>\n</div>\n",
      styles: [".clock-container{display:flex;flex-direction:row}.clock-title{color:var(--theme-text-color);font-size:var(--theme-h4-font-size);line-height:var(--theme-h4-font-size);text-align:center;align-self:center;font-weight:500}.clock-date-container{display:flex;flex-direction:row;align-items:center;justify-content:center;padding-left:var(--theme-spacing-xs);padding-right:var(--theme-spacing-xs)}.clock-time{font-size:var(--theme-h1-font-size);line-height:var(--theme-h1-font-size);color:var(--theme-color-accent-2);margin-right:var(--theme-spacing-xs);font-weight:500}.clock-date-day-container{display:flex;flex-direction:row;justify-content:center;color:var(--theme-h1-text-color);height:100%;font-weight:500}.clock-day-of-week{font-size:var(--theme-h1-font-size);line-height:var(--theme-h1-font-size);padding-right:var(--theme-spacing-xs);font-weight:500}.clock-date{font-size:var(--theme-h1-font-size);line-height:var(--theme-h1-font-size);font-weight:500}\n"]
    }]
  }],
  ctorParameters: () => [{
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }],
  propDecorators: {
    title: [{
      type: Input
    }],
    inputDate: [{
      type: Input
    }],
    inputTimeZone: [{
      type: Input
    }]
  }
});
class ClockModule {
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: ClockModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: ClockModule,
      declarations: [ClockComponent],
      imports: [CommonModule],
      exports: [ClockComponent]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: ClockModule,
      imports: [CommonModule]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: ClockModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [ClockComponent],
      declarations: [ClockComponent],
      providers: []
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { ClockComponent, ClockModule };
