<div class="headerRow" style="display:flex;height:40px;margin-right:18px;">
    <div style="border:1px solid black;box-sizing: border-box;overflow: hidden;flex-grow:1;">
        Filter: <input type=text [(ngModel)]="patternMatch" (ngModelChange)="changed($event)"> Users with licenses only?
        <input type="checkbox" [(ngModel)]="usersWithLicenses"> ({{ userInfo.count }} of {{ users.length }} users)
        <br> Email
    </div>
    <div *ngFor="let application of applications" style="border:1px solid black;width:100px;box-sizing: border-box;text-align: center;">
        {{ application.applicationName}}
    </div>
</div>
<div *ngIf="loading" style="padding-top:40px;">Loading...</div>
<div class="userList" *ngIf="!loading" style="border:1px solid black;padding-top:40px;height:calc(100% - 82px)">
    <div class="bodyRows" style="height:100%;">
        <cdk-virtual-scroll-viewport itemSize="22" style='height:100%;overflow-y:scroll;'>
            <div *cdkVirtualFor="let user of users | userWithLicense : usersWithLicenses : applications : userInfo">
                <div style="display:flex;height:22px;">
                    <div style="border:1px solid black;box-sizing: border-box;flex-grow:1">{{ user.email }} </div>
                    <div *ngFor="let application of applications" style="border:1px solid black;width:100px;box-sizing: border-box;text-align: center;">
                        <input *ngIf="!user.licenses[application.applicationId].updating" type="checkbox" [checked]="user.licenses[application.applicationId].checked" (change)="onChange($event, user, application)">
                        <div *ngIf="user.licenses[application.applicationId].updating">updating</div>
                    </div>
                </div>
            </div>
        </cdk-virtual-scroll-viewport>
    </div>
</div>
