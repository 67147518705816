import { Component } from '@angular/core';
import { AppStateService } from './services/app-state.service';
import { OidcSecurityService } from "angular-auth-oidc-client";
import { Router } from "@angular/router";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(public appState: AppStateService, private oidcSecurityService: OidcSecurityService, private router: Router) { }

  ngOnInit() {
    this.checkAuth();
  }

  private checkAuth() {
    this.oidcSecurityService
      .checkAuth()
      .subscribe((loginResponse) => {
        this.appState.isAuthorized = loginResponse.isAuthenticated;
        this.appState.clientConfiguration.spinnerSrc = '/assets/theme/' + (<any>window).location.hostname + '.spinner.gif';

        if (!loginResponse.isAuthenticated) {
          // Store the return URL so we know where the user was trying to get to.
          localStorage.setItem(
            'portal_redirect',
            JSON.stringify(window.location.pathname)
          );
          this.oidcSecurityService.authorize();
        } else {
          // Use this to strip any parameters that might have been returned by Identity
          if (localStorage.getItem('portal_redirect')) {
            this.router.navigateByUrl(
              JSON.parse(localStorage.getItem('portal_redirect'))
            );
            localStorage.removeItem('portal_redirect');
          }

          if (!loginResponse.userData) {
            // This should only happen after we log in
            console.log('User has been logged out');
            alert('You have been logged out. Click OK to refresh.');
            this.appState.isAuthorized = false;
            this.oidcSecurityService.authorize();
            return;
          }
        }
      });
  }
}
