import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, Input, NgModule } from '@angular/core';
import { getColorConfig } from '@theevolvedgroup/component-library/utils';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
var PlaceholderType;
(function (PlaceholderType) {
  PlaceholderType[PlaceholderType["Circle"] = 0] = "Circle";
  PlaceholderType[PlaceholderType["Bar"] = 1] = "Bar";
})(PlaceholderType || (PlaceholderType = {}));
var AnimationType;
(function (AnimationType) {
  AnimationType[AnimationType["Pulse"] = 0] = "Pulse";
  AnimationType[AnimationType["Progress"] = 1] = "Progress";
})(AnimationType || (AnimationType = {}));
class LoadingPlaceholderComponent {
  constructor(elementRef) {
    this.elementRef = elementRef;
    this.PlaceholderType = PlaceholderType;
    this.AnimationType = AnimationType;
    this.items = [];
  }
  ngOnInit() {
    let {
      theme,
      customColor
    } = getColorConfig(null, "primary");
    this.theme = theme;
    if (!this.placeholderType) this.placeholderType = PlaceholderType.Circle;
    if (!this.animationType) this.animationType = AnimationType.Pulse;
    if (!this.count || this.count == 0) this.count = 1;
    this.items.length = this.count;
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: LoadingPlaceholderComponent,
      deps: [{
        token: i0.ElementRef
      }],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: LoadingPlaceholderComponent,
      selector: "[teg-loading]",
      inputs: {
        placeholderType: "placeholderType",
        animationType: "animationType",
        count: "count"
      },
      ngImport: i0,
      template: "<div class=\"{{theme}}\"\n  *ngFor=\"let item of items\"\n  class=\"teg-loading\"\n  [ngClass]=\"{\n    circle: placeholderType === PlaceholderType.Circle,\n    progress: animationType === AnimationType.Progress,\n    pulse: animationType ===  AnimationType.Pulse\n  }\"\n>\n</div>\n\n\n\n\n",
      styles: [":host{--placeholder-spacing: \"\"}.teg-loading{box-sizing:border-box;overflow:hidden;position:relative;background:var(--loading-placeholder-color) no-repeat;min-height:20px;min-width:20px;height:100%;width:100%;gap:1rem;flex-direction:column;display:flex}.teg-loading:after,.teg-loading:before{box-sizing:border-box}.teg-loading.circle{border-radius:50%}.teg-loading.progress{transform:translateZ(0)}.teg-loading.progress:after,.teg-loading.progress:before{box-sizing:border-box}.teg-loading.progress:before{animation:progress 2s ease-in-out infinite;background-size:200px 100%;position:absolute;z-index:1;top:0;left:0;width:200px;height:100%;content:\"\"}.teg-loading.progress:before{background-image:linear-gradient(90deg,var(--loading-gradient-color-dark),var(--loading-gradient-color-light),var(--loading-gradient-color-dark))}.teg-loading.pulse{animation:pulse 1.5s cubic-bezier(.4,0,.2,1) infinite;animation-delay:.5s}@media (prefers-reduced-motion: reduce){.teg-loading.pulse{animation:pulse 3s cubic-bezier(.4,0,.2,1) infinite;animation-delay:.5s}.teg-loading.progress:before{animation:progress 5s ease-in-out infinite}}@keyframes progress{0%{transform:translate3d(-200px,0,0)}to{transform:translate3d(calc(200px + 100vw),0,0)}}@keyframes pulse{0%{opacity:1}50%{opacity:.4}to{opacity:1}}.teg-loading~.teg-loading{margin-top:var(--placeholder-spacing)}\n"],
      dependencies: [{
        kind: "directive",
        type: i1.NgClass,
        selector: "[ngClass]",
        inputs: ["class", "ngClass"]
      }, {
        kind: "directive",
        type: i1.NgForOf,
        selector: "[ngFor][ngForOf]",
        inputs: ["ngForOf", "ngForTrackBy", "ngForTemplate"]
      }],
      encapsulation: i0.ViewEncapsulation.None
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: LoadingPlaceholderComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: "[teg-loading]",
      encapsulation: ViewEncapsulation.None,
      template: "<div class=\"{{theme}}\"\n  *ngFor=\"let item of items\"\n  class=\"teg-loading\"\n  [ngClass]=\"{\n    circle: placeholderType === PlaceholderType.Circle,\n    progress: animationType === AnimationType.Progress,\n    pulse: animationType ===  AnimationType.Pulse\n  }\"\n>\n</div>\n\n\n\n\n",
      styles: [":host{--placeholder-spacing: \"\"}.teg-loading{box-sizing:border-box;overflow:hidden;position:relative;background:var(--loading-placeholder-color) no-repeat;min-height:20px;min-width:20px;height:100%;width:100%;gap:1rem;flex-direction:column;display:flex}.teg-loading:after,.teg-loading:before{box-sizing:border-box}.teg-loading.circle{border-radius:50%}.teg-loading.progress{transform:translateZ(0)}.teg-loading.progress:after,.teg-loading.progress:before{box-sizing:border-box}.teg-loading.progress:before{animation:progress 2s ease-in-out infinite;background-size:200px 100%;position:absolute;z-index:1;top:0;left:0;width:200px;height:100%;content:\"\"}.teg-loading.progress:before{background-image:linear-gradient(90deg,var(--loading-gradient-color-dark),var(--loading-gradient-color-light),var(--loading-gradient-color-dark))}.teg-loading.pulse{animation:pulse 1.5s cubic-bezier(.4,0,.2,1) infinite;animation-delay:.5s}@media (prefers-reduced-motion: reduce){.teg-loading.pulse{animation:pulse 3s cubic-bezier(.4,0,.2,1) infinite;animation-delay:.5s}.teg-loading.progress:before{animation:progress 5s ease-in-out infinite}}@keyframes progress{0%{transform:translate3d(-200px,0,0)}to{transform:translate3d(calc(200px + 100vw),0,0)}}@keyframes pulse{0%{opacity:1}50%{opacity:.4}to{opacity:1}}.teg-loading~.teg-loading{margin-top:var(--placeholder-spacing)}\n"]
    }]
  }],
  ctorParameters: () => [{
    type: i0.ElementRef
  }],
  propDecorators: {
    placeholderType: [{
      type: Input
    }],
    animationType: [{
      type: Input
    }],
    count: [{
      type: Input
    }]
  }
});
class LoadingPlaceholderModule {
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: LoadingPlaceholderModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: LoadingPlaceholderModule,
      declarations: [LoadingPlaceholderComponent],
      imports: [CommonModule],
      exports: [LoadingPlaceholderComponent]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: LoadingPlaceholderModule,
      imports: [CommonModule]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: LoadingPlaceholderModule,
  decorators: [{
    type: NgModule,
    args: [{
      declarations: [LoadingPlaceholderComponent],
      imports: [CommonModule],
      exports: [LoadingPlaceholderComponent],
      providers: []
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { AnimationType, LoadingPlaceholderComponent, LoadingPlaceholderModule, PlaceholderType };
