/* src/app/components/account/account.component.less */
.content-section-wrapper {
  padding: 24px;
}
.content-section-wrapper .filter-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: end;
}
.content-section-wrapper .filter-container .filter {
  flex-basis: 200px;
}
/*# sourceMappingURL=account.component.css.map */
