import { DataService } from "../../../services/data.service";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { forkJoin, Subject } from "rxjs";
import * as _ from "lodash";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  GridComponent
} from '@syncfusion/ej2-angular-grids';

@Component({
    selector: 'confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ["./confirmation-dialog.component.css"],
  })
  export class ConfirmationDialog {
    constructor(public dialogRef: MatDialogRef<ConfirmationDialog>) {}
  
    public confirmMessage:string;
  }