import * as i0 from '@angular/core';
import { Directive, Component, Input, HostBinding, VERSION, EventEmitter, ContentChildren, ContentChild, Output, PLATFORM_ID, Inject, InjectionToken, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { isPlatformBrowser, CommonModule } from '@angular/common';

/**
 * Returns true if the given string begins with a left curly brace and ends with a right curly brace, e.g.
 * "{asdas d}" will return true, "asdasd}" will return false.
 *
 * this function does not validate the correctness of the string content other than the first and last character
 * @param str
 * @returns boolean
 */
const isJsonLikeString = function (str) {
  // [\s\S] allows the string to contain new lines
  return str && typeof str === 'string' && str.trim().match(/^{[\s\S]*?}$/) !== null;
};
const isArrayLikeString = function (str) {
  return str && typeof str === 'string' && str.trim().match(/^\[[\s\S]*?]$/) !== null;
};
const isNamedNodeMap = function (obj) {
  return obj && (obj.constructor.name === 'NamedNodeMap' || obj instanceof NamedNodeMap);
};
const namedNodeMapToObject = function (source) {
  let target = {};
  Object.keys(source).forEach(index => {
    const name = source[index].name;
    const value = source[index].value;
    target[name] = value;
  });
  return target;
};
const transformKeyNames = function (obj) {
  let _obj = obj;
  if (isJsonLikeString(obj) || isArrayLikeString(obj)) {
    // Given attribute value is in the form of a JSON object -
    // Transforms the string into an object or array, as the Javascript API expects
    if (isArrayLikeString(obj)) {
      obj = obj.replace(/'/g, '"');
    }
    _obj = JSON.parse(obj);
  } else if (isNamedNodeMap(obj)) {
    _obj = namedNodeMapToObject(obj);
  }
  if (Array.isArray(_obj)) {
    // Transform all the array values (e.g. transformation array)
    _obj = _obj.map(currentValue => {
      return transformKeyNames(currentValue);
    });
  } else if (typeof _obj === 'object') {
    Object.keys(_obj).forEach(key => {
      // Replace the key name with the snake_case
      // Then strip cld prefix if it exists (with an optional dash or underscore)
      const kebabKey = key.replace(/-/g, '_').toLocaleLowerCase().replace(/^cld(-|_)?/, '');
      const kebabValue = transformKeyNames(_obj[key]);
      delete _obj[key];
      _obj[kebabKey] = kebabValue;
    });
  }
  return _obj;
};
class Cloudinary {
  constructor(cloudinaryJsLib, configuration) {
    // Cloudinary JS already clones the given configuration so no need to clone it here too
    if (cloudinaryJsLib.CloudinaryJQuery) {
      this._cloudinaryInstance = new cloudinaryJsLib.CloudinaryJQuery(configuration);
    } else {
      this._cloudinaryInstance = new cloudinaryJsLib.Cloudinary(configuration);
    }
  }
  get cloudinaryInstance() {
    return this._cloudinaryInstance;
  }
  config() {
    return this._cloudinaryInstance.config();
  }
  updateConfig(configuration) {
    this._cloudinaryInstance.config(configuration);
  }
  url(...parameters) {
    return this._cloudinaryInstance.url(...parameters);
  }
  imageTag(...parameters) {
    return this._cloudinaryInstance.imageTag(...parameters);
  }
  videoTag(...parameters) {
    return this._cloudinaryInstance.videoTag(...parameters);
  }
  responsive(img, options) {
    // Cloudinary underlying JS library will handle responsive behavior
    this._cloudinaryInstance.cloudinary_update(img, options);
    this._cloudinaryInstance.responsive(options, false);
  }
  /**
   * Transforms a set of attributes and chained transformations to an options object that can be consumed by Cloudinary JS API
   * @param attributes HTML element attributes
   * @param childTransformations QueryList with the element's <cl-transformation> children for chained transformations
   * @param cloudinary Cloudinary service
   * @returns An options object that can be consumed by Cloudinary JS API
   */
  toCloudinaryAttributes(attributes, childTransformations) {
    const options = transformKeyNames(attributes);
    // Add chained transformations
    if (childTransformations && childTransformations.length > 0) {
      options.transformation = [];
      // Support chained transformations
      childTransformations.forEach(transformation => {
        options.transformation.push(this.toCloudinaryAttributes(transformation.getAttributes()));
      });
    }
    // Add responsiveness
    if (options.responsive === '' || options.responsive === 'true' || options.responsive === true) {
      options.responsive = true;
    }
    return options;
  }
}
/* Return a provider object that creates our configurable service */
function provideCloudinary(cloudinaryJsLib, configuration) {
  return {
    provide: Cloudinary,
    useFactory: () => new Cloudinary(cloudinaryJsLib, configuration)
  };
}
;
const isBrowser = function () {
  return typeof window !== 'undefined';
};
class CloudinaryTransformationDirective {
  constructor(el) {
    this.el = el;
  }
  getAttributes() {
    return this.el.nativeElement.attributes;
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: CloudinaryTransformationDirective,
      deps: [{
        token: i0.ElementRef
      }],
      target: i0.ɵɵFactoryTarget.Directive
    });
  }
  static {
    this.ɵdir = i0.ɵɵngDeclareDirective({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: CloudinaryTransformationDirective,
      selector: "cl-transformation",
      ngImport: i0
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: CloudinaryTransformationDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'cl-transformation'
    }]
  }],
  ctorParameters: () => [{
    type: i0.ElementRef
  }]
});
class CloudinaryPlaceHolder {
  constructor(cloudinary, renderer, el) {
    this.cloudinary = cloudinary;
    this.renderer = renderer;
    this.el = el;
    this.options = {};
  }
  setWidth(width) {
    this.itemWidth = width;
  }
  setHeight(height) {
    this.itemHeight = height;
  }
  setPublicId(id) {
    this.publicId = id;
  }
  ngAfterContentChecked() {
    const imageTag = this.cloudinary.imageTag(this.publicId, this.options);
    this.setElementAttributes(this.el.nativeElement.children[0], imageTag.attributes());
    this.placeholderImg = this.getPlaceholderImage();
  }
  getPlaceholderImage() {
    if (this.type === 'predominant-color' && this.itemHeight && this.itemWidth) {
      return this.cloudinary.url(this.publicId, {
        placeholder: 'predominant-color-pixel' || true,
        ...this.options
      });
    } else {
      return this.cloudinary.url(this.publicId, {
        placeholder: this.type || true,
        ...this.options
      });
    }
  }
  setElementAttributes(element, attributesLiteral) {
    Object.keys(attributesLiteral).forEach(attrName => {
      if (attrName !== 'src' && attrName !== 'style') {
        this.renderer.setAttribute(element, attrName, attributesLiteral[attrName]);
      }
    });
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: CloudinaryPlaceHolder,
      deps: [{
        token: Cloudinary
      }, {
        token: i0.Renderer2
      }, {
        token: i0.ElementRef
      }],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: CloudinaryPlaceHolder,
      selector: "cl-placeholder",
      inputs: {
        type: "type"
      },
      host: {
        properties: {
          "style.width": "this.itemWidth",
          "style.height": "this.itemHeight",
          "attr.public-id": "this.publicId"
        }
      },
      ngImport: i0,
      template: `<img [src]="this.placeholderImg">`,
      isInline: true
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: CloudinaryPlaceHolder,
  decorators: [{
    type: Component,
    args: [{
      selector: 'cl-placeholder',
      template: `<img [src]="this.placeholderImg">`
    }]
  }],
  ctorParameters: () => [{
    type: Cloudinary
  }, {
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }],
  propDecorators: {
    type: [{
      type: Input,
      args: ['type']
    }],
    itemWidth: [{
      type: HostBinding,
      args: ['style.width']
    }],
    itemHeight: [{
      type: HostBinding,
      args: ['style.height']
    }],
    publicId: [{
      type: HostBinding,
      args: ['attr.public-id']
    }]
  }
});
const APP_VERSION = '1.5.3';
let SDKAnalyticsConstants = {
  sdkSemver: APP_VERSION,
  techVersion: VERSION.full,
  sdkCode: 'K'
};
class CloudinaryImage {
  constructor(el, cloudinary, renderer) {
    this.el = el;
    this.cloudinary = cloudinary;
    this.renderer = renderer;
    this.onLoad = new EventEmitter(); // Callback when an image is loaded successfully
    this.onError = new EventEmitter(); // Callback when an image is loaded with error
    this.shouldShowPlaceHolder = true;
    this.options = {};
  }
  ngOnInit() {
    if (isBrowser()) {
      // Create an observer instance
      this.observer = new MutationObserver(() => {
        this.loadImage();
      });
      // Observe changes to attributes or child transformations to re-render the image
      const config = {
        attributes: true,
        childList: true
      };
      // pass in the target node, as well as the observer options
      this.observer.observe(this.el.nativeElement, config);
    }
  }
  ngOnChanges(changes) {
    // Listen to changes on the data-bound property 'publicId'.
    // Update component unless this is the first value assigned.
    if (changes.publicId && !changes.publicId.isFirstChange()) {
      this.loadImage();
    }
  }
  ngOnDestroy() {
    if (this.observer && this.observer.disconnect) {
      this.observer.disconnect();
    }
  }
  ngAfterViewInit() {
    this.loadImage();
  }
  ngAfterContentChecked() {
    if (this.width && this.placeholderComponent) {
      this.placeholderComponent.setWidth(this.width);
    }
    if (this.height && this.placeholderComponent) {
      this.placeholderComponent.setHeight(this.height);
    }
    if (this.placeholderComponent) {
      this.placeholderComponent.setPublicId(this.publicId);
    }
  }
  /**
   * appends opacity and position to cl-img->img when placeholder is displayed
   * removes styling from cl-img->img when placeholder does not display
   */
  setPlaceHolderStyle() {
    if (this.shouldShowPlaceHolder) {
      this.renderer.setStyle(this.el.nativeElement.children[0], 'opacity', '0');
      this.renderer.setStyle(this.el.nativeElement.children[0], 'position', 'absolute');
    } else {
      // note this only removes styling from cl-img->img and not cl-img
      this.renderer.removeAttribute(this.el.nativeElement.children[0], 'style');
    }
  }
  hasLoaded() {
    this.shouldShowPlaceHolder = false;
  }
  loadImage() {
    // https://github.com/angular/universal#universal-gotchas
    // Fetch the image only for client side rendering by the browser
    if (isBrowser()) {
      if (!this.publicId) {
        throw new Error('You must set the public id of the image to load, e.g. <cl-image public-id={{photo.public_id}}...></cl-image>');
      }
      const nativeElement = this.el.nativeElement;
      const image = nativeElement.children[0];
      // Add onload and onerror handlers
      image.onload = e => {
        this.onLoad.emit(e);
      };
      image.onerror = e => {
        this.onError.emit(e);
      };
      this.options = this.cloudinary.toCloudinaryAttributes(nativeElement.attributes, this.transformations);
      if (this.clientHints || typeof this.clientHints === 'undefined' && this.cloudinary.config().client_hints) {
        delete this.options['class'];
        delete this.options['data-src'];
        delete this.options['responsive'];
      }
      if (this.cloudinary.config().urlAnalytics) {
        this.options = {
          ...SDKAnalyticsConstants,
          ...this.options
        };
      }
      if (this.placeholderComponent) {
        this.placeholderHandler(this.options, image);
      }
      if (this.accessibility) {
        this.options['src'] = this.accessibilityModeHandler();
      }
      const imageTag = this.cloudinary.imageTag(this.publicId, this.options);
      this.setElementAttributes(image, imageTag.attributes());
      if (this.options['responsive']) {
        this.cloudinary.responsive(image, this.options);
      }
    }
  }
  setElementAttributes(element, attributesLiteral) {
    Object.keys(attributesLiteral).forEach(attrName => {
      const attr = attrName === 'src' && this.loading === 'lazy' ? 'data-src' : attrName;
      this.renderer.setAttribute(element, attr, attributesLiteral[attrName]);
    });
    // Enforcing placeholder style
    if (this.placeholderComponent) {
      this.setPlaceHolderStyle();
    }
  }
  /**
   * Handles placeholder options
   * In case of responsive sets width from resize
   * In case width or height is known takes 10% of original dimension
   */
  placeholderHandler(options, image) {
    const placeholderOptions = {
      ...options
    };
    if (placeholderOptions['width']) {
      if (placeholderOptions['width'] === 'auto') {
        placeholderOptions['width'] = image.getAttribute('data-width');
      }
    }
    this.placeholderComponent.options = placeholderOptions;
  }
  accessibilityModeHandler() {
    return this.cloudinary.url(this.publicId, {
      accessibility: this.accessibility,
      ...this.options
    });
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: CloudinaryImage,
      deps: [{
        token: i0.ElementRef
      }, {
        token: Cloudinary
      }, {
        token: i0.Renderer2
      }],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: CloudinaryImage,
      selector: "cl-image",
      inputs: {
        publicId: ["public-id", "publicId"],
        clientHints: ["client-hints", "clientHints"],
        loading: "loading",
        width: "width",
        height: "height",
        accessibility: "accessibility"
      },
      outputs: {
        onLoad: "onLoad",
        onError: "onError"
      },
      queries: [{
        propertyName: "placeholderComponent",
        first: true,
        predicate: CloudinaryPlaceHolder,
        descendants: true
      }, {
        propertyName: "transformations",
        predicate: CloudinaryTransformationDirective
      }],
      usesOnChanges: true,
      ngImport: i0,
      template: `<img (load)="hasLoaded()">
  <div *ngIf="placeholderComponent && shouldShowPlaceHolder" [style.display]="shouldShowPlaceHolder ? 'inline' : 'none'">
    <ng-content></ng-content>
  </div>
  `,
      isInline: true,
      dependencies: [{
        kind: "directive",
        type: i2.NgIf,
        selector: "[ngIf]",
        inputs: ["ngIf", "ngIfThen", "ngIfElse"]
      }]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: CloudinaryImage,
  decorators: [{
    type: Component,
    args: [{
      selector: 'cl-image',
      template: `<img (load)="hasLoaded()">
  <div *ngIf="placeholderComponent && shouldShowPlaceHolder" [style.display]="shouldShowPlaceHolder ? 'inline' : 'none'">
    <ng-content></ng-content>
  </div>
  `
    }]
  }],
  ctorParameters: () => [{
    type: i0.ElementRef
  }, {
    type: Cloudinary
  }, {
    type: i0.Renderer2
  }],
  propDecorators: {
    publicId: [{
      type: Input,
      args: ['public-id']
    }],
    clientHints: [{
      type: Input,
      args: ['client-hints']
    }],
    loading: [{
      type: Input,
      args: ['loading']
    }],
    width: [{
      type: Input,
      args: ['width']
    }],
    height: [{
      type: Input,
      args: ['height']
    }],
    accessibility: [{
      type: Input,
      args: ['accessibility']
    }],
    transformations: [{
      type: ContentChildren,
      args: [CloudinaryTransformationDirective]
    }],
    placeholderComponent: [{
      type: ContentChild,
      args: [CloudinaryPlaceHolder]
    }],
    onLoad: [{
      type: Output
    }],
    onError: [{
      type: Output
    }]
  }
});

// See also video reference - http://cloudinary.com/documentation/video_manipulation_and_delivery#video_transformations_reference
class CloudinaryVideo {
  constructor(el, cloudinary, platformId) {
    this.el = el;
    this.cloudinary = cloudinary;
    this.platformId = platformId;
    this.play = new EventEmitter();
    this.loadstart = new EventEmitter();
    this.playing = new EventEmitter();
    this.error = new EventEmitter();
    this.ended = new EventEmitter();
  }
  ngOnInit() {
    if (typeof MutationObserver !== 'undefined') {
      // Create an observer instance
      this.observer = new MutationObserver(() => {
        this.loadVideo(this.publicId);
      });
      // Observe changes to attributes or child transformations to re-render the image
      const config = {
        attributes: true,
        childList: true
      };
      // pass in the target node, as well as the observer options
      this.observer.observe(this.el.nativeElement, config);
    }
  }
  ngOnChanges(changes) {
    // Listen to changes on the data-bound property 'publicId'.
    // Update component unless this is the first value assigned.
    if (changes.publicId && !changes.publicId.isFirstChange()) {
      this.loadVideo(changes.publicId.currentValue);
    }
  }
  ngOnDestroy() {
    if (this.observer && this.observer.disconnect) {
      this.observer.disconnect();
    }
  }
  ngAfterViewInit() {
    if (!this.publicId) {
      throw new Error('You must set the public id of the video to load, e.g. <cl-video public-id={{video.public_id}}...></cl-video>');
    }
    this.loadVideo(this.publicId);
  }
  loadVideo(publicId) {
    // https://github.com/angular/universal#universal-gotchas
    if (isPlatformBrowser(this.platformId)) {
      const nativeElement = this.el.nativeElement;
      const video = nativeElement.children[0];
      const options = this.cloudinary.toCloudinaryAttributes(nativeElement.attributes, this.transformations);
      const videoTag = this.cloudinary.videoTag(publicId, options);
      // Replace template with the custom video tag created by Cloudinary
      this.appendSourceElements(video, videoTag.content());
      // Add attributes
      this.setElementAttributes(video, videoTag.attributes());
    }
  }
  setElementAttributes(element, attributesLiteral) {
    Object.keys(attributesLiteral).forEach(attrName => {
      element.setAttribute(attrName, attributesLiteral[attrName]);
    });
  }
  appendSourceElements(element, html) {
    const fragment = document.createDocumentFragment();
    element.innerHTML = html;
    while (element.childNodes[0]) {
      fragment.appendChild(element.childNodes[0]);
    }
    element.appendChild(fragment);
  }
  emitPlayEvent() {
    this.play.emit();
  }
  emitLoadstartEvent() {
    this.loadstart.emit();
  }
  emitPlayingEvent() {
    this.playing.emit();
  }
  emitErrorEvent() {
    this.error.emit();
  }
  emitEndedEvent() {
    this.ended.emit();
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: CloudinaryVideo,
      deps: [{
        token: i0.ElementRef
      }, {
        token: Cloudinary
      }, {
        token: PLATFORM_ID
      }],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: CloudinaryVideo,
      selector: "cl-video",
      inputs: {
        publicId: ["public-id", "publicId"]
      },
      outputs: {
        play: "play",
        loadstart: "loadstart",
        playing: "playing",
        error: "error",
        ended: "ended"
      },
      queries: [{
        propertyName: "transformations",
        predicate: CloudinaryTransformationDirective
      }],
      usesOnChanges: true,
      ngImport: i0,
      template: '<video (play)="emitPlayEvent()" (loadstart)="emitLoadstartEvent()" (playing)="emitPlayingEvent()" (error)="emitErrorEvent" (ended)="emitEndedEvent"></video>',
      isInline: true
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: CloudinaryVideo,
  decorators: [{
    type: Component,
    args: [{
      selector: 'cl-video',
      template: '<video (play)="emitPlayEvent()" (loadstart)="emitLoadstartEvent()" (playing)="emitPlayingEvent()" (error)="emitErrorEvent" (ended)="emitEndedEvent"></video>'
    }]
  }],
  ctorParameters: () => [{
    type: i0.ElementRef
  }, {
    type: Cloudinary
  }, {
    type: Object,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }],
  propDecorators: {
    publicId: [{
      type: Input,
      args: ['public-id']
    }],
    play: [{
      type: Output
    }],
    loadstart: [{
      type: Output
    }],
    playing: [{
      type: Output
    }],
    error: [{
      type: Output
    }],
    ended: [{
      type: Output
    }],
    transformations: [{
      type: ContentChildren,
      args: [CloudinaryTransformationDirective]
    }]
  }
});
class CloudinaryImageSourceDirective {
  constructor(el, cloudinary) {
    this.el = el;
    this.cloudinary = cloudinary;
  }
  ngAfterViewInit() {
    if (isBrowser()) {
      let attrName;
      let propertyValue;
      if (this.clHref) {
        attrName = 'href';
        propertyValue = this.clHref;
      } else if (this.clSrc) {
        attrName = 'src';
        propertyValue = this.clSrc;
      } else if (this.clSrcset) {
        attrName = 'srcset';
        propertyValue = this.clSrcset;
      }
      let isSvg = false;
      if (this.clHref && toString.call(this.el.nativeElement['href'] === '[object SVGAnimatedString]')) {
        this.el.nativeElement.setAttribute('xlinkHref', 'xlink:href');
        isSvg = true;
      }
      if (!attrName || !propertyValue) {
        throw new Error('Directive value is missing for clHref/clSrc/clSrcset');
      }
      const nativeElement = this.el.nativeElement;
      const options = this.cloudinary.toCloudinaryAttributes(nativeElement.attributes, this.transformations);
      const attrValue = this.cloudinary.url(propertyValue, options);
      this.el.nativeElement.setAttribute(attrName, attrValue);
      /*
       on IE, if "ngSrc" directive declaration is used and "src" attribute doesn't exist
       then calling element.setAttribute('src', 'foo') doesn't do anything, so we need
       to set the property as well to achieve the desired effect.
            Check for IE: http://stackoverflow.com/a/32139375/198095
       if is IE then documentMode contains IE version
       */
      const msie = this.el.nativeElement.ownerDocument.documentMode;
      if (msie && !isSvg) {
        // IE logic here
        this.el.nativeElement[attrName] = attrValue;
      }
    }
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: CloudinaryImageSourceDirective,
      deps: [{
        token: i0.ElementRef
      }, {
        token: Cloudinary
      }],
      target: i0.ɵɵFactoryTarget.Directive
    });
  }
  static {
    this.ɵdir = i0.ɵɵngDeclareDirective({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: CloudinaryImageSourceDirective,
      selector: "[clHref], [clSrc], [clSrcset]",
      inputs: {
        clHref: "clHref",
        clSrc: "clSrc",
        clSrcset: "clSrcset"
      },
      queries: [{
        propertyName: "transformations",
        predicate: CloudinaryTransformationDirective
      }],
      ngImport: i0
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: CloudinaryImageSourceDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[clHref], [clSrc], [clSrcset]'
    }]
  }],
  ctorParameters: () => [{
    type: i0.ElementRef
  }, {
    type: Cloudinary
  }],
  propDecorators: {
    clHref: [{
      type: Input
    }],
    clSrc: [{
      type: Input
    }],
    clSrcset: [{
      type: Input
    }],
    transformations: [{
      type: ContentChildren,
      args: [CloudinaryTransformationDirective]
    }]
  }
});
class CloudinaryBackgroundImageDirective {
  constructor(renderer, el, cloudinary) {
    this.renderer = renderer;
    this.el = el;
    this.cloudinary = cloudinary;
    this.position = 'center';
  }
  isBrowser() {
    return typeof window !== 'undefined';
  }
  ngAfterViewInit() {
    if (this.isBrowser()) {
      const nativeElement = this.el.nativeElement;
      const options = this.cloudinary.toCloudinaryAttributes(nativeElement.attributes, this.transformations);
      const imageUrl = this.cloudinary.url(this.clBackgroundImage, options);
      this.renderer.setStyle(nativeElement, 'background-image', `url('${imageUrl}')`);
      this.renderer.setStyle(nativeElement, 'background-repeat', 'no-repeat');
      this.renderer.setStyle(nativeElement, 'background-position', this.position);
    }
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: CloudinaryBackgroundImageDirective,
      deps: [{
        token: i0.Renderer2
      }, {
        token: i0.ElementRef
      }, {
        token: Cloudinary
      }],
      target: i0.ɵɵFactoryTarget.Directive
    });
  }
  static {
    this.ɵdir = i0.ɵɵngDeclareDirective({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: CloudinaryBackgroundImageDirective,
      selector: "[clBackgroundImage]",
      inputs: {
        clBackgroundImage: "clBackgroundImage",
        position: "position"
      },
      queries: [{
        propertyName: "transformations",
        predicate: CloudinaryTransformationDirective
      }],
      ngImport: i0
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: CloudinaryBackgroundImageDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[clBackgroundImage]'
    }]
  }],
  ctorParameters: () => [{
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }, {
    type: Cloudinary
  }],
  propDecorators: {
    clBackgroundImage: [{
      type: Input
    }],
    position: [{
      type: Input
    }],
    transformations: [{
      type: ContentChildren,
      args: [CloudinaryTransformationDirective]
    }]
  }
});
class LazyLoadDirective {
  constructor(el) {
    this.el = el;
  }
  ngAfterViewInit() {
    if (isBrowser()) {
      if (!this.isNativeLazyLoadSupported() && this.isLazyLoadSupported()) {
        this.lazyLoad();
      } else {
        this.loadImage();
      }
    }
  }
  loadImage() {
    const nativeElement = this.el.nativeElement;
    const image = nativeElement.children[0];
    image.setAttribute('src', image.dataset.src);
  }
  isLazyLoadSupported() {
    return window && 'IntersectionObserver' in window;
  }
  isNativeLazyLoadSupported() {
    return 'loading' in HTMLImageElement.prototype; // check loading property is defined on image or iframe
  }
  lazyLoad() {
    const options = {
      rootMargin: `0px 0px -50% 0px` // Margin around the root
    };
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.loadImage();
          observer.unobserve(entry.target);
        }
      }, options);
    });
    observer.observe(this.el.nativeElement);
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: LazyLoadDirective,
      deps: [{
        token: i0.ElementRef
      }],
      target: i0.ɵɵFactoryTarget.Directive
    });
  }
  static {
    this.ɵdir = i0.ɵɵngDeclareDirective({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: LazyLoadDirective,
      selector: "cl-image[loading=lazy]",
      ngImport: i0
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: LazyLoadDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'cl-image[loading=lazy]'
    }]
  }],
  ctorParameters: () => [{
    type: i0.ElementRef
  }]
});

/* App Module */
'use strict';
const CLOUDINARY_LIB = new InjectionToken('CLOUDINARY_LIB');
const CLOUDINARY_CONFIGURATION = new InjectionToken('CLOUDINARY_CONFIGURATION');
// Export this function to Angular's AOT to work
function createCloudinary(cloudinaryJsLib, configuration) {
  return new Cloudinary(cloudinaryJsLib, configuration);
}
;
class CloudinaryModule {
  static forRoot(cloudinaryJsLib, cloudinaryConfiguration) {
    return {
      ngModule: CloudinaryModule,
      providers: [{
        provide: CLOUDINARY_LIB,
        useValue: cloudinaryJsLib
      }, {
        provide: CLOUDINARY_CONFIGURATION,
        useValue: cloudinaryConfiguration
      }, {
        provide: Cloudinary,
        useFactory: createCloudinary,
        deps: [CLOUDINARY_LIB, CLOUDINARY_CONFIGURATION]
      }]
    };
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: CloudinaryModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: CloudinaryModule,
      declarations: [CloudinaryImageSourceDirective, CloudinaryBackgroundImageDirective, CloudinaryImage, CloudinaryVideo, CloudinaryTransformationDirective, LazyLoadDirective, CloudinaryPlaceHolder],
      imports: [CommonModule],
      exports: [CloudinaryImageSourceDirective, CloudinaryBackgroundImageDirective, CloudinaryImage, CloudinaryVideo, CloudinaryTransformationDirective, LazyLoadDirective, CloudinaryPlaceHolder]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: CloudinaryModule,
      imports: [CommonModule]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: CloudinaryModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [CloudinaryImageSourceDirective, CloudinaryBackgroundImageDirective, CloudinaryImage, CloudinaryVideo, CloudinaryTransformationDirective, LazyLoadDirective, CloudinaryPlaceHolder],
      exports: [CloudinaryImageSourceDirective, CloudinaryBackgroundImageDirective, CloudinaryImage, CloudinaryVideo, CloudinaryTransformationDirective, LazyLoadDirective, CloudinaryPlaceHolder]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { CLOUDINARY_CONFIGURATION, CLOUDINARY_LIB, Cloudinary, CloudinaryBackgroundImageDirective, CloudinaryImage, CloudinaryImageSourceDirective, CloudinaryModule, CloudinaryPlaceHolder, CloudinaryTransformationDirective, CloudinaryVideo, LazyLoadDirective, createCloudinary, isBrowser, isJsonLikeString, isNamedNodeMap, namedNodeMapToObject, provideCloudinary, transformKeyNames };
