import * as i0 from '@angular/core';
import { EventEmitter, Component, Optional, Self, Inject, Input, Output, NgModule } from '@angular/core';
import * as i4 from '@angular/forms';
import { NgControl } from '@angular/forms';
import { CustomControlValueAccessor } from '@theevolvedgroup/component-library/utils';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@theevolvedgroup/component-library/icon-button';
import { IconButtonModule } from '@theevolvedgroup/component-library/icon-button';
import * as i3 from '@angular/material/tooltip';
import { MatTooltipModule } from '@angular/material/tooltip';
class CheckboxComponent extends CustomControlValueAccessor {
  constructor(ngControl) {
    super(ngControl);
    this.ngControl = ngControl;
    /** Whether this checkbox is checked */
    this.checked = false;
    /** Whether the checkbox is disabled */
    this.disabled = false;
    /** Whether the label should appear after or before the checkbox. Defaults to 'after' */
    this.labelPosition = "after";
    /** The icon to use when the status is checked */
    this.checkedIcon = {
      icon: 'check_box'
    };
    /** The icon to use when the status is not checked */
    this.uncheckedIcon = {
      icon: 'check_box_outline_blank'
    };
    /** The colour of the icon to use */
    this.color = null;
    /** Override style for the checkbox layout */
    this.justifyContentStyle = null;
    /** Checkbox tooltip */
    this.tooltip = "";
    /** Event emitted when the checked state of this checkbox changes. Allows for two way binding of checked. */
    this.checkedChange = new EventEmitter();
  }
  ngOnInit() {}
  ngOnChanges(simpleChanges) {
    if (this.checkedIcon == null) {
      this.checkedIcon = {
        icon: "check_box"
      };
    }
    if (this.uncheckedIcon == null) {
      this.uncheckedIcon = {
        icon: "check_box_outline_blank"
      };
    }
  }
  toggleChecked(event) {
    if (this.disabled) {
      return;
    }
    this.checked = !this.checked;
    this._onChange(this.checked);
    this.checkedChange.emit(this.checked);
    event.stopPropagation();
  }
  /** Override CustomControlValueAccessor writeValue */
  writeValue(value) {
    this.checked = value;
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: CheckboxComponent,
      deps: [{
        token: NgControl,
        optional: true,
        self: true
      }],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "18.2.0",
      type: CheckboxComponent,
      selector: "teg-checkbox",
      inputs: {
        checked: "checked",
        disabled: "disabled",
        labelPosition: "labelPosition",
        checkedIcon: "checkedIcon",
        uncheckedIcon: "uncheckedIcon",
        color: "color",
        justifyContentStyle: "justifyContentStyle",
        tooltip: "tooltip"
      },
      outputs: {
        checkedChange: "checkedChange"
      },
      usesInheritance: true,
      usesOnChanges: true,
      ngImport: i0,
      template: "<div class=\"teg-checkbox {{labelPosition}}\"\n     [ngClass]=\"{ 'disabled': disabled }\"\n     [ngStyle]=\"{ 'justify-content': justifyContentStyle }\"\n     [matTooltip]=\"tooltip\"\n     (click)=\"toggleChecked($event)\">\n    <teg-icon-button [disabled]=\"disabled\"\n                     [color]=\"color ? color : checked ? checkedIcon.color : uncheckedIcon.color\">\n        {{ checked ? checkedIcon.icon : uncheckedIcon.icon }}\n    </teg-icon-button>\n    <div class=\"label\"\n         #labelContent>\n        <ng-content></ng-content>\n    </div>\n</div>",
      styles: [":host{--label-font-size: var(--theme-base-font-size)}.teg-checkbox{display:flex;align-items:center;-webkit-user-select:none;user-select:none;cursor:pointer;width:fit-content}.teg-checkbox.disabled{cursor:not-allowed}.teg-checkbox.disabled .label{opacity:var(--theme-disabled-input-opacity)}.teg-checkbox .label:not(:empty){order:0;margin:0 0 0 var(--theme-spacing-xs);font-size:var(--label-font-size);white-space:nowrap;line-height:100%}.teg-checkbox.before .label:not(:empty){order:-1;margin:0 var(--theme-spacing-xs) 0 0}\n"],
      dependencies: [{
        kind: "directive",
        type: i1.NgClass,
        selector: "[ngClass]",
        inputs: ["class", "ngClass"]
      }, {
        kind: "directive",
        type: i1.NgStyle,
        selector: "[ngStyle]",
        inputs: ["ngStyle"]
      }, {
        kind: "component",
        type: i2.IconButtonComponent,
        selector: "teg-icon-button",
        inputs: ["customClass", "icon", "outline", "color", "size", "variant", "library", "opticalSize", "iconWeight", "action", "tooltip", "tooltipPosition", "disabled", "loading", "iconSrc", "svgIcon"],
        outputs: ["iconClicked"]
      }, {
        kind: "directive",
        type: i3.MatTooltip,
        selector: "[matTooltip]",
        inputs: ["matTooltipPosition", "matTooltipPositionAtOrigin", "matTooltipDisabled", "matTooltipShowDelay", "matTooltipHideDelay", "matTooltipTouchGestures", "matTooltip", "matTooltipClass"],
        exportAs: ["matTooltip"]
      }]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: CheckboxComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: "teg-checkbox",
      template: "<div class=\"teg-checkbox {{labelPosition}}\"\n     [ngClass]=\"{ 'disabled': disabled }\"\n     [ngStyle]=\"{ 'justify-content': justifyContentStyle }\"\n     [matTooltip]=\"tooltip\"\n     (click)=\"toggleChecked($event)\">\n    <teg-icon-button [disabled]=\"disabled\"\n                     [color]=\"color ? color : checked ? checkedIcon.color : uncheckedIcon.color\">\n        {{ checked ? checkedIcon.icon : uncheckedIcon.icon }}\n    </teg-icon-button>\n    <div class=\"label\"\n         #labelContent>\n        <ng-content></ng-content>\n    </div>\n</div>",
      styles: [":host{--label-font-size: var(--theme-base-font-size)}.teg-checkbox{display:flex;align-items:center;-webkit-user-select:none;user-select:none;cursor:pointer;width:fit-content}.teg-checkbox.disabled{cursor:not-allowed}.teg-checkbox.disabled .label{opacity:var(--theme-disabled-input-opacity)}.teg-checkbox .label:not(:empty){order:0;margin:0 0 0 var(--theme-spacing-xs);font-size:var(--label-font-size);white-space:nowrap;line-height:100%}.teg-checkbox.before .label:not(:empty){order:-1;margin:0 var(--theme-spacing-xs) 0 0}\n"]
    }]
  }],
  ctorParameters: () => [{
    type: i4.NgControl,
    decorators: [{
      type: Optional
    }, {
      type: Self
    }, {
      type: Inject,
      args: [NgControl]
    }]
  }],
  propDecorators: {
    checked: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    labelPosition: [{
      type: Input
    }],
    checkedIcon: [{
      type: Input
    }],
    uncheckedIcon: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    justifyContentStyle: [{
      type: Input
    }],
    tooltip: [{
      type: Input
    }],
    checkedChange: [{
      type: Output
    }]
  }
});
class CheckboxModule {
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: CheckboxModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: CheckboxModule,
      declarations: [CheckboxComponent],
      imports: [CommonModule, IconButtonModule, MatTooltipModule],
      exports: [CheckboxComponent]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: CheckboxModule,
      imports: [CommonModule, IconButtonModule, MatTooltipModule]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: CheckboxModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [CommonModule, IconButtonModule, MatTooltipModule],
      exports: [CheckboxComponent],
      declarations: [CheckboxComponent],
      providers: []
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { CheckboxComponent, CheckboxModule };
