import * as i0 from '@angular/core';
import { Pipe, NgModule } from '@angular/core';
class SentimentPipe {
  transform(value, type) {
    let sentimentIcon = "satisfied";
    let sentimentColor = "success";
    if (value < 0) {
      sentimentIcon = "dissatisfied";
      sentimentColor = "error";
    } else if (value == 0 || value == 2) {
      sentimentIcon = "neutral";
      if (value == 0) {
        sentimentColor = "var(--theme-color-grey-40)";
      }
      if (value == 2) {
        sentimentColor = "var(--palette-color-yellow-60)";
      }
    }
    let icon = `sentiment_${sentimentIcon}`;
    let sentiment = icon;
    if (type == 'color') {
      sentiment = sentimentColor;
    } else if (type == 'both') {
      sentiment = {
        color: sentimentColor,
        icon: icon
      };
    }
    return sentiment;
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: SentimentPipe,
      deps: [],
      target: i0.ɵɵFactoryTarget.Pipe
    });
  }
  static {
    this.ɵpipe = i0.ɵɵngDeclarePipe({
      minVersion: "14.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: SentimentPipe,
      name: "sentiment"
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: SentimentPipe,
  decorators: [{
    type: Pipe,
    args: [{
      name: 'sentiment'
    }]
  }]
});
class SentimentPipeModule {
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: SentimentPipeModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: SentimentPipeModule,
      declarations: [SentimentPipe],
      exports: [SentimentPipe]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "18.2.0",
      ngImport: i0,
      type: SentimentPipeModule
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.2.0",
  ngImport: i0,
  type: SentimentPipeModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [],
      exports: [SentimentPipe],
      declarations: [SentimentPipe],
      providers: []
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { SentimentPipe, SentimentPipeModule };
