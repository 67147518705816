import { DataService } from "../../services/data.service";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { forkJoin, Subject } from "rxjs";
import * as _ from "lodash";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  GridComponent
} from '@syncfusion/ej2-angular-grids';
import { ConfirmationDialog } from "../dialogs/confirmation-dialog/confirmation-dialog.component";
import { Action } from "@theevolvedgroup/component-library/action-bar/types";
import { DialogCloseResult } from "@theevolvedgroup/component-library/dialog-base/types";
import { DialogFactoryService } from "@theevolvedgroup/component-library/dialog-base";
import { AppStateService } from "src/app/services/app-state.service";

@Component({
  selector: "app-role-admin",
  templateUrl: "./user-role.component.html",
  styleUrls: ["./user-role.component.css"]
})
export class UserRoleComponent implements OnInit {
  users: any[] = [];
  loading = true;
  claimValue = "SysAdminUser";

  @ViewChild('grid1') public grid1: GridComponent;


  emailAddress: any;
  roles = [
    {
      'roleText': 'Sys Admin User',
      'roleValue': 'SysAdminUser'
    }, {
      'roleText': 'Dashboard Publisher Super User',
      'roleValue': 'DPSuper'
    }, {
      'roleText': 'Eve Builder Super User',
      'roleValue': 'EBSuper'
    }
  ]

  columns = [
    {
      field: "emailAddress",
      headerText: "Email Address",
      width: "40%",
      type: "string",
      allowFiltering: true,
      allowSorting: true
    },
    {
      field: "claimValue",
      headerText: "Role",
      width: "40%",
      type: "string",
      allowFiltering: true,
      allowSorting: true
    }
  ]

  actions: Action[] = [
    {
      icon: "delete",
      tooltip: "Delete",
      outline: false,
      color: "var(--theme-table-action-color)",
      action: { name: "delete" },
    },
  ];

  modelChanged: Subject<string> = new Subject<string>();
  constructor(public dataService: DataService,
    public dialog: MatDialog,
    private dialogFactoryService: DialogFactoryService,
    private appState: AppStateService) {
    this.modelChanged
      .pipe(
        debounceTime(1000), // wait 300ms after the last event before emitting last event
        distinctUntilChanged()
      ) // only emit if value is different from previous value
      .subscribe(() => this.loadData());
  }
  loadData() {
    this.loading = true;
    this.dataService
      .getUsersRoleList(this.claimValue)
      .subscribe((data: any) => {
        this.users = _.orderBy(data, [user => user.emailAddress.toLowerCase()], ["asc"]);
        this.loading = false;
      });
  }

  ngOnInit() {
    this.appState.breadcrumbs = [{ label: 'Home', url: '/' }, { label: 'Roles', url: 'roles' }];
    this.loadData();
  }
  onChange(event, user, application) {
    // user.licenses[application.applicationId].updating = true;
    // if (event.target.checked) {
    //   this.dataService.userLicenseAdd(user, application).subscribe(data => {
    //     user.licenses[application.applicationId].checked = true;
    //     user.licenses[application.applicationId].updating = false;
    //   });
    // } else {
    //   this.dataService.userLicenseRemove(user, application).subscribe(data => {
    //     user.licenses[application.applicationId].checked = false;
    //     user.licenses[application.applicationId].updating = false;
    //   });
    // }
  }
  onUserChange(event, user) {
    user.updating = true;
    this.dataService.userUpdate(user).subscribe(data => {
      user = _.merge(user, data);
      user.updating = false;
    });
  }
  changed(event) {
    this.modelChanged.next(event);
  }

  onRoleChange(event: any) {
    this.claimValue = event.value;
    this.modelChanged.next(event);
  }

  removeRole(event: any) {
    const headerText = "Delete Role";
    let message = "Are you sure you want to delete this role?";
    let buttons = [
      { id: "no", colorType: "secondary", caption: "CANCEL" },
      { id: "yes", colorType: "warn", caption: "YES" }
    ];

    let dialog = this.dialogFactoryService.openMessageDialog({ headerText, contentComponentProperties: { message }, buttons, headerColor: "warn" });

    dialog.closed$.subscribe((result: DialogCloseResult) => {
      if (result.button.id == 'yes') {
        let req = {
          emailAddress: event.item.emailAddress,
          claimValue: this.claimValue,
        }
        this.dataService.deleteUserRole(req).subscribe(data => {
          this.loadData();
        },
        (error) => {
          console.log(error);
          alert('Error removing role')
        },
        () => {
        });
        // do confirmation actions
      }
    });
  }
  addUserRole() {
    const dialogRef = this.dialog.open(AddRoleDialog, {
      width: '450px',
      data: { role: this.claimValue }
    });

    dialogRef.afterClosed().subscribe(email => {
      if (email) {
        let req = {
          emailAddress: email,
          claimValue: this.claimValue,
        }
        this.dataService.addUserRole(req).subscribe(data => {
          this.loadData();
        },
        (error) => {
          console.log(error);
          alert('Error adding role')
        },
        () => {
        });
      }
    });
  }
}


@Component({
  selector: 'add-role-dialog',
  templateUrl: 'add-role-dialog.html',
})
export class AddRoleDialog {

  emailAddress: any
  constructor(
    public dialogRef: MatDialogRef<AddRoleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}