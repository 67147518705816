<div
  *ngIf="!appState.dataLoaded"
  style="display:flex;flex-direction:column;height:100%;align-items: center;justify-content: center;"
>
  <img
    src="/assets/humanlistening.spinner.gif"
    style="width:101px;height:86px"
  />
  Loading Sub Accounts
</div>

<div *ngIf="appState.currentAccount && !appState.currentSubAccount"  class="content-section-wrapper">
  <div class="filter-container">
    <teg-dropdown class="filter"
                  label="Status" 
                  [data]="contentStatuses"
                  [fields]="contentStatusFields" 
                  [(ngModel)]="selectedContentStatusId"
                  [value]="selectedContentStatusId"
                  (selectionChanged)="onContentStatusChanged($event)" >
    </teg-dropdown>
  </div>
    <teg-content-section 
        title="Sub Accounts"
        [tiles]="subAccountTiles"
        [actions]="subAccountActions"
        [loading]= "false"
        [showAddTile] = "true"
        [viewType]="ViewType"
        (tileClicked)="onSubAccountTileClicked($event)"
        (addItem)="onAddSubAccountTileClicked($event)"
        (itemOptionClicked)="onItemOptionClicked($event)">
    </teg-content-section>
</div>