/* src/app/components/forbidden/forbidden.component.css */
#dialogContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  background: #f2f2f2;
}
#forbiddenDialog {
  position: relative;
  padding: 5px;
  padding-bottom: 70px;
  overflow: auto;
  margin: auto;
}
.header {
  padding: 5px;
  margin-bottom: 10px;
  color: black;
  line-height: 1.5em;
  font-size: 42px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
.footer {
  margin-top: 10px;
  padding: 5px;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  min-height: 50px;
}
.footer {
}
.content {
  padding: 5px;
}
.message {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.75px;
  color: black;
  text-align: center;
}
.content-image {
}
/*# sourceMappingURL=forbidden.component.css.map */
