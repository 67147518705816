    <!-- <span>Existing Shares</span>
    <div *ngFor="let share of account.shareByEmails">
        <span>{{share.emailAddress}}</span>
    </div> -->
<div class="share-dialog-content">
    <span class="section-title">CURENT SHARES</span>
    <div class="share-table-container">
        <teg-table  [dataSource]="dataSource" 
                    [columns]="shareColumns"
                    [actions]="shareActions"
                    [height]="320"
                    [allowFiltering]="true"
                    (actionBarClicked)="onShareActionClicked($event)">
        </teg-table>
    </div>
    <div class="new-share-container">
        <span class="section-title">NEW SHARE</span>
        <div class="new-share-form">
            <teg-input [placeholder]="'Email Address'" [(ngModel)]="newShare.emailAddress"></teg-input>
            <teg-dropdown [placeholder]="'Share Role'" [data]="shareRoles" [fields]="shareRoleFields" 
                          [(ngModel)]="newShare.shareRoleId"
                          (selectionChanged)="onRoleChanged($event)" >
            </teg-dropdown>
            <teg-icon-button [tooltip]="'Add Share'" (click)="onAddShareClicked()">add_circle</teg-icon-button>
        </div>
    </div>
</div>