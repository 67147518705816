<div
  *ngIf="loading; else content"
  style="display:flex;flex-direction:column;height:100%;align-items: center;justify-content: center;"
>
  <img
    src="/assets/humanlistening.spinner.gif"
    style="width:101px;height:86px"
  />
  Loading Roles
</div>

<ng-template #content>
    <div style="padding: 2%;display: flex;flex-direction: column;">
        <div style="display: flex;flex-direction: row; align-items: center;padding-bottom: 5px;">
            <div style="flex: 1 1 0%;">
                <span style="padding-right: 5px;">Role Type:</span>
                <ejs-dropdownlist id='ddlRole' [fields]='{ text: "roleText", value: "roleValue" }' [value]="claimValue"
                    (change)="onRoleChange($event)" [dataSource]='roles' placeholder='Select Role' width="300">
                </ejs-dropdownlist>
            </div>
            <teg-button mat-button fxLayoutAlign="end" style="margin-bottom: 5px;" color="primary" class="pl-button"
                (click)="addUserRole()">New User Role</teg-button><br>
        </div>
        <div class="headerRow" style="display:flex;">
            <teg-table  [dataSource]="users"
                        [columns]="columns"
                        [actions]="actions"
                        [height]="500"
                        [allowFiltering]="true"
                        [allowSorting]="true" (actionBarClicked)="removeRole($event)">
            </teg-table>
        </div>
    </div>
</ng-template>