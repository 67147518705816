var DisplayType;
(function (DisplayType) {
  /* Display Action as Icon */
  DisplayType[DisplayType["Icon"] = 0] = "Icon";
  /* Display Action as Button */
  DisplayType[DisplayType["Button"] = 1] = "Button";
})(DisplayType || (DisplayType = {}));
var ActionType;
(function (ActionType) {
  ActionType[ActionType["Click"] = 0] = "Click";
  /* Toggle all actions in group */
  ActionType[ActionType["ToggleAll"] = 1] = "ToggleAll";
  /* Toggle between different icons - i.e. toggle between list and grid */
  ActionType[ActionType["Toggle"] = 2] = "Toggle";
  /* Toggle Outline of Icon - toggle between filled and outlined */
  ActionType[ActionType["ToggleOutline"] = 3] = "ToggleOutline";
  /* Open nested menu */
  ActionType[ActionType["OpenMenu"] = 4] = "OpenMenu";
})(ActionType || (ActionType = {}));
var MenuOptionsType;
(function (MenuOptionsType) {
  MenuOptionsType[MenuOptionsType["Default"] = 0] = "Default";
  MenuOptionsType[MenuOptionsType["Check"] = 1] = "Check";
  MenuOptionsType[MenuOptionsType["Radio"] = 2] = "Radio";
  MenuOptionsType[MenuOptionsType["NestedMenu"] = 3] = "NestedMenu";
})(MenuOptionsType || (MenuOptionsType = {}));

/**
 * Generated bundle index. Do not edit.
 */

export { ActionType, DisplayType, MenuOptionsType };
